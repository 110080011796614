import "../../Assests/Styles/Common.css";
import { Form, Modal } from "react-bootstrap";
import styles from "../SignUp/BloodTypeModal.module.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function BikeColorModal({
  bikeColorModalOpen,
  setBikeColorModalOpen,
  setValue,
  clearErrors,
  selectedBikeColor,
  setSelectedBikeColor,
}) {
  const { t } = useTranslation();
  const { masterList } = useSelector((state) => state.user);

  // ----------- function for manage checkbox----------------
  const handleCheckboxChange = (bikeColor) => {
    setSelectedBikeColor({
      id: bikeColor.id,
      value_name: bikeColor.value_name,
    });
    setValue("bikeColor", bikeColor.value_name);
    clearErrors("bikeColor");
    setBikeColorModalOpen(false);
  };

  return (
    <div className={styles.topModal}>
      <div className="modalPopup">
        <Modal
          show={bikeColorModalOpen}
          onHide={() => {
            setBikeColorModalOpen(false);
          }}
          className="modalCloseBtn"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className={styles.modalTitle}>
              {t("BIKE_COLOR_TYPE")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="bloodTypeChek">
              <div className={styles.ScrollbarVertical}>
                <div className="checkBoxRow">
                  {masterList &&
                    masterList.colours &&
                    masterList.colours.length > 0 &&
                    masterList.colours.map((bikeColorOption, index) => (
                      <div
                        key={index}
                        className="checkboxSelect"
                        onClick={() => handleCheckboxChange(bikeColorOption)}
                      >
                        <label
                          htmlFor={`bike_color${bikeColorOption.value_name}`}
                        >
                          {bikeColorOption.value_name}
                        </label>
                        <input
                          id={`bike_color${bikeColorOption.id}`}
                          type="radio"
                          checked={
                            selectedBikeColor &&
                            selectedBikeColor.id == bikeColorOption.id
                          }
                          onChange={() => handleCheckboxChange(bikeColorOption)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default BikeColorModal;
