import "../../Assests/Styles/Common.css";
import { useTranslation } from "react-i18next";
import styles from "../SignUp/SignUp.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { Container, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  checkEmailPhone,
  clearProfileData,
  setSignupData,
  setSocialUserData,
} from "../../store/slices/userSlice";
import Toster from "../../utils/Toster";
import accountInfo from "../../Assests/Images/account_information_ico.svg";
import GoogleLogin from "../SocialLogin/GoogleLogin";
import FaceBookLogin from "../SocialLogin/FaceBookLogin";
import { CHECK_TYPE } from "../../utils/Constants";
import { STATUS_CODES } from "../../utils/StatusCode";

function SignUpStepFirst({ setSwitchTo }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { switchTo } = useSelector((state) => state.user);
  const { signupData, socialUserData } = useSelector((state) => state.user);
  const [pinValue, setPinValue] = useState(signupData ? signupData.pin : "");
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const socialProfileData = location.state;
  const [showHint, setShowHint] = useState(true);
  const [emailError, setEmailError] = useState("");

  //======== function for clear redux state===========
  useEffect(() => {
    dispatch(clearProfileData());
  }, []);

  //======= function for manage social data if id exist========
  useEffect(() => {
    if (
      socialProfileData &&
      socialProfileData.profileData &&
      socialProfileData.profileData.id
    ) {
      dispatch(
        setSocialUserData({
          id: socialProfileData.profileData.id,
          name: socialProfileData.profileData.name,
          email: socialProfileData.profileData.email,
          socialType: socialProfileData.socialType,
        })
      );
    } else {
      dispatch(setSocialUserData({}));
    }
  }, []);

  // ------- disable space on key press input password field-------------
  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  //----------- function for apply validation using useForm----------
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  // -------- function for submit form-----------
  const onSubmit = async (formData) => {
    // Call the email existence check API
    let emailCheckData = new FormData();
    emailCheckData.append("check_type", CHECK_TYPE.EMAIL);
    emailCheckData.append("email", formData.email);
    const response = await dispatch(checkEmailPhone(emailCheckData));
    const responseData = response.payload;
    if (
      responseData &&
      responseData.data.code === STATUS_CODES.EMAIL_ALREADY_IN_USE
    ) {
      Toster(t("EMAIL_ALREADY_IN_USE"), "error");
      return false;
    }
    if (pinValue.length !== 6) {
      if (pinValue.length === 0 || pinValue.length === "") {
        Toster(t("ENTER_SIGNUP_PIN"));
      } else {
        Toster(t("MIN_PIN_LENGTH"), "error");
      }
      return false;
    }
    // =====Clear errors for the PIN field if it's prefilled and not changed=========
    if (signupData && signupData.pin === pinValue) {
      clearErrors("pin");
    }
    await dispatch(setSignupData(formData));
    setSwitchTo(1);
    clearErrors("");
    // Check if there are any errors related to the password field
    if (formData.password) {
      setShowHint(false);
    } else {
      setShowHint(true);
    }
  };

  //------ function for show form prefilled------------
  useEffect(() => {
    if (signupData && Object.keys(signupData).length > 0) {
      if (signupData && signupData.pin) {
        if (signupData.pin.length === 6) {
          setValue("pin", signupData.pin ? signupData.pin : "");
          setPinValue(signupData.pin ? signupData.pin : "");
        }
      }
      setValue("name", signupData.name ? signupData.name : "");
      setValue("email", signupData.email ? signupData.email : "");
      setValue("password", signupData.password ? signupData.password : "");
      setValue(
        "confirmPassword",
        signupData.confirmPassword ? signupData.confirmPassword : ""
      );
    } else {
      setValue("email", socialUserData ? socialUserData.email : "");
      setValue("name", socialUserData ? socialUserData.name : "");
    }
  }, [switchTo, socialUserData]);

  useEffect(() => {
    setValue("pin", pinValue && pinValue.length === 6 ? pinValue : "");
    setError("pin", {
      types: {
        required: "Please enter pin",
        minLength: "Please enter pin, with a minimum of 6 digits",
      },
    });
  }, [pinValue]);

  // Add confirmation for navigation away from the form
  useEffect(() => {
    const handleBackButton = (event) => {
      navigate(-1);
    };
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div className={styles.signpWrapper}>
      <div className={styles.tittle}>
        <div className={styles.backIcon}>
          <IoMdArrowRoundBack onClick={() => navigate(-1)} />
        </div>
        <div className={styles.signUpTitle}>
          <p>
            {t("ACCOUNT_INFORMATION_TITTLE")}
            <img src={accountInfo}></img>
          </p>
        </div>
        <p className={styles.stepCount}>
          {1}
          <span>/4</span>
        </p>
      </div>
      <Container>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("NAME_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("NAME_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("name", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("NAME_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 2,
                    message: `${t("NAME_VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 25,
                    message: `${t("NAME_VALIDATION_MAXLENGTH")}`,
                  },
                })}
              />

              {errors.name && (
                <span className="errorMsg">{errors.name.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("EMAIL_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("EMAIL_PLACEHOLDER_TEXT")}`}
                type="text"
                autoComplete="off"
                {...register("email", {
                  onChange: (e) => onInputChange(e),
                  required: {
                    value: true,
                    message: `${t("EMAIL_REQUIRED_MESSAGE")}`,
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: `${t("EMAIL_INVALID")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />
              {emailError && <span className="errorMsg">{emailError}</span>}

              {errors.email && (
                <span className="errorMsg">{errors.email.message}</span>
              )}
            </Form.Group>
            {!socialProfileData && (
              <>
                {" "}
                <Form.Group className={styles.FormGroup}>
                  <Form.Label className={styles.FormLabel}>
                    {t("PASSWORD_LABEL")}
                  </Form.Label>
                  <Form.Control
                    className={styles.FormInput}
                    placeholder={`${t("PASSWORD_PLACEHOLDER_TEXT")}`}
                    type={passwordShow ? "text" : "password"}
                    name="password"
                    autoComplete="new-password"
                    {...register("password", {
                      onChange: (e) => {
                        passwordKeyPress(e);
                      },
                      ...sanitizeHtmlTags(),
                      required: {
                        value: true,
                        message: `${t("PASSWORD_REQUIRED_MESSAGE")}`,
                      },
                      minLength: {
                        value: 6,
                        message: `${t("PASS_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 12,
                        message: `${t("PASS_MAXLENGTH")}`,
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!]).{6,12}$/,
                        message: `${t("PASSWORD_MUST_CONTAIN")}`,
                      },
                    })}
                  />

                  {!errors.password && showHint && (
                    <p className={styles.hintMsg}>
                      {t("PASSWORD_MUST_CONTAIN")}
                    </p>
                  )}

                  {errors.password && (
                    <span className="errorMsg">{errors.password.message}</span>
                  )}

                  {/* {errors.password && (
                    <span
                      className={
                        errors.password.type === "pattern"
                          ? "patternErrorMsg"
                          : "errorMsg"
                      }
                    >
                      {errors.password.message}
                    </span>
                  )} */}
                  <div className="passwordicon">
                    {passwordShow ? (
                      <FaEye
                        className="icon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      />
                    ) : (
                      <FaEyeSlash
                        className="icon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      />
                    )}
                  </div>
                </Form.Group>
                <Form.Group className={styles.FormGroup}>
                  <Form.Label className={styles.FormLabel}>
                    {t("CONFIRM_PASSWORD_LABEL")}
                  </Form.Label>
                  <Form.Control
                    className={styles.FormInput}
                    placeholder={`${t("CONFIRM_PASSWORD_PLACEHOLDER_TEXT")}`}
                    type={confirmPasswordShow ? "text" : "password"}
                    autoComplete="new-password"
                    name="confirmPassword"
                    {...register("confirmPassword", {
                      onChange: (e) => {
                        passwordKeyPress(e);
                      },
                      ...sanitizeHtmlTags(),
                      required: {
                        value: true,
                        message: `${t("CONFIRM_PASSWORD_REQUIRED_MESSAGE")}`,
                      },
                      validate: (value) =>
                        value === password.current ||
                        `${t("CONFIRM_PASSWORD_NOT_MATCH")}`,
                    })}
                  />

                  {errors.confirmPassword && (
                    <span className="errorMsg">
                      {errors.confirmPassword.message}
                    </span>
                  )}
                  <div className="passwordicon">
                    {confirmPasswordShow ? (
                      <FaEye
                        className="icon"
                        onClick={() =>
                          setConfirmPasswordShow(!confirmPasswordShow)
                        }
                      />
                    ) : (
                      <FaEyeSlash
                        className="icon"
                        onClick={() =>
                          setConfirmPasswordShow(!confirmPasswordShow)
                        }
                      />
                    )}
                  </div>
                </Form.Group>
              </>
            )}

            <div className="pinInputText">
              <Form.Label className={styles.pinFormLabel}>
                {t("PIN_NUMBER_LABEL")}
              </Form.Label>
              <Controller
                control={control}
                name="pin"
                render={({ field }) => (
                  <PinInput
                    className="customPinInput"
                    {...field}
                    length={6}
                    type="numeric"
                    style={{ padding: "10px, 10px", width: "100%" }}
                    inputStyle={{ borderColor: "#DEDEDE" }}
                    inputFocusStyle={{ borderColor: "#DEDEDE" }}
                    onComplete={(value, index) => {
                      const isPinValid = value.length === 6;
                      setValue("pin", isPinValid ? value : "");
                      clearErrors("");
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    value={pinValue}
                    initialValue={pinValue}
                    onChange={(value) => {
                      setPinValue(value);
                    }}
                  />
                )}
                rules={{
                  required: `${t("PIN_REQUIRED_MESSAGE")}`,
                }}
              />
              {errors.pin && (
                <span className="errorMsg">{errors.pin.message}</span>
              )}
            </div>

            <div className={styles.nextBtn}>
              <button type="submit">{t("NEXT_BUTTON")}</button>
            </div>

            <div className={styles.otherOption}>
              <p>{t("OR_SOCIAL_SIGNIN")}</p>
            </div>

            <div className={styles.socialIcon}>
              <div className={styles.socialOption}>
                <GoogleLogin />
              </div>
              {/* <div className={styles.socialOption}>
                <FaceBookLogin />
              </div> */}
            </div>
          </Form>
          <div className={styles.havaAccount}>
            <p className={styles.accText}>
              {t("ALREADY_HAVE_ACCOUNT")}
              <Link to="/login" className="font-semibold">
                {t("SIGN_IN_NOW")}
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SignUpStepFirst;