import styles from "../Login/Login.module.css";
import logoImg from "../../Assests/Images/LoginImg.png";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import "../../Assests/Styles/Common.css";
import { useTranslation } from "react-i18next";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useEffect, useState } from "react";
import {
  clearSignupData,
  resetSwitchTo,
  setCurrentPage,
  updateLockUnlockStatus,
  userLogin,
  clearProfileData,
  resetActiveTab,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../utils/StatusMsg.js";
import { STATUS_CODES } from "../../utils/StatusCode.js";
import Toster from "../../utils/Toster.js";
import Loader from "../../utils/Loader/Loader.js";
import GoogleLogin from "../SocialLogin/GoogleLogin.js";
import OptionButton from "../../utils/LanguageOption/OptionButton.js";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.user);
  const [passwordShow, setPasswordShow] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();


  // ======== function for userLogin api calling =========
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("signup_type", "normal");
    requestData.append("email", formdata.email.trim());
    requestData.append("password", formdata.password.trim());
    dispatch(userLogin(requestData)).then((response) => {
      const responsejson = response.payload;
      const ResponseCode = STATUS_MSG[responsejson?.data?.code];
      if (responsejson?.code === STATUS_CODES.SUCCESS) {
        
      } else if (responsejson?.data?.code === STATUS_CODES.USER_NOT_FOUND) {
        Toster(t("USER_NOT_FOUND"), "error");
      } else if (responsejson?.data.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(responsejson?.data.message, "error");
      } else if (
        response?.data?.code == STATUS_CODES.ACCOUNT_CURRENTLY_INACTIVE ||
        response?.code == STATUS_CODES.ACCOUNT_CURRENTLY_INACTIVE
      ) {
        Toster(t("ACCOUNT_INACTIVE_ERROR"), "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  //======= disable space on key press input email field========
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  //========= disable space on key press input password field==========
  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  //======== function for clear redux state===========
  useEffect(() => {
    dispatch(clearSignupData(true));
    dispatch(resetSwitchTo());
    dispatch(updateLockUnlockStatus());
    dispatch(setCurrentPage());
    dispatch(clearProfileData());
    dispatch(resetActiveTab());
  }, []);

  return (
    <div className={styles.loginSection}>
      {isLoading ? <Loader /> : ""}
      <div className={styles.backGround}>
        <img src={logoImg} onClick={() => navigate("/")}></img>
      </div>
      <Container>
        <div className={styles.loginForm}>
          <h3>{t("LOGIN_TITLE")}</h3>
        </div>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("EMAIL_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("EMAIL_PLACEHOLDER_TEXT")}`}
                type="text"
                autoComplete="off"
                {...register("email", {
                  onChange: (e) => onInputChange(e),
                  required: {
                    value: true,
                    message: `${t("EMAIL_REQUIRED_MESSAGE")}`,
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: `${t("EMAIL_INVALID")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.email && (
                <span className="errorMsg">{errors.email.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("PASSWORD_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("PASSWORD_PLACEHOLDER_TEXT")}`}
                type={passwordShow ? "text" : "password"}
                autoComplete="new-password"
                {...register("password", {
                  onChange: (e) => {
                    passwordKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("PASSWORD_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 6,
                    message: `${t("PASS_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 12,
                    message: `${t("PASS_MAXLENGTH")}`,
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!]).{6,12}$/,
                    message: `${t("PASSWORD_MUST_CONTAIN")}`,
                  },
                })}
              />

              {errors.password && (
                <span className="errorMsg">{errors.password.message}</span>
              )}

              <div className="passwordicon">
                {passwordShow ? (
                  <FaEye
                    className="icon"
                    onClick={() => setPasswordShow(!passwordShow)}
                  />
                ) : (
                  <FaEyeSlash
                    className="icon"
                    onClick={() => setPasswordShow(!passwordShow)}
                  />
                )}
              </div>
            </Form.Group>
            <div>
              <p className={styles.forgotPassword}>
                <Link to="/forgot-password">{t("FORGOT_PASSWORD_LINK")}</Link>
              </p>
            </div>

            <div className={styles.loginBtn}>
              <button>{t("SIGN_IN_BUTTON")}</button>
            </div>

            <div className={styles.otherOption}>
              <p>{t("OR_SOCIAL_SIGNIN")}</p>
            </div>

            <div className={styles.socialIcon}>
              <div className={styles.socialOption}>
                <GoogleLogin />
              </div>
            </div>
          </Form>

          <div className={styles.localOption}>
            <div className={styles.languageBtn}>
              <OptionButton />
            </div>

            <div className={styles.havaAccount}>
              <p className={styles.accText}>
                {t("DONT_HAVE_ACCOUNT")}
                <Link to="/sign-up" className="font-semibold">
                  {t("SIGN_UP_NOW")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Login;