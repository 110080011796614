import "../../Assests/Styles/Common.css";
import { useTranslation } from "react-i18next";
import styles from "../SignUp/SignUp.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { Container, Form, Modal } from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BikeColorModal from "./BikeColorModal";
import { IoCloudUploadOutline } from "react-icons/io5";
import scannerImg from "../../Assests/Images/scanner.png";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { setSignupData } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseOutline } from "react-icons/io5";
import dayjs from "dayjs";
import bikeInfo from "../../Assests/Images/bike_information_ico.svg";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";

import CameraModal from "./CameraModel";

function SignUpStepThird({ setSwitchTo, setPreviousValue, previousValue }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmNavigation, setConfirmNavigation] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const { switchTo, signupData } = useSelector((state) => state.user);
  const [bikeColorModalOpen, setBikeColorModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    signupData && signupData.selectedFile ? signupData.selectedFile : null
  );
  const [selectedBikeColor, setSelectedBikeColor] = useState(
    signupData && signupData.bike_color ? signupData.bike_color : ""
  );
  const [imgError, setImgError] = useState("");

  const [showCameraModal, setShowCameraModal] = useState(false);

  const handleOpenCameraModal = () => {
    setShowCameraModal(true);
  };

  const handleCloseCameraModal = () => {
    setShowCameraModal(false);
  };

  //======= function for upload image onChnage=========
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/webp",
    ];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result); // Set binary content of the image to state
      };
      reader.readAsDataURL(file);
      setImgError(""); // Clear any previous error message
    } else {
      setImgError(
        "Invalid file type. Please select a .jpg, .jpeg, .gif, .png, or .webp file."
      ); // Set error message
    }
  };

  //======= useForm property for manage validation==========
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  //  ------function for submit form------
  const onSubmit = async (formData) => {
    if (!selectedFile || imgError) {
      setImgError(t("SELECT_IMAGE"));
      return;
    }
    let res = SublyApi.getSerialNumber(formData?.serialnumber);
    res.then((response) => {
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODES.SUCCESS) {
        const data = {
          ...formData,
          selectedFile: selectedFile,
          bike_color: selectedBikeColor,
          is_skip: 0,
        };

        dispatch(setSignupData(data));
        setSwitchTo(3);
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  // ========Function to disable future dates and limit selection to current and previous year
  const disableFutureDates = (date) => {
    const currentYear = new Date().getFullYear();
    const nextOneYears = currentYear + 1;
    return date.isAfter(new Date(nextOneYears, 11, 31));
  };

  //======= function for show form data prefilled==========
  useEffect(() => {
    if (previousValue == false) {
    } else {
      if (signupData && Object.keys(signupData).length > 0) {
        setValue("licenseNumber", signupData.licenseNumber);
        setValue("serialnumber", signupData.serialnumber);
        setValue("modalNumber", signupData.modalNumber);
        setValue(
          "yearOfBike",
          signupData.yearOfBike ? new Date(signupData.yearOfBike) : ""
        );
        setValue("bikeColor", signupData.bikeColor);
        setSelectedBikeColor(signupData.bike_color);
      }
    }
  }, [switchTo]);

  //============ disable space on key press input password field========
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //========= function for skip third step form============
  const skipeKeyPress = () => {
    const data = {
      is_skip: 1,
    };
    dispatch(setSignupData(data));
    setSwitchTo(3);
  };

  // Function to handle user's choice from the alert modal
  const handleAlertModalChoice = (choice) => {
    if (choice === "ok") {
      const formData = {
        licenseNumber: getValues("licenseNumber"),
        serialnumber: getValues("serialnumber"),
        modalNumber: getValues("modalNumber"),
        yearOfBike: getValues("yearOfBike"),
        bikeColor: getValues("bikeColor"),
        selectedFile: selectedFile,
        selectedBikeColor: selectedBikeColor,
        bike_color: selectedBikeColor,
      };
      dispatch(setSignupData(formData));
      // If user clicks OK, navigate to the previous page
      setSwitchTo(1);
      setPreviousValue(true);
    }
    // Close the alert modal in both cases
    setShowAlertModal(false);
  };

  // Add confirmation for navigation away from the form
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    const handleBackButton = (event) => {
      setShowAlertModal(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [showAlertModal]);

  return (
    <div className={styles.signpWrapper}>
      <div className={styles.tittle}>
        <div className={styles.backIcon}>
          <IoMdArrowRoundBack onClick={() => setShowAlertModal(true)} />
        </div>
        <Modal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className={styles.goBack}>{t("BACK_ALERT_TEXT")}</div>
          </Modal.Body>
          <div className={styles.Btns}>
            <button
              className={styles.cancelBtn}
              onClick={() => handleAlertModalChoice("cancel")}
            >
              {t("CANCEL")}
            </button>
            <button
              className={styles.deleteBtn}
              onClick={() => handleAlertModalChoice("ok")}
            >
              {t("OK")}
            </button>
          </div>
        </Modal>
        <div className={styles.signUpTitleBike}>
          <p>
            {t("BIKE_INFORMATION_TITTLE")}
            <img src={bikeInfo}></img>
          </p>
        </div>
        <p className={styles.stepCount}>
          3 <span>/4</span>
        </p>
      </div>

      <Container>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("LICENSE_NUMBER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("LICENSE_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("licenseNumber", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("LICENSE_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 2,
                    message: `${t("VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 15,
                    message: `${t("VALIDATION_MAXLENGTH")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.licenseNumber && (
                <span className="errorMsg">{errors.licenseNumber.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("MODAL_NUMBER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("MODAL_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("modalNumber", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("MODAL_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 2,
                    message: `${t("VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 15,
                    message: `${t("VALIDATION_MAXLENGTH")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.modalNumber && (
                <span className="errorMsg">{errors.modalNumber.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("BIKE_COLOR_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("BIKE_COLOR__PLACEHOLDER_TEXT")}`}
                type="text"
                readOnly
                onClick={() => setBikeColorModalOpen(true)}
                {...register("bikeColor", {
                  required: {
                    value: true,
                    message: `${t("BIKE_COLOR__REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setBikeColorModalOpen(true);
                }}
              />

              {errors.bikeColor && (
                <span className="errorMsg">{errors.bikeColor.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <div className="dateOfBirth">
                <Controller
                  control={control}
                  name="yearOfBike"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDatePicker"]}>
                        <DemoItem>
                          <MobileDatePicker
                            views={["year"]}
                            label={t("BIKE_YEAR_LABEL")}
                            defaultValue={
                              signupData.yearOfBike
                                ? dayjs(signupData.yearOfBike)
                                : ""
                            }
                            onChange={(newValue) => {
                              onChange(newValue);
                              clearErrors("yearOfBike"); // Handle date change to remove error message
                            }}
                            shouldDisableDate={disableFutureDates}
                            minDate={dayjs(new Date("1901-01-01"))}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                />

                <div className="errorMsg">
                  {errors.yearOfBike && <p>{t("SELECT_BIKE_YEAR")}</p>}
                </div>
              </div>
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SERIAL_NUMBER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("SERIAL_NUMBER_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("serialnumber", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("SERIAL_NUMBER_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 5,
                    message: `${t("SERIAL_VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 50,
                    message: `${t("SERIAL_VALIDATION_MAXLENGTH")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.serialnumber && (
                <span className="errorMsg">{errors.serialnumber.message}</span>
              )}
            </Form.Group>

            <div className={styles.uploadBox}>
              <p>{t("UPLOAD_BIKE_PHOTO_LABEL")}</p>
              {selectedFile ? (
                <div className={styles.imgUpload}>
                  <IoCloseOutline
                    className={styles.closeIcon}
                    onClick={() => {
                      setSelectedFile(null);
                    }}
                  />
                  <img
                    className={styles.afterUploadImg}
                    src={selectedFile}
                    alt="Uploaded"
                  />
                </div>
              ) : (
                <div className={styles.uploadIcon}>
                  <label htmlFor="file-input" className={styles.uploadLabel}>
                    <IoCloudUploadOutline className="upload-icon" />
                    {t("UPLOAD_PHOTO")}
                  </label>
                </div>
              )}

              <input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <div className="errorMsg">
                {imgError && <div className="errorMsg">{imgError}</div>}
              </div>
            </div>

            <div className={styles.qrScanner}>
              <p>{t("SCANER_QR_CODE")}</p>
              <div
                className={styles.scannerImg}
                style={{ cursor: "pointer" }}
                onClick={handleOpenCameraModal}
              >
                <img src={scannerImg} alt="Scanner" />
              </div>
            </div>

            <div className={styles.nextBtn}>
              <button type="submit">{t("NEXT_BUTTON")}</button>
            </div>
            <div className={styles.skipButton}>
              <p onClick={() => skipeKeyPress()}>{t("SKIP_BUTTON")}</p>
            </div>
          </Form>
          <BikeColorModal
            bikeColorModalOpen={bikeColorModalOpen}
            setBikeColorModalOpen={setBikeColorModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            selectedBikeColor={selectedBikeColor}
            setSelectedBikeColor={setSelectedBikeColor}
          />

          <CameraModal
            show={showCameraModal}
            handleClose={handleCloseCameraModal}
            setValue={setValue}
          />
        </div>
      </Container>
    </div>
  );
}

export default SignUpStepThird;