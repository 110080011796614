import styles from "../Home/HomeList.module.css";
import { FaBell } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaFilter } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaLock } from "react-icons/fa6";
import HomeFooterOption from "./HomeFooterOption";
import {
  fetchVehicleList,
  setCurrentPage,
  userLogoutClear,
} from "../../store/slices/userSlice";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useNavigate } from "react-router-dom";
import LockUnlockModal from "./LockUnlockModal";
import VehicleFilterModal from "./VehicleFilterModal";
import { FaUnlock } from "react-icons/fa";
import "../../Assests/Styles/Common.css";
import Loader from "../../utils/Loader/Loader";
import { debounce } from "lodash";

function HomeList() {
  const listInnerRef = useRef(null);
  const {
    userToken,
    vehicleListData,
    isLoading,
    loadMore,
    currentPage,
    currentUser,
    unreadNotification,
  } = useSelector((state) => state.user);
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openLock, setOpenLock] = useState();
  const [vehicleModalOpen, setVehicleModalOpen] = useState();
  const [vehicleSearch, setVehicleSearch] = useState("");
  const [selectedVehicleId, setSelectedVehicleId] = useState();
  const [selectedFilterHome, setSelectedFilterHome] = useState("");

  //========== function for calling get vehicle list api==========
  async function getVehicleList(
    pageNum = 1,
    vehicleSearch = "",
    selectedFilterHome = "",
    onLoadMore = false
  ) {
    await dispatch(
      fetchVehicleList({
        userToken: userToken,
        page_no: pageNum,
        search: vehicleSearch,
        filterByVehicle: selectedFilterHome,
        onLoadMore: onLoadMore,
      })
    ).then((responsejson) => {
      const { response } = responsejson.payload;
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else if (onLoadMore == true && loadMore) {
        dispatch(setCurrentPage(currentPage + 1));
      }
    });
  }

  //====== Function to load more vehicles========
  const loadMoreVehicles = () => {
    if (loadMore) {
      getVehicleList(currentPage + 1, vehicleSearch, selectedFilterHome, true);
    }
  };

  useEffect(() => {
    dispatch(setCurrentPage(1));
    getVehicleList();
  }, []);

  //===== function for load more data on scrolling==========
  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        loadMoreVehicles();
      }
    }
  };

  //====== Function to handle lock/unlock action=======
  const handleLockUnlock = (vehicleId) => {
    setOpenLock(true);
    setSelectedVehicleId(vehicleId);
  };

  //======= Function to handle filtering by vehicle type=========
  const handleFilterByVehicle = () => {
    getVehicleList("", "", selectedFilterHome);
    setVehicleModalOpen(false); // Close the filter modal after selecting a vehicle type
  };

  //===== Define a debounce function for handling search input change==========
  const debouncedSearch = debounce((searchValue) => {
    getVehicleList(1, searchValue, selectedFilterHome);
    dispatch(setCurrentPage(1));
  }, 300); // 300 milliseconds debounce time

  //===== Function to handle search input change========
  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    setVehicleSearch(searchValue);
    debouncedSearch(searchValue);
  };

  return (
    <div className={styles.listWrapper}>
      <div className={styles.displayItem}>
        <div className={styles.topItem}>
          {unreadNotification > 0 ? (
            <div className={styles.notificationTick}></div>
          ) : (
            ""
          )}
          <FaBell onClick={() => navigate("/notification")} />
        </div>
        <div className={styles.rightItem}>
          <h5>{t("HOME_TITLE")}</h5>
        </div>
        <div className={styles.topItemRight}>
          <FaPlus onClick={() => navigate("/add-vehicle-list")} />
          <FaFilter onClick={() => setVehicleModalOpen(true)} />
        </div>
      </div>

      <div onScroll={handleScroll} ref={listInnerRef} className="fixContent">
        <div className={styles.searchBar}>
          <Form.Group className={styles.formGroup}>
            <Form.Control
              className={styles.searchControl}
              type="search"
              placeholder={t("SEARCH")}
              value={vehicleSearch}
              onChange={handleSearchInputChange}
            />
            <div className={styles.searchIcon}>
              <CiSearch />
            </div>
          </Form.Group>
        </div>

        {vehicleListData && vehicleListData.length > 0 ? (
          vehicleListData.map((item, index) => {
            return (
              <div className={styles.bikeHistory} key={index}>
                <div
                  className={styles.bikeImage}
                  onClick={() => navigate(`/vehicle-detail/${item.id}`)}
                >
                  <img src={item.vehicle_image} alt={item.vehicle_model} />
                </div>
                <div className={styles.rightText}>
                  <div className={styles.text}>
                    <h4>{item.year}</h4>
                    <h5>{item.vehicle_model} </h5>
                    <p>{item.license_plate_number}</p>
                    <p>{item.assign_by}</p>
                    <p className={styles.geoFenceStatus}>
                      {t("GEO_FENCE_TEXT")}{" "}
                      <span>
                        {item.geo_fence_status === 0
                          ? t("OFF_TEXT")
                          : `${t("ON_TEXT")} (${item.distance} KM)`}
                      </span>
                    </p>
                  </div>
                </div>
                <div className={styles.bottomItem}>
                  <h4>{item.colour_name}</h4>
                  {item.is_lock === 1 ? (
                    <div className={styles.lockImg}>
                      <FaLock onClick={() => handleLockUnlock(item.id)} />{" "}
                    </div>
                  ) : (
                    <div className={styles.unlockImg}>
                      <FaUnlock onClick={() => handleLockUnlock(item.id)} />{" "}
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : isLoading ? (
          <Loader />
        ) : (
          <div className={styles.noResultsMessage}>
            {t("NO_VEHICLES_FOUND")}
          </div>
        )}
        {isLoading && <Loader />}
      </div>

      <HomeFooterOption />
      <LockUnlockModal
        openLock={openLock}
        setOpenLock={setOpenLock}
        selectedVehicleId={selectedVehicleId}
      />
      <VehicleFilterModal
        vehicleModalOpen={vehicleModalOpen}
        setVehicleModalOpen={setVehicleModalOpen}
        handleFilterByVehicle={handleFilterByVehicle}
        selectedFilterHome={selectedFilterHome}
        setSelectedFilterHome={setSelectedFilterHome}
        vehicleSearch={vehicleSearch}
        filterList={getVehicleList}
      />
    </div>
  );
}

export default HomeList;
