import styles from "./Setting.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";
import Loader from "../../utils/Loader/Loader";
import lockImg from "../../Assests/Images/change.png";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/slices/userSlice";

function ChangePassword() {
  const { userToken } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("newPassword", "");

  //----function for calling api change password-----
  const onSubmit = async (formdata) => {
    setLoader(true);
    let requestData = new FormData();
    requestData.append("old_password", formdata.oldPassword);
    requestData.append("new_password", formdata.newPassword);
    requestData.append("cnf_password", formdata.confirmPassword);
    await SublyApi.changePassword(userToken, requestData).then(
      (responsejson) => {
        const ResponseCode =
          STATUS_MSG[
            responsejson && responsejson.data && responsejson.data.code
          ];
        if (responsejson && responsejson.code === STATUS_CODES.SUCCESS) {
          Toster(t("CHANGE_PASSWORD_SUCCUSS"), "success");
          navigate("/setting");
          setValue("oldPassword", "");
          setValue("newPassword", "");
          setValue("confirmPassword", "");
          setLoader(false);
        } else if (
          responsejson &&
          responsejson.data.code === STATUS_CODES.VALIDATION_ERROR
        ) {
          Toster(responsejson && responsejson.data.message, "error");
          setLoader(false);
        } else if (
          responsejson &&
          responsejson.data &&
          responsejson.data.code == STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
        } else {
          Toster(t(ResponseCode), "error");
          setLoader(false);
        }
      }
    );
  };

  // ------- disable space on key press input password field-------------
  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  return (
    <div className={styles.settingWrapper}>
      {loader ? <Loader /> : ""}

      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.forgotText}>
            <p>{t("CHANGE_PASSWORD")}</p>
          </div>
        </div>
      </div>

      <div className={styles.overLay}>
        <div className={styles.resetWrapper}>
          <div className={styles.resetLockImg}>
            <img src={lockImg}></img>
          </div>
          <Container>
            <div className={styles.inputForm}>
              <Form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.formSection}
              >
                <Form.Group className={styles.FormGroup}>
                  <Form.Label className={styles.FormLabel}>
                    {t("OLD_PASSWORD_LABEL")}
                  </Form.Label>
                  <Form.Control
                    className={styles.FormInput}
                    placeholder={`${t("OLD_PASSWORD_PLACEHOLDER_TEXT")}`}
                    type={oldPasswordShow ? "text" : "password"}
                    name="password"
                    autoComplete="new-password"
                    {...register("oldPassword", {
                      onChange: (e) => {
                        passwordKeyPress(e);
                      },
                      ...sanitizeHtmlTags(),
                      required: {
                        value: true,
                        message: `${t("OLD_PASSWORD_REQUIRED_MESSAGE")}`,
                      },
                      minLength: {
                        value: 6,
                        message: `${t("PASS_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 12,
                        message: `${t("PASS_MAXLENGTH")}`,
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!]).{6,12}$/,
                        message: `${t("PASSWORD_MUST_CONTAIN")}`,
                      },
                    })}
                  />

                  {errors.oldPassword && (
                    <span className="errorMsg">
                      {errors.oldPassword.message}
                    </span>
                  )}
                  <div className="passwordicon">
                    {oldPasswordShow ? (
                      <FaEye
                        className="icon"
                        onClick={() => setOldPasswordShow(!oldPasswordShow)}
                      />
                    ) : (
                      <FaEyeSlash
                        className="icon"
                        onClick={() => setOldPasswordShow(!oldPasswordShow)}
                      />
                    )}
                  </div>
                </Form.Group>
                <Form.Group className={styles.FormGroup}>
                  <Form.Label className={styles.FormLabel}>
                    {t("NEW_PASSWORD_LABEL")}
                  </Form.Label>
                  <Form.Control
                    className={styles.FormInput}
                    placeholder={`${t("NEW_PASSWORD_PLACEHOLDER_TEXT")}`}
                    type={newPasswordShow ? "text" : "password"}
                    autoComplete="new-password"
                    name="newPassword"
                    {...register("newPassword", {
                      onChange: (e) => {
                        passwordKeyPress(e);
                      },
                      ...sanitizeHtmlTags(),
                      required: {
                        value: true,
                        message: `${t("NEW_PASSWORD_REQUIRED_MESSAGE")}`,
                      },
                      minLength: {
                        value: 6,
                        message: `${t("PASS_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 12,
                        message: `${t("PASS_MAXLENGTH")}`,
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!]).{6,12}$/,
                        message: `${t("PASSWORD_MUST_CONTAIN")}`,
                      },
                    })}
                  />

                  {errors.newPassword && (
                    <span className="errorMsg">
                      {errors.newPassword.message}
                    </span>
                  )}
                  <div className="passwordicon">
                    {newPasswordShow ? (
                      <FaEye
                        className="icon"
                        onClick={() => setNewPasswordShow(!newPasswordShow)}
                      />
                    ) : (
                      <FaEyeSlash
                        className="icon"
                        onClick={() => setNewPasswordShow(!newPasswordShow)}
                      />
                    )}
                  </div>
                </Form.Group>
                <Form.Group className={styles.FormGroup}>
                  <Form.Label className={styles.FormLabel}>
                    {t("CONFIRM_PASSWORD_LABEL")}
                  </Form.Label>
                  <Form.Control
                    className={styles.FormInput}
                    placeholder={`${t("CONFIRM_PASSWORD_PLACEHOLDER_TEXT")}`}
                    type={confirmPasswordShow ? "text" : "password"}
                    autoComplete="new-password"
                    name="confirmPassword"
                    {...register("confirmPassword", {
                      onChange: (e) => {
                        passwordKeyPress(e);
                      },
                      ...sanitizeHtmlTags(),
                      required: {
                        value: true,
                        message: `${t("CONFIRM_PASSWORD_REQUIRED_MESSAGE")}`,
                      },
                      validate: (value) =>
                        value === password.current ||
                        `${t("CONFIRM_PASSWORD_NOT_MATCH")}`,
                    })}
                  />

                  {errors.confirmPassword && (
                    <span className="errorMsg">
                      {errors.confirmPassword.message}
                    </span>
                  )}
                  <div className="passwordicon">
                    {confirmPasswordShow ? (
                      <FaEye
                        className="icon"
                        onClick={() =>
                          setConfirmPasswordShow(!confirmPasswordShow)
                        }
                      />
                    ) : (
                      <FaEyeSlash
                        className="icon"
                        onClick={() =>
                          setConfirmPasswordShow(!confirmPasswordShow)
                        }
                      />
                    )}
                  </div>
                </Form.Group>

                <div className={styles.logOutBtn}>
                  <button>{t("SUBMIT_BUTTON")}</button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
