export const TRANSLATIONS_EN = {
  // -----------------------------Login TEXT-------------------------------------
  LOGIN_TITLE: "Welcome to G-LOCK",
  EMAIL_LABEL: "Email Id",
  EMAIL_PLACEHOLDER_TEXT: "Enter Email Id",
  PASSWORD_PLACEHOLDER_TEXT: "Enter Password",
  EMAIL_REQUIRED_MESSAGE: "Please enter your email",
  PASSWORD_REQUIRED_MESSAGE: "Please enter your password",
  FORGOT_PASSWORD_LINK: "Forgot Password ?",
  SIGN_IN_BUTTON: "Sign In",
  OR_SOCIAL_SIGNIN: "Or sign in with social media",
  DONT_HAVE_ACCOUNT: "Don't have an account yet ?",
  SIGN_UP_NOW: "Sign Up Now",
  EMAIL_INVALID: "Please enter a valid email address",
  LOGIN_SUCCESSFULLY: "Login Succesfully",
  EMAIL_ALREADY_IN_USE: "Email already in use, please use other emails.",

  // -----------------------------ForgotPassword TEXT----------------------------
  FORGOT_PASSWORD: "Forgot Password",
  FORGOT_YOUR_PASSWORD: "Forgot Your Password?",
  FORGOT_PARA: `Please enter your email address, You will receive a link to create a
  new password via email`,
  RESET_PASSWORD: "Reset Password",
  NEW_PASSWORD: "New Password ",
  SUBMIT_BUTTON: "Submit",
  OTP_REQUIRED: "Please enter otp",
  DIGIT_REQUIRED: "Please enter 6 digit otp",
  OTP_INPUT_LABEL: "Please enter the code that you received on your mail id",
  //-----------------------------SignUp TEXT----------------------------
  ACCOUNT_INFORMATION_TITTLE: "Account Information",
  NAME_LABEL: "Name",
  PASSWORD_LABEL: "Password",
  CONFIRM_PASSWORD_LABEL: "Confirm Password",
  ENTER_HEALTH_ISSUE: "Enter other health issue",
  CONFIRM_PASSWORD_PLACEHOLDER_TEXT: "Enter confirm password",
  NAME_PLACEHOLDER_TEXT: "Enter Name",
  NAME_REQUIRED_MESSAGE: "Please enter your name",
  CONFIRM_PASSWORD_REQUIRED_MESSAGE: "Please enter your confirm password",
  SIGN_IN_NOW: "Sign In",
  ALREADY_HAVE_ACCOUNT: "Already have an account yet?",
  NEXT_BUTTON: "Next",
  PIN_NUMBER_LABEL: "PIN Number",
  ADDRESS_LABEL: "Address",
  ADDRESS_PLACEHOLDER_TEXT: "Enter Address",
  ADDRESS_REQUIRED_MESSAGE: "Please enter your address",
  NATIONAL_ID_LABEL: "National Id",
  NATIONAL_ID_PLACEHOLDER_TEXT: "Enter National Id",
  NATIONAL_ID_REQUIRED_MESSAGE: "Please enter your national id",
  BLOOD_TYPE_LABEL: "Blood Type",
  BLOOD_TYPE_PLACEHOLDER_TEXT: "Select Blood Type",
  BLOOD_TYPE_REQUIRED_MESSAGE: "Please select your blood type",
  HEALTH_ISSUE_LABEL: "Specify Health Issues",
  HEALTH_ISSUE_PLACEHOLDER_TEXT: "Select Specify Health Issues",
  HEALTH_ISSUE_REQUIRED_MESSAGE: "Please select your health issue",
  SELECT_HEALTH_ISSUE_TITLE: "Select Specify Health Issues",
  SELECT_BLOOD_TYPE: "Select Blood Type",
  DONE_BUTTON: "Done",
  CONFIRM_PASSWORD_NOT_MATCH: "Password does not match the confirm password",
  PIN_REQUIRED: "PIN Number is Required",
  PERSONAL_INFORMATION_TITTLE: "Personal Inormation",
  PHONE_NUMBER_LABEL: "Phone Number",
  PASS_MAXLENGTH: "Please enter password, with a maximum of 12 characters",
  PASS_MINLENGTH: "Please enter password, with a minimum of 6 characters",
  DOB_LABEL: "Birthday",
  DOB_PLACEHOLDER: "Enter Birthday",
  DATE_OF_BIRTH_REQUIRED: "Please select your date of birth",
  PHONE_NUMBER_PLACEHOLDER: "Enter Phone Number",
  HOME_NUMBER_LABEL: "Enter Home Number",
  HOME_NUMBER_PLACEHOLDER: "Enter Home Number",
  COMPANY_LABEL: "Company",
  COMPANY_PLACEHOLDER_TEXT: "Enter Company",
  COMPANY_REQUIRED_MESSAGE: "Please enter your company name",
  SECURITY_QUESTION_LABEL1: "Security Questions 1",
  SECURITY_PLACEHOLDER_TEXT: "Select security questions ",
  SECURITY_QUESTION_REQUIRED_MESSAGE: "Please select your security question",
  SECURITY_ANSWER_LABEL: "Answer",
  ANSWER_PLACEHOLDER_TEXT: "Enter Answer",
  ANSWER_REQUIRED_MESSAGE: "Please enter your security answer",
  SECURITY_QUESTION_LABEL2: "Security Questions 2",
  SECURITY_QUESTION_LABEL3: "Security Questions 3",
  SECURITY_QUESTION_TITLE: "Security Questions",
  BIKE_INFORMATION_TITTLE: "Bike Information",
  LICENSE_NUMBER_LABEL: "License Plate Number",
  LICENSE_PLACEHOLDER_TEXT: "Enter license plate number",
  LICENSE_REQUIRED_MESSAGE: "Please enter your license plate number",
  MODAL_NUMBER_LABEL: "Model of the Bike",
  MODAL_PLACEHOLDER_TEXT: "Enter model of the bike",
  MODAL_REQUIRED_MESSAGE: "Please enter your modal number",
  BIKE_COLOR_LABEL: "Color of the Bike",
  BIKE_COLOR__PLACEHOLDER_TEXT: "Select color of the bike ",
  BIKE_COLOR__REQUIRED_MESSAGE: "Please select your bike color",
  YEAR_OF_BIKE_LABEL: "Year of the Bike",
  BIKE_COLOR_TYPE: "Color of the Motor",
  YEAR_OF_BIKE_REQUIRED: "Please select year of bike",
  SKIP_BUTTON: "Skip",
  SELECT_BIKE_YEAR: "Please select year of bike",
  PIN_REQUIRED_MESSAGE: "Please enter your PIN number",
  UPLOAD_BIKE_PHOTO_LABEL: "Upload Bike Photo",
  EMERGENCY_CONTACT_TITLE: "Emergency Contacts",
  CREATE_ACCOUNT_BUTTON: "Create Account",
  RELATIONSHIP_LABEL: "Relationship",
  RELATIONSHIP_PLACEHOLDER_TEXT: "Enter Relationship",
  RELATIONSHIP_REQUIRED_MESSAGE: "Please enter your relationship",
  CONTACT_NUMBER_REQUIRED: "Please enter your phone number",
  PHONE_NUMBER_LENGTH_ERROR: "Please enter valid phone number",
  HOME_NUMBER_REQUIRED: "Please enter your home number",
  HOME_NUMBER_LENGTH_ERROR: "Please enter valid home number",
  OTHER_LABEL: "Other health issue",
  CONTACT1_TITLE: "Contact 1",
  CONTACT2_TITLE: "Contact 2",
  CONTACT3_TITLE: "Contact 3",
  SET_DEFAULT_CONTACT: "Set Default",
  UPLOAD_PHOTO: "Upload Photo",
  OTHER_INPUT_REQUIRED: "Please enter other health issue",
  VALIDATION_MINLENGTH: "Please enter min 2 character",
  VALIDATION_MAXLENGTH: "Please enter max 15 character",
  ADDRESS_VALIDATION_MINLENGTH: "Please enter min 5 character",
  ADDRESS_VALIDATION_MAXLENGTH: "Please enter min 250 character",
  NAME_VALIDATION_MINLENGTH: "Please enter min 2 character",
  NAME_VALIDATION_MAXLENGTH: "Please enter max 25 character",
  VALIDATION_MINLENGTH_NATIONAL_ID: "Please enter min 5 character",
  VALIDATION_MAXLENGTH_NATIONAL_ID: "Please enter max 25 character",
  PASSWORD_MUST_CONTAIN:
    "Please enter password must contain one uppercase, lowercase, number, and a special character.",
  SCANER_QR_CODE: "Scanner QR Code",
  CONTACT_TEXT: "Enter your contact Number",
  NATIONAL_ID_NUMBER_EXIST: "National id number already exist",
  VEHICLE_SERIES_EXIST: "GLock serial number already exists",
  SERIAL_NUMBER_LABEL: "Serial Number",
  SERIAL_NUMBER_TEXT: "Serial number",
  SERIAL_NUMBER_PLACEHOLDER_TEXT: "Enter serial number",
  SERIAL_VALIDATION_MINLENGTH: "Please enter min 5 character",
  SERIAL_VALIDATION_MAXLENGTH: "Please enter max 25 character",
  SERIAL_NUMBER_REQUIRED_MESSAGE: "Please enter your serial number",
  ENTER_SIGNUP_PIN: "Please enter pin",
  MIN_PIN_LENGTH: "Please enter pin, with a minimum of 6 digits",
  BIRTHDAY_LABEL: "Birthday",
  BIKE_YEAR_LABEL: "Year of the bike",
  PHONE_INPUT_LABEL: "Phone Number",
  HOME_INPUT_LABEL: "Home Number",
  OPTIONAL_CONTACT_HIDE: "Hide Third Contact",
  OPTIONAL_CONTACT_SHOW: "Contact 3 (Optional)",
  BACK_ALERT_TEXT: "Are you want go back?",
  SELECT_IMAGE: "Please select an image",
  NO_HISTORY_FOUND: "No history found",
  INCORRECT_PIN_ERROR:
    "Incorrect pin number please enter correct pin to proceed.",
  FILL_OTHER_HEALTH_ISSUE: "Please fill in the other health issue",
  SECURITY_QUESTION_ONE_ERROR_MSG:
    "First security question cannot be the same as the second or third one.",
  SECURITY_QUESTION_SECOND_ERROR_MSG:
    "Second security question cannot be the same as the first one.",
  SECURITY_QUESTION_THIRD_ERROR_MSG:
    "Third security question cannot be the same as the second or first one.",

  //---------------Api response error messages text----------------
  HEADER_IS_MISSING: "Header is missing.",
  DEVICE_TYPE_NOT_ALLOWED: "Device type not allowed",
  INVALID_API_KEY: "Invalid Api Key",
  TOKEN_REQUIRE_AUTHENTICATION: "A token is required for authentication",
  INVALID_TOKEN: "Invalid Token",
  VALIDATION_ERROR_CODE:
    "Request validation error (returning message key with this)",
  SUCCESS: "Success",
  STAFF_ERROR_MSG:
    "Access Denied NOTE: this code will come when a staff is trying to run the apis which are not allow to him",
  BLOCKED_ACCOUNT: "Your account is currently blocked.",
  SOCIAL_ALREADY_EXIT: "Social account already exist",
  SOCIAL_ACCOUNT_NOT_FOUND: "Social account not found",
  INTERNAL_SERVER_ERROR: "Internal server error.",
  INVALID_LOGIN_CREDENTIAL: "Invalid login credentials",
  ACCOUNT_NOT_FOUND: "Account not found, please enter the correct email",
  ACCOUNT_CURRENTLY_INACTIVE: "Your account is currently inactive.",
  OLD_PASSWORD_DOES_NOT_MATCH: "Old password not match.",
  PHONE_NUMBER_ALREADY_USE:
    "Phone number already in use, please use other phone numbers.",
  VEHICLE_IMAGE_REQUIRED: "Motorcycle image required",
  ALL_SECURITY_QUESTION_REQUIRED: "All security questions required",
  ALL_EMERGENCY_CONTACT_REQUIRED: "All security questions required",
  FAILED_TO_SEND_EMAIL: "Email send fail.",
  INVALID_BLOOD_TYPE_ID: "Invalid blood type id",
  INVALID_HEALTH_ISSUE_ID: "Invalid health issue id",
  INVALID_SECURITY_QUESTION_ID: "Invalid security question id",
  INVALID_COLOR_ID: "Invalid colour id",
  VEHICLE_IMAGE_MAX_SIZE: "Motorcycle image max size 10 mb",
  INVALID_VEHICLE_IMAGE_EXTENSION:
    "Invalid motocicletacycle image extention pls use  jpeg gif png webp",
  INVALID_SECURITY_QUESTION_ANSWER:
    "Invalid security question answer json format",
  INVALID_SCANNER_JSON_FORMATE: "Invalid scanner json format",
  INVALID_EMERGENCY_CONTACT: "Invalid emergency contact json format",
  CODE_ALREADY_USE: "Code already used",
  CODE_DOES_NOT_MATCH: "Code does not matched",
  INVALID_CONTACT_RELATION_ID: "Invalid contact relation id",
  OTHER_HEALTH_ISSUE_REQUIRED: "Other health issue required",
  SIGNUP_SUCCESSFULLY: "Signup successfully",
  UPLOAD_PHOTO_ERROR: "Please upload bike image",
  RELATION_TYPE: "Relationship Type",
  USER_NOT_FOUND: "Account not found, please enter the correct email",
  UPDATE_PASSWORD_SUCCUSS: "Password update succusfully",
  PASSWORD_SENT: "Password sent to your gmail",
  ACCOUNT_DELETED: "Your account has been deleted.",
  EMAIL_LINKED_SOCIAL_USER: "Email is linked with social user",
  EMAIL_LINKED_WITH_NORMAL_USER: "Email is linked with normal user",
  FAILED_UPLOAD_IMAGE: "Failed to upload image",
  INVALID_VEHICLE_ID: "Invalid vehicle id",
  VEHICLE_UNBLOCKED_BY_COMPANY: "Vehicle is already unlocked by company",
  VEHICLE_LOCKED_SUCCUSFULLY: "Vehicle locked successfully",
  VEHICLE_UNLOCKED_SUCCSFULLY: "Vehicle unlocked successfully",
  LICENCE_PLATE_NUMBER_EXIST: "Vehicle license plate number already registered",
  YOUR_SESSION_EXPIRE: "Your session expired",

  //-------------------Home List-----------------
  HOME_TITLE: "Home",
  SEARCH: "Search Vehicle...",
  ADD_NEW_BIKE_TITLE: "Add New Bike",
  ENTER_LOCK_PIN: "Please enter your 6 digit PIN number",
  APPLY_FILTER_TITLE: "Filter By",
  APPLY_FILTER: "Apply Filter",
  CLEAR_FILTER: "Clear Filter",
  ADD_VEHICLE_SUCCESSFULLY: "Add new vehicle successfully",
  CHANGE_STATUS_LOCK: "Vehicle locked successfully",
  CHANGE_STATUS_UNLOCK: "Vehicle unlocked successfully",
  FORGOT_PIN_LINK: "Forgot PIN ?",
  VEHICLE_DETAIL_TITLE: "Vehicle Detail",
  KILOMETERS: "Kilometers:",
  LICENCE_PLATE: "Licence plate number",
  MODAL_NUMBER_TEXT: "Modal number",
  VEHICLE_YEAR: "Vehicle year",
  VEHICLE_COLOR: "Vehicle color",
  GEO_FENCE_BTN: "Geo fence",
  MAXLENGTH: "Please enter max 50 km distance",
  GEO_FENCE_TEXT: "Geo Fence :",
  PIN_REQUIRED_MSG: "Please enter a valid 6-digit PIN",
  ENTER_NEW_PIN_NUMBER: "Please enter new PIN",
  ENTER_OLD_PIN: "Please enter old PIN number",
  INCORRECT_PIN_NUMBER: "Incorrect PIN. Please try again.",
  ON_TEXT: "ON",
  OFF_TEXT: "OFF",
  UNLOCK_TEXT: "Unlock",
  LOCK_TEXT: "Lock",
  EMERGENCY_TEXT: "Emergency",
  NO_VEHICLES_FOUND: "No vehicles found.",
  NO_NOTIFICATION_FOUND: "No Notification found",
  KILOMETER_LABEL: "Kilometer",
  ENTER_KM_PLACEHOLDER: "Enter kilometer",
  KM_REQUIRED_MSG: "kilometer is required",
  KM_MIN_MAX_ERROR: "Kilometer should not be more than 0.5 and less than 50.",
  MY_VEHICLE_LABEL: "My vehicle",
  COMPANY_VEHICLE_LABEL: "Company vehicle",
  SELECT_VEHICLE_ERROR: "Please select a vehicle.",
  ACCOUNT_INACTIVE_ERROR:
    "You account has been inactive. Please contact admin.",
  COMPANY_NAME: "Company name",

  //-------------------setting -----------------
  LOGOUT: "Logout",
  ALERT: "Alert",
  WANT_TO_DELETE: "Are you sure you want to delete this account ?",
  CANCEL: "Cancel",
  OK: "Ok",
  SETTING_TITLE: "Settings",
  SETTING_USER_NAME: "Wanda Maximof",
  USER_EMAIL: "wanda.wilaimson@gmail.com",
  USER_PHONE_NO: "+1 - 7894561230",
  VIEW_DETAILS: " VIEW DETAILS",
  NOTIFICATION_TEXT: "Notification",
  CHANGE_PASSWORD: "Change Password",
  CHANGE_PIN: "Change PIN",
  ABOUT_US: "About Us",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_CONDITIONS: "Terms & Conditions",
  DELETE_ACCOUNT: "Delete Account",
  LOGOUT_SUCCESSFULLY: "Logout successfully",
  // CHANGE_PIN: "Change Pin",
  OLD_PIN: "Old PIN",
  NEW_PIN: "New PIN",
  FORGOT_PIN: "ForGot PIN",
  FORGOT_YOUR_PIN: "Forgot your PIN",
  UPDATE_PIN: "Update PIN",
  UPDATE_YOUR_PIN: "Update your PIN",
  CODE_REQUIRED_MESSAGE: "Please enter 6 digit code",
  ENTER_NEW_PIN: "Enter new PIN",
  OLD_PASSWORD_LABEL: "Old Password",
  NEW_PASSWORD_LABEL: "New Password",
  OLD_PASSWORD_PLACEHOLDER_TEXT: "Enter old password",
  NEW_PASSWORD_PLACEHOLDER_TEXT: "Enter new password",
  OLD_PASSWORD_REQUIRED_MESSAGE: "Please enter your old password",
  NEW_PASSWORD_REQUIRED_MESSAGE: "Please enter your new password",
  CHANGE_PASSWORD_SUCCUSS: "Password change successfully",
  NOTIFICATION_DISABLED: "Notification off successfully",
  NOTIFICATION_ENABLED: "Notification on successfully",
  NOTIFICATION_TITLE: "Notifications",
  PIN_UPDATE_SUCCESSFULLY: "PIN update successfully",
  PIN_CHANGE_SUCCESSFULLY: "PIN change successfully",
  GEO_FENCE_STATUS_CHANGE_SUCCESFULLY: "Geo fence update successfully",
  CHANGE_LANGUAGE: "Change Language",
  SELECT_LANGUAGE: "Select Language",
  CONTINUE: "Continue",
  ACCOUNT_DELETE_SUCCUSS: "Account deleted successfully",
  IMAGE_DELETE_SUCCUSS: "Image deleted successfully",
  IMAGE_ADDED_SUCESS: "Images uploaded successfully",

  //-------------------Job History -----------------
  HISTORY_TITLE: "History",
  SOS_TITLE: "SOS",
  NEED_HELP: "Need Help ?",
  PRESS_BUTTON: "Press Button",
  MAP_TITLE: "Map",
  THEFT: "Theft",
  ACCIDENT: "Accident",
  ROAD_SIDE_ASSISTANCE: "Roadside Assistance",
  SERIAL_NO_NOT_EXSIT: "GLock serial number does not exist",

  //---------------Sos modal-------------
  SELECT_VEHICLE: "Select motocicletacycle",

  //-------------------Profile  -----------------
  PROFILE_TILTLE: "Profile",
  EDIT_PROFILE_TEXT: "Edit Profile",
  PERSONAL_INFORMATION: "Personal Information",
  SECURITY_QUESTION: "Security Question",
  EMERGENCY_CONTACT: "Emergency contact",
  BIRTHDAY: "Birthday :",
  NATIONAL_ID_TEXT: "National Id :",
  BLOOD_TYPE_TEXT: "  Blood Type :",
  SPECIFY_HEALTH_ISSUE: "Specify Health Issues :",
  PROFILE_PHONE_NUMBER: "Phone Number :",
  PROFILE_HOME_NUMBER: "Home Number :",
  ADDRESS_TEXT: "Address :",
  SECURITY_QUESTION_HEAD: "Security Question :",
  EDIT_QUESTIONS: "Edit Questions",
  EDIT_EMERGENCY_CONTACT: "Edit Emergency Contacts",
  NAME_TEXT: "Name :",
  RELATIONSHIP_TEXT: "Relationship :",
  QUESTION: "Question",
  ANSWER: "Answer",
  PERSONAL_INFORMATION_TITLE: "Personal Information",
  UPDATE: "Update",
  PERSONAL_INFO_UPDATE: "Personal information update successfully",
  SECURITY_QUESTION_UPDATE: "Security question update successfully",
  EMERGENCY_CONTACT_UPDATE: "Emergency contact update succesfully",
  PROFILE_CONTACT: "Contact",
  COMPANY_OPTIONAL_TEXT:
    "Optional for users, or auto-filled for Admin creating users",
  OTHER_HEALTH_ISSUE: "Other health issue",
  //-------------------Map-----------------
  PILOT_NAME: "Pilot Name",
  PLATE_NUMBER: "License Plate Number",
  BIKE_MODEL: "Model of the Bike ",
  BIKE_COLOR: "Color of the Bike",
  BIKE_YEAR: "Year of the Bike",

  //-------------------Page Not found  -----------------
  OOPS: "Oops!",
  PAGE_404: "404 page not found",
  SORRY_PAGE_NOT_EXIST: "Sorry, the page you are looking for does not exist.",

  RESET_BTN: "Reset",
  SOS_ALREADY_EXSIT: "Sos vehicle status already updated",
  RESER_SOS_SUCCESS: "Reset SOS vehicle successfully",
  SEND_BTN: "Send",
  HISTORY_DETAIL: "History Detail",
  UNSAVED_CHANGES_CONFIRMATION: "Are you sure you want to leave?",
  VIEW_ON_MAP: "View on map",

  START_DATE: "Start date",
  END_DATE: "End date",
  STEALTH: "Stealth",
  SOS_TYPE: "SOS Type",
  INVALID_IMG_ID: "Invalid image id",
  IMAGES: "Images",
  NOTIFY: "notify",
  NOTIFY_SEND: "Notification send successfully",
  SOS_IMG_REQUIRED: "Sos image required",
  INVALID_EXTENSION: "Sos image has invalid extentions",
  INVALID_SOS_ID: "Invalid sos id",
  IMAGE_LIMIT: "Sos image cant be greater then 10 mb",
  MAX_IMAGE_LIMIT: "Max 10 image can be upload in sos",
  INVALID_FILE_TYPE: "Image extension is not valid",
  LANGUAGE_CHANGE: "Language change successfully"
};