import styles from "./Content.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { fetchContent, userLogoutClear } from "../../store/slices/userSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { CONTENT_TYPE } from "../../utils/Constants";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";

function PrivacyPolicy() {
  const { userToken, isLoading } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [contentList, setContentList] = useState("");

  //======== function for calling privacy policy content api===========
  async function getContentList(pageName = CONTENT_TYPE.PRIVACY_POLICY) {
    await dispatch(
      fetchContent({
        userToken: userToken,
        pageName: pageName,
      })
    ).then((responsejson) => {
      const response = responsejson.payload;
      setContentList(response.data.content_data);
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      }
    });
  }

  useEffect(() => {
    getContentList();
  }, [fetchContent]);

  return (
    <div className={styles.aboutWrapper}>
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.forgotText}>
            <p>{t("PRIVACY_POLICY")}</p>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      {contentList && (
        <div className={styles.contentWrapper}>
          <div className={styles.contentText}>
            <div
              className="textSection"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(contentList),
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrivacyPolicy;
