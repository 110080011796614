import "../../Assests/Styles/Common.css";
import { Form, Modal } from "react-bootstrap";
import styles from "../../AuthComponents/SignUp/BloodTypeModal.module.css";
import React, {useState } from "react";
import { useTranslation } from "react-i18next";
import { VehicleTypes } from "../../utils/OptionData";
import {setCurrentPage } from "../../store/slices/userSlice";
import { useDispatch} from "react-redux";

function VehicleFilterModal({
  vehicleModalOpen,
  setVehicleModalOpen,
  setSelectedFilterHome,
  vehicleSearch,
  filterList,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("");

  // ----------- function for manage checkbox----------------
  const handleCheckboxChange = (filter) => {
    setSelectedFilter(filter);
    setSelectedFilterHome(filter);
  };

  // --------- function for handle modal popup done button -------------
  const handleDoneButtonClick = () => {
    filterList(1 ,vehicleSearch ,selectedFilter);
    dispatch(setCurrentPage(1));
    setVehicleModalOpen(false);
  };

  const handleClearFilterButtonClick = () => {
    filterList(1 ,vehicleSearch ,"");
    dispatch(setCurrentPage(1));
    setSelectedFilter(""); // Clear the selected filter
    setSelectedFilterHome("");
    setVehicleModalOpen(false);
  };

  return (
    <div className={styles.topModal}>
      <Modal
        show={vehicleModalOpen}
        onHide={() => {
          setVehicleModalOpen(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("APPLY_FILTER_TITLE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="filterCheck">
            <div className="checkBoxRow">
              {VehicleTypes.map((item, index) => (
                <div
                  key={index}
                  className="checkboxSelect"
                  onClick={() => handleCheckboxChange(item.value)}
                >
                  <label for={`vehicle_type${item.label}`}>{t(item.label)}</label>
                  <input
                    id={`security_question3_${item.id}`}
                    type="checkbox"
                    checked={selectedFilter == item.value}
                    onChange={() => handleCheckboxChange(item.value)}
                  />
                </div>
              ))}
            </div>
          </Form>

          <div className={styles.applyFilterBtn}>
            <button
              onClick={() => {
                handleDoneButtonClick();
              }}
            >
              {t("APPLY_FILTER")}
            </button>
          </div>

          <div className={styles.clearFilterBtn}>
            <button onClick={handleClearFilterButtonClick}>
              {t("CLEAR_FILTER")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VehicleFilterModal;
