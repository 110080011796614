import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_EN } from "./utils/en/translation";
import { TRANSLATIONS_ES } from "./utils/es/translation";
const storedLanguage = localStorage.getItem("selectedLanguage");
i18n.use(initReactI18next).init({
  // fallbackLng: storedLanguage? storedLanguage: "en",
  fallbackLng:"es",
  lng: storedLanguage ? storedLanguage :"es",
  resources: {
    en: {
      translations: TRANSLATIONS_EN,
    },
    es: {
      translations: TRANSLATIONS_ES,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "es"];

export default i18n;