// FireBaseNotification.js
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { requestNotificationPermission } from "./firebase";
import { useNavigate } from "react-router-dom";
import { fetchNotificationList } from "./store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Toast from "react-bootstrap/Toast";
import AccidentImg from "../src/Assests/Images/accident1.png";
import LockImg from "../src/Assests/Images/Lock1.png";
import UnlockImg from "../src/Assests/Images/unlock1.png";

const FireBaseNotification = () => {
  const { userToken } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    action: "",
  });
  const [status, setStatus] = useState(false);
  const [showA, setShowA] = useState(false);

  const getHistoryImage = (item) => {
    let imgUrl;
    if (item === "lock") {
      imgUrl = LockImg;
    } else if (item === "unlock") {
      imgUrl = UnlockImg;
    }
    if (imgUrl) {
      return imgUrl;
    } else {
      return AccidentImg;
    }
  };

  function ToastDisplay() {
    return (
      <Toast
        show={showA}
        onClick={() => {
          navigate("/notification");
        }}
        onClose={() => setShowA(false)}
      >
        <Toast.Header>
          <img
            src={getHistoryImage(notification?.action)}
            className="rounded me-2"
            alt=""
            style={{ height: "42px", width: "42px" }}
          />
          <strong className="me-auto">{notification?.title}</strong>
        </Toast.Header>
        <Toast.Body>
          {notification?.body?.split(" ").slice(0, 16).join(" ")}...
        </Toast.Body>
      </Toast>
    );
  }

  useEffect(() => {
    if (status == true) {
      toggleShowA();
      //Call notification api
      dispatch(
        userToken &&
          fetchNotificationList({
            userToken: userToken,
            page_no: 1,
            // onLoadMore: true,
          })
      );
    }
  }, [notification]);

  useEffect(() => {
    requestNotificationPermission();

    navigator.serviceWorker.addEventListener("message", (event) => {
      const { data } = event;
      setStatus(true);

      setNotification({
        title: data.notification.title,
        body: data.notification.body,
        action: data.data.action,
      });
    });
  }, []);

  const toggleShowA = () => {
    setShowA(true); // Show the toast
    setTimeout(() => setShowA(false), 3000); // Automatically hide the toast after 3 seconds
  };

  return (
    <>
      <Toaster />

      {ToastDisplay()}
    </>
  );
};

export default FireBaseNotification;
