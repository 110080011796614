import { useEffect, useState } from "react";
import SecurityModal2 from "../../AuthComponents/SignUp/SecurityModal2";
import SecurityModal3 from "../../AuthComponents/SignUp/SecurityModal3";
import SecurityQuestionModal from "../../AuthComponents/SignUp/SecurityQuestionModal";
import { useForm } from "react-hook-form";
import { IoMdArrowRoundBack } from "react-icons/io";
import styles from "../Profile/Profile.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { IoMdArrowDropdown } from "react-icons/io";
import Loader from "../../utils/Loader/Loader";
import { Form } from "react-bootstrap";
import {
  appMyProfile,
  fetchMasterData,
  setActiveTab,
  setSecurityQuestionExist,
  userLogoutClear,
} from "../../store/slices/userSlice";
import SublyApi from "../../helpers/Api";
import Toster from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";

function EditSecurityQuestion() {
  const { userToken, profileData, currentUser } = useSelector(
    (state) => state.user
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [securityQuestionModalOpen, setSecurityQuestionModalOpen] =
    useState(false);
  const [securityQuestionModalOpen2, setSecurityQuestionModalOpen2] =
    useState(false);
  const [securityQuestionModalOpen3, setSecurityQuestionModalOpen3] =
    useState(false);
  const [securityQuestion1, setSecurityQuestion1] = useState("");
  const [securityQuestion2, setSecurityQuestion2] = useState("");
  const [securityQuestion3, setSecurityQuestion3] = useState("");
  const [loader, setLoader] = useState(false);

  //======== function for apply validation using useForm==========
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  //===== disable space=======
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // =======function for calling masterData api==========
  useEffect(() => {
    const refKey = "security_questions";
    const getMasterDataList = async () => {
      dispatch(fetchMasterData({ refKey: refKey })).then((data) => {
        var question_list = data?.payload?.data?.security_questions;
        if (
          profileData &&
          Object.keys(profileData).length > 0 &&
          profileData.security_que_ans.length > 0
        ) {
          const question_exist1 = question_list.some(
            (item) => item.id === profileData?.security_que_ans[0]?.question_id
          );
          const question_exist2 = question_list.some(
            (item) => item.id === profileData?.security_que_ans[1]?.question_id
          );
          const question_exist3 = question_list.some(
            (item) => item.id === profileData?.security_que_ans[2]?.question_id
          );

          if (question_exist1) {
            setSecurityQuestion1({
              id: profileData?.security_que_ans[0].question_id,
              value_name: profileData?.security_que_ans[0].quesion,
            });
            setValue(
              "securityQuestionFirst",
              profileData?.security_que_ans[0].quesion
            );
            setValue(
              "securityAnswer1",
              profileData?.security_que_ans[0].answer
            );
          }

          if (question_exist2) {
            setSecurityQuestion2({
              id: profileData?.security_que_ans[1].question_id,
              value_name: profileData?.security_que_ans[1].quesion,
            });
            setValue(
              "securityQuestionsecond",
              profileData?.security_que_ans[1].quesion
            );
            setValue(
              "securityAnswer2",
              profileData?.security_que_ans[1].answer
            );
          }
          if (question_exist3) {
            setSecurityQuestion3({
              id: profileData?.security_que_ans[2].question_id,
              value_name: profileData?.security_que_ans[2].quesion,
            });
            setValue(
              "securityQuestionThird",
              profileData?.security_que_ans[2].quesion
            );
            setValue(
              "securityAnswer3",
              profileData?.security_que_ans[2].answer
            );
          }
        } else {
          setSecurityQuestion1("");
          setValue("securityQuestionFirst", "");
          setValue("securityAnswer1", "");
          setSecurityQuestion2("");
          setValue("securityQuestionsecond", "");
          setValue("securityAnswer2", "");
          setSecurityQuestion3("");
          setValue("securityQuestionThird", "");
          setValue("securityAnswer3", "");
        }
      });
    };
    getMasterDataList();
  }, [profileData]);

  // ======function for calling edit security question api==========
  const onSubmit = async (formdata) => {
    setLoader(true);
    const secuAnsData = [];
    if (securityQuestion1 && securityQuestion1.id) {
      secuAnsData.push({
        sq: securityQuestion1.id,
        ans: formdata.securityAnswer1,
      });
    }

    if (securityQuestion2 && securityQuestion2.id) {
      secuAnsData.push({
        sq: securityQuestion2.id,
        ans: formdata.securityAnswer2,
      });
    }

    if (securityQuestion3 && securityQuestion3.id) {
      secuAnsData.push({
        sq: securityQuestion3.id,
        ans: formdata.securityAnswer3,
      });
    }
    let requestData = new FormData();
    requestData.append("security_que_json", JSON.stringify(secuAnsData));
    await SublyApi.editSecurityQuestions(userToken, requestData).then(
      (responsejson) => {
        const ResponseCode = STATUS_MSG[responsejson.data.code];
        if (responsejson.code === STATUS_CODES.SUCCESS) {
          setLoader(false);
          Toster(t("SECURITY_QUESTION_UPDATE"), "success");
          dispatch(appMyProfile(userToken));
          dispatch(setSecurityQuestionExist(currentUser));
          navigate("/personal-information");
          dispatch(setActiveTab("Security Question"));
        } else if (responsejson.code === STATUS_CODES.VALIDATION_ERROR) {
          setLoader(false);
          Toster(t("VALIDATION_ERROR"), "success");
        } else if (responsejson.code === STATUS_CODES.INVALID_TOKEN) {
          dispatch(userLogoutClear());
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };

  return (
    <div className={styles.editProfileWrapper}>
      {loader ? <Loader /> : ""}
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack
              onClick={() => navigate("/personal-information")}
            />
          </div>
          <div className={styles.profileTitle}>
            <p>{t("SECURITY_QUESTION_TITLE")}</p>
          </div>
        </div>
      </div>
      <div className={styles.securityForm}>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_QUESTION_LABEL1")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("SECURITY_PLACEHOLDER_TEXT")}`}
                type="text"
                readOnly
                onClick={() => setSecurityQuestionModalOpen(true)}
                onChange={(e) => {
                  setSecurityQuestion1(""); // Clear the selected security question
                  clearErrors("securityQuestionFirst"); // Clear the error for the field
                }}
                {...register("securityQuestionFirst", {
                  required: {
                    value: true,
                    message: `${t("SECURITY_QUESTION_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setSecurityQuestionModalOpen(true);
                }}
              />

              {errors.securityQuestionFirst && (
                <span className="errorMsg">
                  {errors.securityQuestionFirst.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_ANSWER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("ANSWER_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("securityAnswer1", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("ANSWER_REQUIRED_MESSAGE")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.securityAnswer1 && (
                <span className="errorMsg">
                  {errors.securityAnswer1.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_QUESTION_LABEL2")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("SECURITY_PLACEHOLDER_TEXT")}`}
                type="text"
                onChange={(e) => {
                  setSecurityQuestion2(""); // Clear the selected security question
                  clearErrors("securityQuestionsecond"); // Clear the error for the field
                }}
                readOnly
                onClick={() => setSecurityQuestionModalOpen2(true)}
                {...register("securityQuestionsecond", {
                  required: {
                    value: true,
                    message: `${t("SECURITY_QUESTION_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setSecurityQuestionModalOpen2(true);
                }}
              />
              {errors.securityQuestionsecond && (
                <span className="errorMsg">
                  {errors.securityQuestionsecond.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_ANSWER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("ANSWER_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("securityAnswer2", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("ANSWER_REQUIRED_MESSAGE")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.securityAnswer2 && (
                <span className="errorMsg">
                  {errors.securityAnswer3?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_QUESTION_LABEL3")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("SECURITY_PLACEHOLDER_TEXT")}`}
                type="text"
                onChange={(e) => {
                  setSecurityQuestion3(""); // Clear the selected security question
                  clearErrors("securityQuestionThird"); // Clear the error for the field
                }}
                readOnly
                onClick={() => setSecurityQuestionModalOpen3(true)}
                {...register("securityQuestionThird", {
                  required: {
                    value: true,
                    message: `${t("SECURITY_QUESTION_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setSecurityQuestionModalOpen3(true);
                }}
              />

              {errors.securityQuestionThird && (
                <span className="errorMsg">
                  {errors.securityQuestionThird.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_ANSWER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("ANSWER_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("securityAnswer3", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("ANSWER_REQUIRED_MESSAGE")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.securityAnswer3 && (
                <span className="errorMsg">
                  {errors.securityAnswer3.message}
                </span>
              )}
            </Form.Group>

            <div className={styles.nextBtn}>
              <button>{t("SUBMIT_BUTTON")}</button>
            </div>
          </Form>
          <SecurityQuestionModal
            securityQuestionModalOpen={securityQuestionModalOpen}
            setSecurityQuestionModalOpen={setSecurityQuestionModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            securityQuestion1={securityQuestion1}
            setSecurityQuestion1={setSecurityQuestion1}
            securityQuestion2={securityQuestion2}
            securityQuestion3={securityQuestion3}
          />

          <SecurityModal2
            securityQuestionModalOpen2={securityQuestionModalOpen2}
            setSecurityQuestionModalOpen2={setSecurityQuestionModalOpen2}
            setValue={setValue}
            clearErrors={clearErrors}
            securityQuestion2={securityQuestion2}
            setSecurityQuestion2={setSecurityQuestion2}
            securityQuestion1={securityQuestion1}
            securityQuestion3={securityQuestion3}
          />
          <SecurityModal3
            securityQuestionModalOpen3={securityQuestionModalOpen3}
            setSecurityQuestionModalOpen3={setSecurityQuestionModalOpen3}
            setValue={setValue}
            clearErrors={clearErrors}
            setSecurityQuestion3={setSecurityQuestion3}
            securityQuestion1={securityQuestion1}
            securityQuestion2={securityQuestion2}
            securityQuestion3={securityQuestion3}
          />
        </div>
      </div>
    </div>
  );
}

export default EditSecurityQuestion;
