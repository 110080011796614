import { Icon } from "@iconify/react";
import { useContext, useEffect } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./LanguageDropdown.module.css";
import { LanguageContext } from "../../Context/LanguageContext";
import moment from "moment";
import "moment/locale/es";

const LanguageDropdown = () => {
  const languages = [
    { id: "en", code: "en", label: "En", icon: "circle-flags:us" },
    { id: "es", code: "es", label: "Es", icon: "circle-flags:es-variant" },
    // Add more languages as needed
  ];

  const { selectedLanguage, changeLanguage } = useContext(LanguageContext);

  const handleSelect = (eventKey) => {
    changeLanguage(eventKey);
    moment.locale(eventKey);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (
      storedLanguage &&
      languages.some((lang) => lang.code === storedLanguage)
    ) {
      changeLanguage(storedLanguage);
      moment.locale(storedLanguage);
      
    }
  }, []);

  return (
    <div className="languageModule">
      <DropdownButton
        as={ButtonGroup}
        title={
          <>
            <Icon
              icon={
                languages.find((lang) => lang.code === selectedLanguage)?.icon
              }
              color=""
              width="20"
              height="20"
              marginTop="5px"
            />{" "}
            {languages.find((lang) => lang.code === selectedLanguage)?.label}
          </>
        }
        onSelect={(eventKey) => handleSelect(eventKey)}
      >
        <Dropdown.Header>Select Language</Dropdown.Header>
        {languages.map((lang) => {
          return (
            <Dropdown.Item
              key={lang.id}
              eventKey={lang.code}
              className={`${styles.languageOptions} ${
                lang.code === selectedLanguage ? styles.selectedLanguage : ""
              }`}
              style={{
                backgroundColor:
                  lang.code === selectedLanguage ? "#E3E5E7" : "",
              }}
            >
              <span className={styles.langIcons}>
                <Icon icon={lang.icon} color="" width="22" height="22" />
              </span>
              {lang.label}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </div>
  );
};

export default LanguageDropdown;
