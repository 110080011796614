import styles from "../Setting/Setting.module.css";
import { FaBell } from "react-icons/fa";
import { FaAnglesRight } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  appMyProfile,
  logOutApp,
  userLogoutClear,
} from "../../store/slices/userSlice";
import HomeFooterOption from "../Home/HomeFooterOption";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";
import Switch from "react-switch";
import ChangePinModal from "./ChangePinModal";
import SublyApi from "../../helpers/Api";
import ChangeLanModal from "./ChangeLanModal";

function Setting() {
  const { userToken, profileData, unreadNotification, loginType } = useSelector(
    (state) => state.user
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState();
  const [changePinModalOpen, setChangePinModalOpen] = useState();
  const [checked, setChecked] = useState(
    profileData.notification_enable == 1 ? true : false
  );
  const [changeLan, setChangeLan] = useState("");

  //======= function for show toggle according to response=========
  useEffect(() => {
    setChecked(profileData.notification_enable == 1 ? true : false);
  }, [profileData]);

  //-------- function  for change notification status on/off----------
  const handleChange = async (nextChecked) => {
    setChecked(nextChecked);
    const updatedNotificationStatus = nextChecked ? 1 : 0;
    await SublyApi.updateNotification(userToken, {
      notification_enable: updatedNotificationStatus,
    }).then((responsejson) => {
      const ResponseCode =
        STATUS_MSG[responsejson && responsejson.data && responsejson.data.code];
      if (
        responsejson &&
        responsejson.code === STATUS_CODES.NOTIFICATION_ENABLED
      ) {
        Toster(t("NOTIFICATION_ENABLED"), "success");
      } else if (
        responsejson &&
        responsejson.code === STATUS_CODES.NOTIFICATION_DISABLED
      ) {
        Toster(t("NOTIFICATION_DISABLED"), "success");
      } else if (
        responsejson &&
        responsejson.data &&
        responsejson.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  //========= function for calling logOut api=========
  async function logOut() {
    dispatch(logOutApp(userToken)).then((response) => {
      const responsejson = response.payload;
      const ResponseCode = STATUS_MSG[responsejson && responsejson.data.code];
      if (
        responsejson &&
        responsejson.data &&
        responsejson.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else if (responsejson.code === STATUS_CODES.SUCCESS) {
        navigate("/login");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  }

  //--------- function for calling my profile api--------
  useEffect(() => {
    const getProfileData = async () => {
      dispatch(appMyProfile(userToken)).then((response) => {
        const responsejson = response.payload;
        const ResponseCode = STATUS_MSG[responsejson && responsejson.data.code];
        if (
          responsejson &&
          responsejson.data &&
          responsejson.code === STATUS_CODES.SUCCESS
        ) {
        } else if (
          responsejson &&
          responsejson.data &&
          responsejson.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
        } else {
          Toster(t(ResponseCode), "error");
        }
      });
    };
    getProfileData();
  }, []);

  // ==========function for remove extra + in dial code=========
  function removeExtraPlus(inputString) {
    let newStr = "+" + inputString;
    return newStr.replace(/\++/g, "+");
  }

  const handleClose = () => {
    setChangePinModalOpen(false);
  };

  return (
    <div className={styles.settingWrapper}>
      <div className={styles.backGround}>
        <div className={styles.topItem}>
          <h5>{t("SETTING_TITLE")}</h5>
        </div>
        <div className={styles.bellIcon}>
          {unreadNotification > 0 ? (
            <div className={styles.notificationTick}></div>
          ) : (
            ""
          )}

          <FaBell onClick={() => navigate("/notification")} />
        </div>
      </div>

      <div className="fixContent">
        <div className={styles.userDetail}>
          <h5>{profileData.name}</h5>
          <p>{profileData.email}</p>

          <p>
            {" "}
            {profileData.dial_code &&
              removeExtraPlus(profileData.dial_code)}{" "}
            {profileData.phone_number &&
              profileData.phone_number.substring(0, 3)}{" "}
            {profileData.phone_number &&
              profileData.phone_number.substring(3, 6)}{" "}
            {profileData.phone_number && profileData.phone_number.substring(6)}
          </p>

          <div className={styles.viewDetail}>
            <p onClick={() => navigate("/personal-information")}>
              {t("VIEW_DETAILS")} <FaAnglesRight />
            </p>
          </div>
        </div>

        <div className={styles.optionList}>
          <div className={styles.optionItem}>
            <h5>{t("NOTIFICATION_TEXT")}</h5>
            <Switch
              onChange={handleChange}
              checked={checked}
              className="react-switch custom-switch"
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#FF2A00"
              offColor="#d3d3d3"
            />
          </div>
          {loginType === "normal" ? (
            <div
              className={styles.optionItem}
              onClick={() => navigate("/change-password")}
            >
              <h5>{t("CHANGE_PASSWORD")}</h5>
              <FaAngleRight />
            </div>
          ) : (
            ""
          )}

          <div
            className={styles.optionItem}
            onClick={() => setChangePinModalOpen(true)}
          >
            <h5>{t("CHANGE_PIN")}</h5>
            <FaAngleRight />
          </div>
          <div
            className={styles.optionItem}
            onClick={() => navigate("/about-us")}
          >
            <h5>{t("ABOUT_US")}</h5>
            <FaAngleRight />
          </div>

          <div
            className={styles.optionItem}
            onClick={() => navigate("/privacy-policy")}
          >
            <h5>{t("PRIVACY_POLICY")}</h5>
            <FaAngleRight />
          </div>
          <div
            className={styles.optionItem}
            onClick={() => navigate("/terms-condition")}
          >
            <h5>{t("TERMS_CONDITIONS")}</h5>
            <FaAngleRight />
          </div>
          <div
            className={styles.optionItem}
            onClick={() => setOpenDelete(true)}
          >
            <h5>{t("DELETE_ACCOUNT")}</h5>
            <FaAngleRight />
          </div>

          <div className={styles.optionItem} onClick={() => setChangeLan(true)}>
            <h5>{t("CHANGE_LANGUAGE")}</h5>
            <FaAngleRight />
          </div>
        </div>
        <div className={styles.logOutBtn}>
          <button onClick={logOut}>{t("LOGOUT")}</button>
        </div>
      </div>

      <HomeFooterOption />
      <ChangePinModal
        changePinModalOpen={changePinModalOpen}
        setChangePinModalOpen={setChangePinModalOpen}
      />
      <DeleteAccount openDelete={openDelete} setOpenDelete={setOpenDelete} />
      <ChangeLanModal
        changeLan={changeLan}
        setChangeLan={setChangeLan}
        handleClose={handleClose}
      />
    </div>
  );
}

export default Setting;
