import "../../Assests/Styles/Common.css";
import { Form, Modal } from "react-bootstrap";
import styles from "../SignUp/BloodTypeModal.module.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { useSelector } from "react-redux";

function HealthIssueModal({
  healthIssueModalOpen,
  setHealthIssueModalOpen,
  setValue,
  clearErrors,
  selectedHealthIssue,
  setSelectedHealthIssue,
  selectedHealthIssueValue,
  setSelectedHealthIssueValue,
  otherInputValue,
  setOtherInputValue,
  profileData,
}) {
  const { t } = useTranslation();
  const { masterList } = useSelector((state) => state.user);

  const [error, setError] = useState("");
  const [isOtherChecked, setIsOtherChecked] = useState(false); // State to track if "other" is checked
  const [showOtherInput, setShowOtherInput] = useState(false);
  const {
    register,
    formState: { errors },
  } = useForm();

  //======== function for find other value code=========
  useEffect(() => {
    if (masterList && masterList.health_issues) {
      const otherHealthIssue = masterList.health_issues.find(
        (issue) => issue.value_code === "other"
      );
      if (otherHealthIssue) {
        setShowOtherInput(selectedHealthIssue?.includes(otherHealthIssue.id));
      }
    }
  }, [masterList, selectedHealthIssue]);

  // ----------- function for manage checkbox----------------
  const handleCheckboxChange = (healthIssue) => {
    let updatedSelection = [...selectedHealthIssue];
    let updatedSelectionValue = [...selectedHealthIssueValue];

    // Check if the health issue is "None"
    const isNoneOption = healthIssue.value_code === "none";
    const noneOptionIndex = updatedSelection.indexOf("QeZJep");

    if (isNoneOption) {
      // If "None" is selected, deselect all other options

      updatedSelection = [healthIssue.id];
      updatedSelectionValue = [healthIssue.value_name];
    } else {
      // If any other option is selected
      if (updatedSelection.includes(healthIssue.id)) {
        // If already selected, remove it
        updatedSelection = updatedSelection.filter(
          (id) => id !== healthIssue.id
        );
        updatedSelectionValue = updatedSelectionValue.filter(
          (name) => name !== healthIssue.value_name
        );
      } else {
        // If not selected, add it
        updatedSelection.push(healthIssue.id);
        updatedSelectionValue.push(healthIssue.value_name);
      }

      // If "None" was previously selected, deselect it
      if (noneOptionIndex !== -1) {
        updatedSelection.splice(noneOptionIndex, 1);
        updatedSelectionValue.splice(noneOptionIndex, 1);
      }
    }

    // Update the state with new selections
    setSelectedHealthIssue(updatedSelection);
    setSelectedHealthIssueValue(updatedSelectionValue);

    // Toggle "other" checkbox if "None" is selected
    if (isNoneOption) {
      setIsOtherChecked(false);
    }

    if (healthIssue.value_code === "other") {
      setIsOtherChecked(!isOtherChecked);
    }

    clearErrors("healthIssue");
    setError("");
  };

  //======= function for click on done button=========
  const handleDoneButtonClick = () => {
    if (isOtherChecked && !otherInputValue && otherInputValue.trim() === "") {
      setError(t("FILL_OTHER_HEALTH_ISSUE"));
      return;
    }

    setHealthIssueModalOpen(false);
  };

  // ========function for manage other input==========
  const handleOtherInputChange = (e) => {
    setValue(e.target.name, e.target.value && e.target.value.trim());
    setOtherInputValue(e.target.value);
    setError("");
  };

  useEffect(() => {
    if (!showOtherInput) {
      // setOtherInputValue("");
      // setValue("otherIssue", "");
      setValue("otherIssue", "");
    } else {
      // Set the other input value to profileData when it is shown
      setOtherInputValue(profileData);
      setValue("otherIssue", profileData);
    }
  }, [showOtherInput, profileData]);

  const isItemSelected = (issue) => {
    return selectedHealthIssueValue?.includes(issue);
  };

  return (
    <div className={styles.topModal}>
      <Modal
        show={healthIssueModalOpen}
        onHide={() => {
          if (!isOtherChecked) {
            setHealthIssueModalOpen(false);
          }
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton={!isOtherChecked}>
          <Modal.Title className={styles.modalTitle}>
            {t("SELECT_HEALTH_ISSUE_TITLE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="bloodTypeChek">
            <div className={styles.ScrollbarVertical}>
              <div className="checkBoxRow">
                {masterList &&
                  masterList.health_issues &&
                  masterList.health_issues.length > 0 &&
                  masterList.health_issues.map((healthIssue, index) => (
                    <div
                      key={index}
                      className="checkboxSelect"
                      // onClick={() => handleCheckboxChange(healthIssue)}
                    >
                      <label>{healthIssue.value_name}</label>
                      <input
                        type="checkbox"
                        checked={isItemSelected(healthIssue?.value_name)}
                        onChange={() => handleCheckboxChange(healthIssue)}
                      />
                    </div>
                  ))}
              </div>
            </div>
            {showOtherInput && (
              <div className={styles.otherInput}>
                <Form.Group className={styles.healthFormGroup}>
                  <Form.Label className={styles.healthFormLabel}>
                    {t("OTHER_LABEL")}
                  </Form.Label>
                  <Form.Control
                    className={styles.FormInput}
                    placeholder={t("ENTER_HEALTH_ISSUE")}
                    type="text"
                    autoComplete="off"
                    defaultValue={profileData}
                    {...register("otherIssue", {
                      onChange: (e) => handleOtherInputChange(e),
                      required: {
                        value: isOtherChecked,
                        message: `${t("OTHER_INPUT_REQUIRED")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.otherIssue && (
                    <span className="errorMsg">
                      {errors.otherIssue.message}
                    </span>
                  )}
                  {error && <div className="errorMsg">{error}</div>}
                </Form.Group>
              </div>
            )}
          </Form>
          <div className={styles.doneBtn}>
            <button onClick={handleDoneButtonClick}>{t("DONE_BUTTON")}</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HealthIssueModal;