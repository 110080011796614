import styles from "../ForgotPassword/ForgotPassword.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Container, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { MdKey } from "react-icons/md";
import OtpInput from "react-otp-input";
import { useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";
import Loader from "../../utils/Loader/Loader";

function ResetPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const userEmail = location.state;
  const [loader, setLoader] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  // ==========  function for calling api verifyForgot password ==========
  const onSubmit = async (formdata) => {
    setLoader(true);
    let requestData = new FormData();
    requestData.append("email", userEmail.email);
    requestData.append("password", formdata.password);
    requestData.append("cnf_password", formdata.confirmPassword);
    requestData.append("code", formdata.otp);
    await SublyApi.updatePassword(requestData).then((responsejson) => {
      const ResponseCode =
        STATUS_MSG[responsejson && responsejson.data && responsejson.data.code];
      if (responsejson && responsejson.code === STATUS_CODES.SUCCESS) {
        Toster(t("UPDATE_PASSWORD_SUCCUSS"), "success");
        navigate("/login");
        setValue("password", "");
        setValue("confirmPassword", "");
        setValue("otp", "");
        setLoader(false);
      } else if (
        responsejson &&
        responsejson.data.code === STATUS_CODES.VALIDATION_ERROR
      ) {
        Toster(responsejson && responsejson.data.message, "error");
        setLoader(false);
      } else {
        Toster(t(ResponseCode), "error");
        setLoader(false);
      }
    });
  };

  // ------- disable space on key press input password field-------------
  const passwordKeyPress = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  return (
    <div className={styles.wrapper}>
      {loader ? <Loader /> : ""}
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.forgotText}>
            <p>{t("RESET_PASSWORD")}</p>
          </div>
        </div>
      </div>

      <div className={styles.keyIcon}>
        <MdKey />
      </div>

      <div className={styles.tittleText}>
        <h3>{t("NEW_PASSWORD")}</h3>
      </div>

      <Container>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Label className={styles.otpInputLabel}>
              {t("OTP_INPUT_LABEL")}
            </Form.Label>
            <div className="otpInput">
              <Controller
                name="otp"
                type="password"
                control={control}
                defaultValue=""
                rules={{
                  required: `${t("OTP_REQUIRED")}`,
                  minLength: {
                    value: 6,
                    message: `${t("DIGIT_REQUIRED")}`,
                  },
                }}
                render={({ field }) => (
                  <OtpInput
                    inputStyle="inputStyle"
                    {...field}
                    numInputs={6}
                    isInputNum
                    shouldAutoFocus={field.value.length < 6}
                    renderInput={(props, index) => (
                      <input {...props} inputMode="numeric" />
                    )}
                    onChange={(value) => {
                      let numbers = /^[0-9]+$/;
                      if (value.match(numbers) || value === "") {
                        field.onChange(value);
                      } else {
                        return false;
                      }
                    }}
                  />
                )}
              />
            </div>

            {errors.otp && (
              <span className="changeModalErrorMsg">{errors.otp.message}</span>
            )}

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("PASSWORD_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("PASSWORD_PLACEHOLDER_TEXT")}`}
                type={passwordShow ? "text" : "password"}
                name="password"
                autoComplete="new-password"
                {...register("password", {
                  onChange: (e) => {
                    passwordKeyPress(e);
                  },
                  ...sanitizeHtmlTags(),
                  required: {
                    value: true,
                    message: `${t("PASSWORD_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 6,
                    message: `${t("PASS_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 12,
                    message: `${t("PASS_MAXLENGTH")}`,
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!]).{6,12}$/,
                    message: `${t("PASSWORD_MUST_CONTAIN")}`,
                  },
                })}
              />

              {errors.password && (
                <span className="errorMsg">{errors.password.message}</span>
              )}
              <div className="passwordicon">
                {passwordShow ? (
                  <FaEye
                    className="icon"
                    onClick={() => setPasswordShow(!passwordShow)}
                  />
                ) : (
                  <FaEyeSlash
                    className="icon"
                    onClick={() => setPasswordShow(!passwordShow)}
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("CONFIRM_PASSWORD_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("CONFIRM_PASSWORD_PLACEHOLDER_TEXT")}`}
                type={confirmPasswordShow ? "text" : "password"}
                autoComplete="new-password"
                name="confirmPassword"
                {...register("confirmPassword", {
                  onChange: (e) => {
                    passwordKeyPress(e);
                  },
                  ...sanitizeHtmlTags(),
                  required: {
                    value: true,
                    message: `${t("CONFIRM_PASSWORD_REQUIRED_MESSAGE")}`,
                  },
                  validate: (value) =>
                    value === password.current ||
                    `${t("CONFIRM_PASSWORD_NOT_MATCH")}`,
                })}
              />

              {errors.confirmPassword && (
                <span className="errorMsg">
                  {errors.confirmPassword.message}
                </span>
              )}
              <div className="passwordicon">
                {confirmPasswordShow ? (
                  <FaEye
                    className="icon"
                    onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  />
                ) : (
                  <FaEyeSlash
                    className="icon"
                    onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  />
                )}
              </div>
            </Form.Group>

            <div className={styles.forgotButton}>
              <button>{t("SUBMIT_BUTTON")}</button>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default ResetPassword;
