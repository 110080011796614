import Modal from "react-bootstrap/Modal";
import styles from "./Sos.module.css";
import "../../Assests/Styles/Common.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchVehicleList,
  getSos,
  setCurrentPage,
  userLogoutClear,
} from "../../store/slices/userSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import LockUnlockModal from "../Home/LockUnlockModal";
import Loader from "../../utils/Loader/Loader";
import Toster from "../../utils/Toster";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { useLocation, useNavigate } from "react-router-dom";

//---------function for vehicle type popup modal---------
function SosModal({ sosModalOpen, setSosModalOpen, selectedSosType }) {
  const { userToken, vehicleListData, isLoading, loadMore, currentPage } =
    useSelector((state) => state.user);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openLock, setOpenLock] = useState();
  const listInnerRef = useRef(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);

  // ----------- function for manage vehicle select----------------
  const handleVehicleChange = (vehicle) => {
    setSelectedVehicleId(vehicle.id);
  };

  // ===========function for calling get vehicle list==========
  async function getVehicleList(
    pageNum = 1,
    vehicleSearch = "",
    selectedFilterHome = "",
    onLoadMore = false
  ) {
    await dispatch(
      fetchVehicleList({
        userToken: userToken,
        page_no: pageNum,
        search: vehicleSearch,
        filterByVehicle: selectedFilterHome,
        onLoadMore: onLoadMore,
      })
    ).then((responsejson) => {
      const { response } = responsejson.payload;
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else if (onLoadMore == true && loadMore) {
        dispatch(setCurrentPage(currentPage + 1));
      }
    });
  }

  useEffect(() => {
    dispatch(setCurrentPage(1));
    getVehicleList();
  }, []);

  const loadMoreVehicles = () => {
    if (loadMore) {
      dispatch(
        fetchVehicleList({
          userToken: userToken,
          page_no: currentPage + 1,
          search: "",
          filterByVehicle: "",
          onLoadMore: true,
        })
      );
    }
  };

  //======privious code without loader=======
  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        loadMoreVehicles();
      }
    }
  };

  const [locationData, setLocationData] = useState({
    latitude: "",
    longitude: "",
    location: "",
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLocationData({
            latitude,
            longitude,
            location: ``,
          });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  // ===========Calling APi for get sos service ===========
  const handleSOS = async (sosType) => {
    const { latitude, longitude, location } = locationData;
    const requestData = new FormData();
    requestData.append("vehicle_id", selectedVehicleId);
    requestData.append("sos_type", sosType);
    requestData.append("location", location);
    requestData.append("latitude", latitude ? latitude : "");
    requestData.append("longitude", longitude ? longitude : "");

    await dispatch(
      getSos({
        userToken: userToken,
        requestData: requestData,
      })
    ).then((responsejson) => {
      const response = responsejson.payload;
      const ResponseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        navigate("/vehicle-history");
      } else if (response.data.code === STATUS_CODES.USER_NOT_FOUND) {
        Toster(t("USER_NOT_FOUND"), "error");
      } else if (
        response.data.code === STATUS_CODES.LICENCE_PLATE_NUMBER_EXIST
      ) {
        // Toster("Vehicle license plate number already registered", "error");
      } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      }
    });
  };

  return (
    <div className="modal show">
      <Modal
        show={sosModalOpen}
        onHide={() => {
          setSosModalOpen(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("SELECT_VEHICLE")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            onScroll={handleScroll}
            ref={listInnerRef}
            className="fixContentModal"
          >
            {vehicleListData && vehicleListData.length > 0 ? (
              vehicleListData.map((item, index) => {
                return (
                  <div className="vehicleInput" key={index}>
                    <input
                      type="radio"
                      id={item.id}
                      name="select"
                      onChange={() => handleVehicleChange(item)}
                    />
                    <label
                      htmlFor={item.id}
                      className={styles.vehicleInputDisplay}
                    >
                      <div className={styles.vehicleInputText}>
                        <h2>{item.license_plate_number}</h2>
                        <p>{item.vehicle_model}</p>
                      </div>
                      <img src={item.vehicle_image} alt="vehicle-img" />
                    </label>
                  </div>
                );
              })
            ) : isLoading ? (
              <Loader />
            ) : (
              <div className={styles.noResultsMessage}>{t('NO_VEHICLES_FOUND')}.</div>
            )}
            {isLoading && <Loader />}
          </div>
          <div className={styles.doneBtn}>
            <button
              onClick={() => {
                if (selectedVehicleId) {
                  // Add this condition to check if a vehicle is selected
                  handleSOS(selectedSosType);
                } else {
                  Toster(t("SELECT_VEHICLE_ERROR"), "error"); // Show error message if no vehicle is selected
                }
              }}
            >
              {t("DONE_BUTTON")}
            </button>
          </div>

          <div className={styles.cancel}>
            <button
              onClick={() => {
                setSosModalOpen(false);
              }}
            >
              {t("CANCEL")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <LockUnlockModal
        openLock={openLock}
        setOpenLock={setOpenLock}
        selectedVehicleId={selectedVehicleId}
      />
    </div>
  );
}

export default SosModal;
