import React from "react";
import Modal from "react-modal";
import style from "./ImageModal.module.css"
import { IoMdCloseCircleOutline } from "react-icons/io";

const ImageModal = ({ isOpen, onRequestClose, imageSrc }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Modal"
      className={style.modal}
      overlayClassName={style.overlay}
    >
        <button onClick={onRequestClose} className={style.closeButton}>
        <IoMdCloseCircleOutline size={24} /> 
      </button>
      <img src={imageSrc} alt="Large view" className={style.image} />
    </Modal>
  );
};

export default ImageModal;
