// import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./Routes/Routers";
import { LanguageProvider } from "./Context/LanguageContext";

function App() {
  return (
    <>
     <LanguageProvider>
     <Routers />
      <ToastContainer />
            </LanguageProvider>
     
    </>
  );
}

export default App;