import "../../Assests/Styles/Common.css";
import { Form, Modal } from "react-bootstrap";
import styles from "../SignUp/BloodTypeModal.module.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function SecurityQuestionModal({
  securityQuestionModalOpen,
  setSecurityQuestionModalOpen,
  setValue,
  clearErrors,
  securityQuestion1,
  setSecurityQuestion1,
  securityQuestion2,
  securityQuestion3,
}) {
  const { t } = useTranslation();
  const { masterList } = useSelector((state) => state.user);
  const [error, setError] = useState("");

  // =====function for handle checkbox======
  const handleCheckboxChange = (sObj) => {
    if (sObj.id === securityQuestion2.id || sObj.id === securityQuestion3.id) {
      setError(t("SECURITY_QUESTION_ONE_ERROR_MSG"));
    } else {
      setSecurityQuestion1({ id: sObj.id, value_name: sObj.value_name });
      setValue("securityQuestionFirst", sObj.value_name);
      clearErrors("securityQuestionFirst");
      setError("");
      setSecurityQuestionModalOpen(false);
    }
  };

  return (
    <div className={styles.topModal}>
      <Modal
        show={securityQuestionModalOpen}
        onHide={() => {
          setSecurityQuestionModalOpen(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("SECURITY_QUESTION_TITLE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="bloodTypeChek">
            <div className={styles.ScrollbarVertical}>
              <div className="checkBoxRow">
                {masterList &&
                  masterList.security_questions &&
                  masterList.security_questions.length > 0 &&
                  masterList.security_questions.map((item, index) => (
                    <div
                      key={index}
                      className="checkboxSelect"
                      onClick={() => handleCheckboxChange(item)}
                    >
                      <label for={`security_question1_${item.value_name}`}>
                        {item.value_name}
                      </label>
                      <input
                        id={`security_question1_${item.id}`}
                        type="radio"
                        checked={
                          securityQuestion1 && securityQuestion1.id == item.id
                        }
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </Form>
          {error && <div className="errorMsg">{error}</div>}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SecurityQuestionModal;
