import styles from "../Home/Home.module.css";
import backgroundImage from "../../Assests/Images/homeBg.png";
import logoIMg from "../../Assests/Images/homeLogo.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearSignupData, resetSwitchTo } from "../../store/slices/userSlice";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // =======function for clear redux state and redirect on login page  after 3 seconds=========
  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      dispatch(clearSignupData(true));
      dispatch(resetSwitchTo());
      navigate("/login");
    }, 3000);

    return () => clearTimeout(redirectTimeout);
  }, [dispatch, navigate]);

  return (
    <div className={styles.homeSection}>
      <div
        className={styles.backgroundImg}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          backgroundPositionX: "center",
          backgroundColor: "transparent",
          transition: "background 0.3s ease",
          position: "relative",
        }}
      >
        <div className={styles.logoSection}>
          <img
            src={logoIMg}
            className={styles.logoIMg}
            onClick={() => navigate("/login")}
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Home;