import axios from "axios";

// const BASE_URL = "";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// const REACT_PROXYURL = "https://quiet-retreat-79741.herokuapp.com/";
const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_ID, uint32.toString(32));
}

if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_TOKEN, uint32.toString(32));
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class SublyApi {
  //token which interact with the API will be stored here.
  static token;
  //required common header for each api calling.
  static commonHeaders = {
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
      ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
      : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    // Replace multiple consecutive forward slashes with a single forward slash
    // return url.replace(/\/\/+/g, '/');
    return url.replace(/([^:]\/)\/+/g, "$1");
  }
  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    const headers = { ...SublyApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /******************************************************
   * USERS AUTH API ROUTES
   *******************************************************/

  /* POST  /login:  {device_id,device_type,password,email}
 login api
 Authorization required:none
 @param data {Object} {device_id,device_type,password,email}
 @returns {object} {token}
 */
  static async login(data) {
    let res = await this.request(`/app/login`, data, "post");
    return res;
  }

  // ----------Master Data List-----------
  static async masterDataList(refKey) {
    let res = await this.request(
      `/super-admin/master-data-list?ref_key=${refKey}`,
      undefined,
      "get"
    );
    return res;
  }

  /* POST  /signup:  {device_id,device_type}
signup api
Authorization required:none
@param data {Object} {device_id,device_type}
@returns {object} {token}
*/
  static async signUp(data) {
    let res = await this.request(`/app/sign-up`, data, "post");
    return res;
  }

  // ============check phone number, national id and email existence=======
  static async checkExistence(data) {
    let res = await this.request(
      `/app/phone-number-email-existance`,
      data,
      "post"
    );
    return res;
  }

  /* ------FORGOT PASSWORD API -----*/

  static async forgotPassword(data) {
    let res = await this.request(`/app/forgot-password`, data, "patch");
    return res;
  }

  /* ------VERIFY FORGOT PASSWORD API -----*/

  static async updatePassword(data) {
    let res = await this.request(`/app/update-password`, data, "patch");
    return res;
  }

  /* POST  /addVehicle:  {device_id,device_type}
addVehicle api
Authorization required:none
@param data {Object} {device_id,device_type}
@returns {object} {token}
*/
  static async addVehicle(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/app/add-vehicle`, data, "post", header);
    return res;
  }

  // ----------Vehicle List Api-----------
  static async vehicleList(authToken, pageNo, search, filterByVehicle) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/vehicle-list?page_no=${pageNo}&search=${search}&filter_by_veh=${filterByVehicle}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ------Change Lock Unlock API -----*/
  static async vehicleStatusChange(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/change-vehicle-lock-status`,
      data,
      "patch",
      header
    );
    return res;
  }

  // ----------Vehicle List Api-----------
  static async getJobList(authToken, pageNo) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/history?page_no=${pageNo}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* POST  /getSos:  {device_id,device_type}
Authorization required:yes
@param data {Object} {device_id,device_type}
@returns {object} {token}
*/
  static async sosService(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/app/get-sos-service`, data, "post", header);
    return res;
  }

  /* ------ App Logout Api -----*/
  static async userLogOut(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/logout`, undefined, "get", header);
    return res;
  }

  /* ------Change Pin  API -----*/
  static async changePin(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/change-pin`, data, "patch", header);
    return res;
  }

  /* ------ App Forgot pin Api -----*/
  static async forgotPin(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/forgot-pin`, undefined, "get", header);
    return res;
  }

  /* ------Change Pin  API -----*/
  static async updatePin(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/update-pin`, data, "patch", header);
    return res;
  }

  /* ------Delete account  API -----*/
  static async deleteAccount(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/delete-account`, data, "delete", header);
    return res;
  }

  /* ----- Change Password  API -----*/
  static async changePassword(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/reset-password`, data, "patch", header);
    return res;
  }

  /* ------ App my profile  Api -----*/
  static async myProfile(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/my-profile`, undefined, "get", header);
    return res;
  }

  /* ----- Update notification status API -----*/
  static async updateNotification(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/update-notification-status`,
      data,
      "patch",
      header
    );
    return res;
  }

  // ----------Content Api-----------
  static async getContent(authToken, pageName) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/super-admin/content-page?page_name=${pageName}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ----- Edit personal info API -----*/
  static async editPersonalInfo(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/edit-personal-info`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* ----- Edit security question answer API -----*/
  static async editSecurityQuestions(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/edit-security-que-ans`,
      data,
      "put",
      header
    );
    return res;
  }

  /* ----- Edit emergency contact API -----*/
  static async editEmegencyContact(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/edit-emergency-contacts`,
      data,
      "put",
      header
    );
    return res;
  }

  /* ----- map api -----*/
  static async getMap(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/map-vehicles`, undefined, "get", header);
    return res;
  }

  /* ----- Vehicle details api -----*/
  static async getVehicleDetail(authToken, vehicleId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/vehicle-details?vehicle_id=${vehicleId}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ----- Change geo fence status -----*/
  static async changeGeoFenceStatus(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/change-geo-fence-status`,
      data,
      "put",
      header
    );
    return res;
  }

  /* ----- App location update api-----*/
  static async updateLocation(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/app/location`, data, "post", header);
    return res;
  }

  /* ----- Notification list api -----*/
  static async getNotificationList(authToken, pageNo) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/notifications?page_no=${pageNo}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ----- Notification read api -----*/
  static async notificationIsRead(authToken, notificationId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/notification-is-read?notification_id=${notificationId}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* ----- Post driver location api -----*/
  static async postDriverLocation(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`app/location`, data, "post", header);
    return res;
  }

  // ----------Serial number existance Api-----------
  static async getSerialNumber(serialNo) {
    let res = await this.request(
      `/app/serial-number-existance?serial_no=${serialNo}`,
      "",
      "get"
    );
    return res;
  }

  /* ----- Reset sos api -----*/
  // static async getResetSos(authToken) {
  //   let header = { "access-token": `${authToken}` };
  //   let res = await this.request(`/app/reset-sos`, undefined, "get", header);
  //   return res;
  // }

  static async getResetSos(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/reset-sos`, data, "patch", header);
    return res;
  }

  // app sos vehicle list api
  static async sosVehicleList(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/sos-vehicle-list`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ----- SOS details api -----*/
  static async getSOSDetail(authToken, sosId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/sos-detail?sos_id=${sosId}`,
      undefined,
      "get",
      header
    );
    return res;
  }

  /* ----- Post sos image api -----*/
  static async postSosImage(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/app/upload-sos-img`, data, "post", header);
    return res;
  }

  /* ------Delete sos image  API -----*/
  static async deleteSosImage(authToken, imgId) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/app/delete-sos-img/${imgId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  /* ----- notify moving veicle api-----*/
  static async sendVehicleNotify(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/app/notify-moving-vehicle`,
      data,
      "post",
      header
    );
    return res;
  }

    /* ------Chnage Language API -----*/
    static async changeLanguage(authToken, data) {
      let header = { "access-token": `${authToken}`
    };
      let res = await this.request(
        `/app/change-language`,
        data,
        "patch",
        header
      );
      return res;
    }

}

export default SublyApi;