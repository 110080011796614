import theftImage from "../../Assests/Images/theift1.png";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./VehicleHistory.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RemoveBtn from "../../Assests/Images/RemoveBtn.svg";
import { STATUS_CODES } from "../../utils/StatusCode";
import {
  fetchSosDetails,
  getSosImg,
  userLogoutClear,
} from "../../store/slices/userSlice";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";
import SublyApi from "../../helpers/Api";
import moment from "moment";
import TheftImg from "../../Assests/Images/theift1.png";
import AccidentImg from "../../Assests/Images/accident1.png";
import RoadsideCallImg from "../../Assests/Images/roadside1.png";
import ImageModal from "./ImageModal";
import Loader from "../../utils/Loader/Loader";

function HistoryDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { jobHistoryData, userToken, sosData, isLoading } = useSelector(
    (state) => state.user
  );
  const [photos, setPhotos] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const dispatch = useDispatch();
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const getSosDetail = async () => {
    dispatch(fetchSosDetails({ userToken, sosId: id })).then((res) => {
      const response = res.payload;
      if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else if (
        response &&
        response.data &&
        response.code === STATUS_CODES.SUCCESS
      ) {
        setHistoryData(response?.data);
        setLatitude(response?.data?.latitude);
        setLongitude(response?.data?.longitude);
        const existingPhotos =
          response?.data?.images.map((img) => ({
            sos_img: img.sos_img,
            id: img.id,
            preview: img.sos_img,
          })) || [];
        setPhotos(existingPhotos);
      }
    });
  };
  useEffect(() => {
    getSosDetail();
  }, [id]);

  const handlePhotoUpload = async (e) => {
    const selectedFile = e.target.files[0]; // Only one file is selected
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/webp",
    ];
  
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      const photoWithPreview = Object.assign(selectedFile, {
        preview: URL.createObjectURL(selectedFile),
      });
  
      const updatedPhotos = [...photos, photoWithPreview].slice(0, 10);
      setPhotos(updatedPhotos);
  
      const requestData = new FormData();
      requestData.append("sos_id", id);
      requestData.append("image", selectedFile);
  
      await dispatch(
        getSosImg({
          userToken,
          requestData,
        })
      ).then((responsejson) => {
        const response = responsejson.payload;
        const ResponseCode = STATUS_MSG[response.data.code];
        if (response.code === STATUS_CODES.SUCCESS) {
          getSosDetail();
          Toster(t("IMAGE_ADDED_SUCESS"), "success");
        } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
        }
      });
    } else {
      Toster(t("INVALID_FILE_TYPE"), "error");
    }
  };
  

  const removePhoto = async (index) => {
    if (historyData.sos_status === "resolved") {
      return; // Don't proceed if sos_status is resolved
    }

    const updatedPhotos = photos.filter((_, i) => i !== index);
    const photoToRemove = photos[index];
    setPhotos(updatedPhotos);

    await SublyApi.deleteSosImage(userToken, photoToRemove.id).then(
      (responsejson) => {
        const ResponseCode = STATUS_MSG[responsejson.data.code];
        if (responsejson.code === STATUS_CODES.SUCCESS) {
          Toster(t("IMAGE_DELETE_SUCCUSS"), "success");
        } else if (responsejson.code === STATUS_CODES.USER_NOT_FOUND) {
          Toster(t("USER_NOT_FOUND"), "success");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };

  // generateGoogleMapsLink function accept latitude and longitude parameters
  const generateGoogleMapsLink = (latitude, longitude, placeName) => {
    const baseUrl = "https://www.google.com/maps/search/?api=1";
    const location = `&query=${encodeURIComponent(placeName)}&query_place_id=`;
    return `${baseUrl}&query=${latitude},${longitude}${location}`;
  };

  // Modify handleViewOnMap to use locationData instead of historyData
  const handleViewOnMap = () => {
    if (latitude && longitude) {
      const url = generateGoogleMapsLink(
        latitude,
        longitude,
        "Current Location"
      );
      window.open(url, "_blank");
    } else {
      console.error("Latitude or longitude is missing.");
    }
  };

  const getHistoryImage = (item) => {
    let imgUrl;
    if (item.sos_type == "theft") {
      imgUrl = TheftImg;
    } else if (item.sos_type == "accident") {
      imgUrl = AccidentImg;
    } else if (item.sos_type == "roadside-assistance") {
      imgUrl = RoadsideCallImg;
    }
    if (imgUrl) {
      return imgUrl;
    } else {
      return null;
    }
  };

  const getStatusForSubType = (subType) => {
    switch (subType) {
      case "theft":
        return t("THEFT");
      case "accident":
        return t("ACCIDENT");
      case "roadside-assistance":
        return t("ROAD_SIDE_ASSISTANCE");
      case "lock":
        return t("LOCK_TEXT");
      case "unlock":
        return t("UNLOCK_TEXT");
      default:
        return null;
    }
  };

  return (
    <div className={styles.settingWrapper}>
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.profileTitle}>
            <p>{t("HISTORY_DETAIL")}</p>
          </div>
        </div>
      </div>

      <div className={styles.bikeDetail}>
        {isLoading && <Loader />}
        <div className={styles.bikeHistory}>
          <div className={styles.bikeImage}>
            <img src={getHistoryImage(historyData)} alt="Bike" />
          </div>

          <div className={styles.vehicleText}>
            <div className={styles.historytextOption}>
              <h5>{historyData.license_plate_number}</h5>
            </div>
          </div>

          <div className={styles.historytextOption}>
            <p>
              {t("START_DATE")} :{" "}
              {moment(historyData.date).format("DD/MM/YYYY hh:mm A")}
              {/* {t("START_DATE")} : {new Date(historyData.date).toLocaleString()} */}
            </p>
          </div>

          <div className={styles.historytextOption}>
            <p>
              {t("END_DATE")} :{" "}
              {historyData.end_date
                ? moment(historyData.end_date).format("DD/MM/YYYY hh:mm A")
                : "NA"}
            </p>
          </div>
          <div className={styles.historytextOptionSos}>
            <p>
              {t("SOS_TYPE")} :{" "}
              <span>{getStatusForSubType(historyData.sos_type)}</span>
            </p>
          </div>

          <div className={styles.uploadPhoto}>
            {(historyData.sos_status == "pending" ||
              (photos.length > 0 && historyData.sos_status == "resolved")) && (
              <h3>{t("Images")}</h3>
            )}
            <div className={styles.photoUploadContainer}>
              {photos.map((photo, index) => (
                <div key={index} className={styles.thumbnailContainer}>
                  <img
                    src={photo.preview}
                    alt={`Uploaded ${index}`}
                    className={styles.thumbnail}
                    onClick={() => openModal(photo.preview)}
                  />

                  {historyData.sos_status !== "resolved" && (
                    <img
                      src={RemoveBtn}
                      onClick={() => removePhoto(index)}
                      className={styles.removeButton}
                      alt="Remove"
                    />
                  )}
                </div>
              ))}

              {photos.length < 10 && historyData.sos_status !== "resolved" && (
                <>
                  <label htmlFor="photoInput" className={styles.uploadButton}>
                    +
                  </label>
                  <input
                    id="photoInput"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handlePhotoUpload}
                    className={styles.fileInput}
                  />
                </>
              )}
            </div>
          </div>

          <div className={styles.viewBtn}>
            <button
              type="button"
              className={styles.nextButton}
              onClick={handleViewOnMap}
            >
              {t("VIEW_ON_MAP")}
            </button>
          </div>
        </div>
      </div>

      <ImageModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        imageSrc={selectedImage}
      />
    </div>
  );
}

export default HistoryDetail;
