//firebase
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  apiKey: "AIzaSyBcu_RWGLH4WneaLW_3y2VM3XCVNzee0cI",
  authDomain: "glock-website-5d196.firebaseapp.com",
  projectId: "glock-website",
  storageBucket: "glock-website.appspot.com",
  messagingSenderId: "639228526027",
  appId: "1:639228526027:web:92661827f067cb021c4a4e",
  measurementId: "G-PKZRG5X69R",
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("notification reaceived in onMessageListener", payload);
//       resolve(payload);
//     });
//   });

// generate the firebase token when notification permisstion is grandted 
function generateFirebaseToken() {
  return getToken(messaging, {
    vapidKey:
      "BEsu-dbH2IMwmvzW_Acg288VKhJl72Aq6MFJPHdm5mCzNEs6afB7dDHhpd48LntwIqtV_OPt2fSOo1RpxCo94ho",
  })
    .then((currentToken) => {
      console.log('firebase device token', currentToken)
      if (currentToken) {
        localStorage.setItem(
          process.env.REACT_APP_FIREBASE_TOKEN,
          currentToken
        );
      } else {
        console.log("Failed to generate the registration token.");
      }
    })

    .catch((err) => {
      console.log(
        "An error occurred when requesting to receive the token.",
        err
      );
    });
}

export function requestNotificationPermission() {
  // Check if the browser supports notifications
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications.');
    // alert('This browser does not support notifications.');
    return;
  }

  // Check if permission has already been granted
  if (Notification.permission === 'granted') {
    console.log('Permission to receive notifications has been granted');
    generateFirebaseToken();
  } else if (Notification.permission !== 'denied') {
    // Request permission from the user
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Permission to receive notifications has been granted');
        generateFirebaseToken();
      }
    });
  }
}


const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
