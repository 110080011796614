import styles from "../Sos/SosHistory.module.css";
import { FaBell } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import HomeFooterOption from "../Home/HomeFooterOption";
import accident from "../../Assests/Images/accident1.png";
import theift from "../../Assests/Images/theift1.png";
import roadsideAccistence from "../../Assests/Images/roadside1.png";
import sos from "../../Assests/Images/sos_image.png";
import { useEffect, useState } from "react";
import SosModal from "./SosModal";
import { SOS_TYPES } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toster";
import SosResetModal from "./SosResetModal";

function SosHistory() {
  const { unreadNotification, userToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sosModalOpen, setSosModalOpen] = useState();
  const [selectedSosType, setSelectedSosType] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [sosVehicleList, setSosVehicleList] = useState([]);

  //======== Function to handle opening the SOS modal and setting the selected SOS type========
  const openSosModal = (sosType) => {
    setSelectedSosType(sosType);
    setSosModalOpen(true);
  };

  //======== Function to handle reset sos service the SOS========
  const openResetModal = (resetVehicle) => {
    setSelectedVehicle(resetVehicle);
    setResetModalOpen(true);
  };

  //========== function for calling get vehicle list api==========
  async function getSosVehicleList() {
    await SublyApi.sosVehicleList(userToken).then((responsejson) => {
      const ResponseCode = STATUS_MSG[responsejson.data.code];
      if (responsejson.code === STATUS_CODES.SUCCESS) {
        setSosVehicleList(responsejson.data);
        setLoading(false);
      } else if (responsejson.data.code === STATUS_CODES.VALIDATION_ERROR) {
      } else if (responsejson.data.code === STATUS_CODES.INCORRECT_PIN_NUMBER) {
        Toster(t("INCORRECT_PIN_NUMBER"), "error");
        setLoading(false);
      } else {
        Toster(t(ResponseCode), "error");
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    getSosVehicleList();
  }, []);

  return (
    <div className={styles.settingWrapper}>
      <div className={styles.backGround}>
        <div className={styles.topItem}>
          <h5>{t("SOS_TITLE")}</h5>
        </div>
        <div className={styles.bellIcon}>
          {unreadNotification > 0 ? (
            <div className={styles.notificationTick}></div>
          ) : (
            ""
          )}

          <FaBell onClick={() => navigate("/notification")} />
        </div>
      </div>
      <div className="fixContent">
        <div className={styles.listBox}>
          <div className={styles.historyList}>
            <h2>{t("NEED_HELP")}</h2>
            <p>{t("PRESS_BUTTON")}</p>
          </div>

          <div className={styles.sosImg}>
            <a href="tel:911">
              <img src={sos} alt="Emergency" />
            </a>
          </div>

          <div className={styles.sosServiceImg}>
            <div className={styles.text}>
              <div
                className={styles.theiftImg}
                onClick={() => openSosModal(SOS_TYPES.THEFT)}
              >
                <img src={theift}></img>
              </div>
              <p>{t("THEFT")}</p>
            </div>

            <div className={styles.text}>
              <div
                className={styles.accidentImg}
                onClick={() => openSosModal(SOS_TYPES.ACCIDENT)}
              >
                <img src={accident}></img>
              </div>
              <p>{t("ACCIDENT")}</p>
            </div>
            <div className={styles.text}>
              <div
                className={styles.roadSideAccistenceImg}
                onClick={() => openSosModal(SOS_TYPES.ROAD_SIDE_ASSISTANCE)}
              >
                <img src={roadsideAccistence}></img>
              </div>
              <p>{t("ROAD_SIDE_ASSISTANCE")}</p>
            </div>
          </div>

          {sosVehicleList.length > 0 && (
            <div className={styles.ResetBtn}>
              <button
                type="submit"
                onClick={() => openResetModal()}
                className={`${styles.nextButton} ${
                  loading ? styles.disabledButton : ""
                }`}
                disabled={loading}
              >
                {t("RESET_BTN")}
              </button>
            </div>
          )}
        </div>
      </div>

      <HomeFooterOption />
      <SosModal
        sosModalOpen={sosModalOpen}
        setSosModalOpen={setSosModalOpen}
        selectedSosType={selectedSosType}
      />
      <SosResetModal
        resetModalOpen={resetModalOpen}
        setResetModalOpen={setResetModalOpen}
        selectedVehicle={selectedVehicle}
        sosVehicleList={sosVehicleList}
        getSosVehicleList={getSosVehicleList}
      />
    </div>
  );
}

export default SosHistory;
