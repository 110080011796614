import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";
import styles from "../Map/Map.module.css";
import { FaBell } from "react-icons/fa";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import HomeFooterOption from "../Home/HomeFooterOption";
import "../../Assests/Styles/Common.css";
import { getMapData, userLogoutClear } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import BlackBike from "../../Assests/Images/blackBike.png";
import RedBike from "../../Assests/Images/redBike.png";
import GrayBike from "../../Assests/Images/greyBike.png";
import YellowBike from "../../Assests/Images/yelloBike.png";
import L from "leaflet";
import { useNavigate, useParams } from "react-router-dom";
import Toster from "../../utils/Toster";

const Map = () => {
  const { unreadNotification } = useSelector((state) => state.user);

  const [currentLocation, setCurrentLocation] = useState([18.4861, -69.9312]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userToken, mapData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const markerRef = useRef(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { id } = useParams();

  //========= function for get currunt position of vehicle==========
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation([latitude, longitude]);
      },
      (error) => {
        setCurrentLocation([18.4861, -69.9312]);
        console.error("Error getting current location:", error);
      }
    );
  }, []);

  //--------- function for calling my profile api--------
  useEffect(() => {
    const appMap = async () => {
      dispatch(getMapData(userToken)).then((response) => {
        const responsejson = response.payload;
        const ResponseCode = STATUS_MSG[responsejson && responsejson.data.code];
        if (
          responsejson &&
          responsejson.data &&
          responsejson.code === STATUS_CODES.SUCCESS
        ) {
        } else if (
          responsejson &&
          responsejson.data &&
          responsejson.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
        } else {
          Toster(t(ResponseCode), "error");
        }
      });
    };
    appMap();
  }, []);

  //======= function for handle marker=========
  const getMarkerIcon = (vehicle) => {
    let iconUrl;
    switch (vehicle.colour_status) {
      case "black":
        iconUrl = BlackBike;
        break;
      case "red":
        iconUrl = RedBike;
        break;
      case "grey":
        iconUrl = GrayBike;
        break;
      case "yellow":
        iconUrl = YellowBike;
        break;
      default:
        iconUrl = BlackBike;
    }
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [40, 40],
    });
  };


  // ==========function for handle marker click============
  const handleMarkerClick = (bike) => {
    setSelectedMarker(bike);
    markerRef.current?.openPopup();
  };

  return (
    <>
      <div className={styles.mapWrapper}>
        <div className={styles.backGround}>
          <div className={styles.topItem}>
            <h5>{t("MAP_TITLE")}</h5>
          </div>
          <div className={styles.bellIcon}>
            {unreadNotification > 0 ? (
              <div className={styles.notificationTick}></div>
            ) : (
              ""
            )}
            <FaBell onClick={() => navigate("/notification")} />
          </div>
        </div>

        <div className="mapItem">
        <div style={{ height: "600px", width: "100%" }}>
          {currentLocation.length > 0 && (
            <MapContainer
              key={`${currentLocation[0]}-${currentLocation[1]}`}
              center={currentLocation}
              scrollWheelZoom={false}
              attributionControl={false}
              touchZoom={true}
              doubleClickZoom={true}
              zoom={16}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />

              {mapData &&
                mapData.length > 0 &&
                mapData.map((vehicle) =>
                  vehicle.latitude && vehicle.longitude ? (
                    <>
                      {vehicle.geo_fence_status === 1 && (
                        <Circle
                          center={currentLocation}
                          radius={vehicle.distance * 1000}
                          fillColor="#FF2A00"
                          color="#FF2A00"
                          width="1"
                        />
                      )}
                      <Marker
                        key={vehicle.vehicle_id}
                        position={[vehicle.latitude, vehicle.longitude]}
                        icon={getMarkerIcon(vehicle)}
                        eventHandlers={{
                          click: () => handleMarkerClick(vehicle),
                        }}
                      >
                        {selectedMarker &&
                          selectedMarker.vehicle_id === vehicle.vehicle_id && (
                            <Popup closeButton={false}>
                              <div className={styles.optionBike}>
                                <div
                                  className={styles.bikeImage}
                                  onClick={() =>
                                    navigate(
                                      `/vehicle-detail/${vehicle.vehicle_id}`
                                    )
                                  }
                                >
                                  <img src={vehicle.vehicle_image} alt="bike" />{" "}
                                </div>

                                {/* <p>
                              {t("PILOT_NAME")} : {vehicle.name}
                            </p> */}
                                <p>
                                  {t("PLATE_NUMBER")} :{" "}
                                  {vehicle.license_plate_number}
                                </p>
                                <p>
                                  {t("BIKE_MODEL")} : {vehicle.vehicle_model}
                                </p>
                                <p>
                                  {t("BIKE_COLOR")} : {vehicle.vehicle_colour}
                                </p>
                                <p>
                                  {t("BIKE_YEAR")} : {vehicle.year}
                                </p>
                              </div>
                            </Popup>
                          )}
                      </Marker>
                    </>
                  ) : (
                    ""
                  )
                )}
            </MapContainer>
          )}
          </div>
        </div>
        <HomeFooterOption />
      </div>
    </>
  );
};

export default Map;
