import styles from "../ForgotPassword/ForgotPassword.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import lockImg from "../../Assests/Images/Lock1.png";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";
import { useState } from "react";
import Loader from "../../utils/Loader/Loader";

function ForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // ======== function for forgot password api calling =======
  const onSubmit = async (formdata) => {
    setLoader(true);
    let requestData = new FormData();
    requestData.append("email", formdata.email);
    await SublyApi.forgotPassword(requestData).then((responsejson) => {
      const ResponseCode = STATUS_MSG[responsejson.data.code];
      if (responsejson.code === STATUS_CODES.SUCCESS) {
        Toster(t("PASSWORD_SENT"), "success");
        navigate("/reset-password", {
          state: {
            email: formdata.email,
          },
        });
        setLoader(false);
      } else if (responsejson.data.code === 105) {
        Toster(responsejson.data.message, "error");
        setLoader(false);
      } else {
        Toster(t(ResponseCode), "error");
        setLoader(false);
      }
    });
  };

  //======= disable space on key press input email field========
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  return (
    <div className={styles.wrapper}>
      {loader ? <Loader /> : ""}
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.forgotText}>
            <p>{t("FORGOT_PASSWORD")}</p>
          </div>
        </div>
      </div>

      <div className={styles.lockImg}>
        <img src={lockImg}></img>
      </div>

      <div className={styles.tittleText}>
        <h3>{t("FORGOT_YOUR_PASSWORD")}</h3>
        <p>{t("FORGOT_PARA")}</p>
      </div>

      <Container>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("EMAIL_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("EMAIL_PLACEHOLDER_TEXT")}`}
                type="text"
                autoComplete="off"
                {...register("email", {
                  onChange: (e) => onInputChange(e),
                  required: {
                    value: true,
                    message: `${t("EMAIL_REQUIRED_MESSAGE")}`,
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: `${t("EMAIL_INVALID")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.email && (
                <span className="errorMsg">{errors.email.message}</span>
              )}
            </Form.Group>

            <div className={styles.forgotButton}>
              <button>{t("SEND_BTN")}</button>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default ForgotPassword;