import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../DeleteAccount/DeleteAccount.module.css";
import { TbAlertTriangleFilled } from "react-icons/tb";
import SublyApi from "../../helpers/Api";
import Toster from "../../utils/Toster";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../store/slices/userSlice";

function DeleteAccount({ openDelete, setOpenDelete }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);

  // =======function for calling logOut api=======
  const userDelete = async () => {
    await SublyApi.deleteAccount(userToken).then((responsejson) => {
      const ResponseCode = STATUS_MSG[responsejson.data.code];
      if (responsejson.code === STATUS_CODES.SUCCESS) {
        Toster(t("ACCOUNT_DELETE_SUCCUSS"), "success");
        setOpenDelete(false);
        dispatch(userLogoutClear());
      } else if (responsejson.code === STATUS_CODES.USER_NOT_FOUND) {
        Toster(t("USER_NOT_FOUND"), "success");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  return (
    <div>
      <Modal
        show={openDelete}
        onHide={() => {
          setOpenDelete(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Body className="modalCustom">
          <div className={styles.iconText}>
            <TbAlertTriangleFilled
              icon="solar:danger-triangle-broken"
              color="red"
              width="100"
              height="100"
            />
          </div>

          <div className={styles.alertBox}>
            <h6>{t("ALERT")}</h6>
          </div>
          <div className={styles.deletAcc}>{t("WANT_TO_DELETE")}</div>
          <div className={styles.Btns}>
            <button
              className={styles.cancelBtn}
              onClick={() => setOpenDelete(false)}
            >
              {t("CANCEL")}
            </button>
            <button className={styles.deleteBtn} onClick={() => userDelete()}>
              {t("OK")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteAccount;
