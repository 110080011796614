export const META_REF_KEY = {
  VEHICLE_COLOURS: "colours",
  BLOOD_TYPE: "blood_types",
  SECURITY_QUESTION: "security_questions",
  HEALTH_ISSUE: "health_issues",
  CONTACT_RELATIONSHIP: "contact_relation",
};

export const SIGNUP_TYPE = {
  NORMAL_SIGNUP: "normal",
  SOCIAL_SIGNUP: "social",
};

export const FILTER_BY_VEHICLE = {
  MY_VEHICLE: "myVehicle",
  COMPANY_VEHICLE: "companyVehicle",
};

export const SOS_TYPES = {
  THEFT: "theft",
  ACCIDENT: "accident",
  ROAD_SIDE_ASSISTANCE: "roadside-assistance",
};

export const SOCIAL_TYPE = {
  GOOGLE: "google",
  FACEBOOK: "facebook",
};

export const CONTENT_TYPE = {
  ABOUT_US: "about-us",
  PRIVACY_POLICY: "privacy-policy",
  TERMS_CONDITION: "terms-conditions",
};

export const CHECK_TYPE = {
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  NATIONAL_ID: "national_id"
};
