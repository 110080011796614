import { useGoogleLogin } from "@react-oauth/google";
import google from "../../Assests/Images/Google_ico.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setSocialUserData, userLogin } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { SIGNUP_TYPE, SOCIAL_TYPE } from "../../utils/Constants";
import Toster from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useTranslation } from "react-i18next";
import { STATUS_MSG } from "../../utils/StatusMsg";

//---------function for google social api call----------
function GoogleLogin() {
  const { t } = useTranslation();
  const [user, setUser] = useState("");
  const [profile, setProfile] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //---- Social Login with google------
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  //----- function for calling social login api-------
  useEffect(() => {
    if (profile) {
      let requestData = new FormData();
      requestData.append("signup_type", SIGNUP_TYPE.SOCIAL_SIGNUP);
      requestData.append("social_id", profile.id);
      requestData.append("social_type", SOCIAL_TYPE.GOOGLE);
      if (profile.id) {
        dispatch(userLogin(requestData)).then((response) => {
          const socialRes = response.payload;
          const msg = socialRes && socialRes.data && socialRes.data.message;
          const ResponseCode =
            STATUS_MSG[socialRes && socialRes.data && socialRes.data.code];
          if (
            response &&
            response.payload &&
            response.payload.code === STATUS_CODES.SUCCESS
          ) {
            navigate("/home-list");
          } else if (
            socialRes.data &&
            socialRes.data.code === STATUS_CODES.SOCIAL_ACCOUNT_NOT_FOUND
          ) {
            dispatch(
              setSocialUserData({
                id: profile.id,
                name: profile.name,
                email: profile.email,
                socialType: SOCIAL_TYPE.GOOGLE,
              }) // show prefilled data in signup form
            );
            navigate("/sign-up", {
              state: {
                profileData: {
                  id: profile.id,
                  name: profile.name,
                  email: profile.email,
                  socialType: SOCIAL_TYPE.GOOGLE,
                },
                socialType: SOCIAL_TYPE.GOOGLE,
              },
            }); // if account not exist then navigate in signup
          } else {
            dispatch(
              setSocialUserData({
                id: profile.id,
                name: profile.name,
                email: profile.email,
                socialType: SOCIAL_TYPE.GOOGLE,
              })
            );

            if (
              socialRes &&
              socialRes.data &&
              socialRes.data.code === STATUS_CODES.SERVER_VALIDATION
            ) {
              Toster(msg, "error");
            } else {
              Toster(t(ResponseCode), "error");
            }
          }
        });
      }
    }
  }, [profile]);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => err);
    }
  }, [user]);

  return (
    <>
      <img src={google} alt="google-logo" onClick={() => login()} />
    </>
  );
}
export default GoogleLogin;
