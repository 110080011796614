import Modal from "react-bootstrap/Modal";
import styles from "./Sos.module.css";
import "../../Assests/Styles/Common.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import Toster from "../../utils/Toster";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { useLocation, useNavigate } from "react-router-dom";
import SublyApi from "../../helpers/Api";

//---------function for vehicle type popup modal---------
function SosResetModal({
  resetModalOpen,
  setResetModalOpen,
  selectedVehicle,
  sosVehicleList,
  getSosVehicleList,
}) {
  const { userToken, isLoading } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const listInnerRef = useRef(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (resetModalOpen) {
  //     getSosVehicleList();
  //   }
  // }, [resetModalOpen]);

  useEffect(() => {
    if (resetModalOpen) {
      getSosVehicleList();
      setSelectedVehicleId(null); // Reset selected vehicle ID when modal is opened
    }
  }, [resetModalOpen]);

  // ----------- function for manage vehicle select----------------
  const handleVehicleChange = (vehicle) => {
    setSelectedVehicleId(vehicle.vehicle_id);
  };

  //======   function for handle reset sos====
  const handleReset = async () => {
    setLoading(true);
    const requestData = new FormData();
    requestData.append("vehicle_id", selectedVehicleId);
    let res = SublyApi.getResetSos(userToken, requestData);
    res.then((response) => {
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODES.SUCCESS) {
        Toster(t("RESER_SOS_SUCCESS"), "success");
        getSosVehicleList();
        setResetModalOpen(false);
        setLoading(false);
      } else {
        Toster(t(ResponseCode), "error");
        setLoading(false);
      }
    });
  };

  return (
    <div className="modal show">
      <Modal
        show={resetModalOpen}
        // onHide={() => {
        //   setResetModalOpen(false);
        // }}
        onHide={() => {
          setResetModalOpen(false);
          setSelectedVehicleId(null); // Reset selected vehicle ID when modal is closed
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("SELECT_VEHICLE")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            // onScroll={handleScroll}
            ref={listInnerRef}
            className="fixContentModal"
          >
            {sosVehicleList && sosVehicleList.length > 0 ? (
              sosVehicleList.map((item, index) => {
                return (
                  <div className="vehicleInput" key={index}>
                    <input
                      type="radio"
                      id={item.vehicle_id}
                      name="select"
                      onChange={() => handleVehicleChange(item)}
                    />
                    <label
                      htmlFor={item.vehicle_id}
                      className={styles.vehicleInputDisplay}
                    >
                      <div className={styles.vehicleInputText}>
                        <h2>{item.license_plate_number}</h2>
                        <p>{item.vehicle_model}</p>
                      </div>
                      <img src={item.vehicle_image} alt="vehicle-img" />
                    </label>
                  </div>
                );
              })
            ) : isLoading ? (
              <Loader />
            ) : (
              <div className={styles.noResultsMessage}>
                {t("NO_VEHICLES_FOUND")}.
              </div>
            )}
            {isLoading && <Loader />}
          </div>
          <div className={styles.doneBtn}>
            <button
              onClick={() => {
                if (selectedVehicleId) {
                  // Add this condition to check if a vehicle is selected
                  handleReset(selectedVehicle);
                } else {
                  Toster(t("SELECT_VEHICLE_ERROR"), "error"); // Show error message if no vehicle is selected
                }
              }}
            >
              {t("DONE_BUTTON")}
            </button>
          </div>

          <div className={styles.cancel}>
            <button
              // onClick={() => {
              //   setResetModalOpen(false);
              // }}
              onClick={() => {
                setResetModalOpen(false);
                setSelectedVehicleId(null); // Reset selected vehicle ID when modal is closed
              }}
            >
              {t("CANCEL")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SosResetModal;
