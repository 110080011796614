import { FILTER_BY_VEHICLE } from "./Constants";

export const VehicleTypes = [
  {
    id: "1",
    label: "MY_VEHICLE_LABEL",
    value: FILTER_BY_VEHICLE.MY_VEHICLE,
  },
  {
    id: "2",
    label: "COMPANY_VEHICLE_LABEL",
    value: FILTER_BY_VEHICLE.COMPANY_VEHICLE,
  },
];
