import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoMdArrowRoundBack } from "react-icons/io";
import styles from "../Profile/Profile.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { IoMdArrowDropdown } from "react-icons/io";
import Loader from "../../utils/Loader/Loader";
import { Container, Form } from "react-bootstrap";
import {
  appMyProfile,
  fetchMasterData,
  setActiveTab,
  setEmergencyContactExist,
  userLogoutClear,
} from "../../store/slices/userSlice";
import SublyApi from "../../helpers/Api";
import Toster from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import PhoneInput from "react-phone-input-2";
import RelationshipModal from "../../AuthComponents/SignUp/RelationshipModal";
import RelationshipModal2 from "../../AuthComponents/SignUp/RelationshipModal2";
import RelationshipModal3 from "../../AuthComponents/SignUp/RelationshipModal3";

function EditEmergencyContact() {
  const { userToken, profileData, masterList, currentUser } = useSelector(
    (state) => state.user
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showThirdContact, setShowThirdContact] = useState(false);
  const [relationshipModalOpen, setRelationshipModalOpen] = useState(false);
  const [relationshipModalOpen2, setRelationshipModalOpen2] = useState(false);
  const [relationshipModalOpen3, setRelationshipModalOpen3] = useState(false);
  const [selectedRelationShip1, setSelectedRelationship1] = useState("");
  const [selectedRelationShip2, setSelectedRelationship2] = useState("");
  const [selectedRelationShip3, setSelectedRelationship3] = useState("");
  const [phoneNo1, setPhoneNo1] = useState();
  const [phoneNo2, setPhoneNo2] = useState("");
  const [phoneNo3, setPhoneNo3] = useState("");
  const [homeNo1, setHomeNo1] = useState("");
  const [homeNo2, setHomeNo2] = useState("");
  const [homeNo3, setHomeNo3] = useState("");
  const [phoneDialCode1, setPhoneDialCode1] = useState("");
  const [phoneDialCode2, setPhoneDialCode2] = useState("");
  const [phoneDialCode3, setPhoneDialCode3] = useState("");
  const [homeDialCode1, setHomeDialCode1] = useState("");
  const [homeDialCode2, setHomeDialCode2] = useState("");
  const [homeDialCode3, setHomeDialCode3] = useState("");
  const [phoneCountryCode1, setPhoneCountryCode1] = useState("");
  const [phoneCountryCode2, setPhoneCountryCode2] = useState("");
  const [phoneCountryCode3, setPhoneCountryCode3] = useState("");
  const [homeCountryCode1, setHomeCountryCode1] = useState("");
  const [homeCountryCode2, setHomeCountryCode2] = useState("");
  const [homeCountryCode3, setHomeCountryCode3] = useState("");
  const [isDefault, setIsDefault] = useState(1);

  // ======= Add a function to handle setting default contact =======
  const handleSetDefaultContact = (contactNumber) => {
    setIsDefault(contactNumber);
  };

  //======= function for apply validation using useForm ======
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  // =========function for disable space ===============
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //======= function for apply validation in phoneNumber field===========
  const validatePhoneNumber1 = (value) => {
    if (!value) {
      return `${t("CONTACT_NUMBER_REQUIRED")}`;
    }
    const phoneNo1 = value.replace(/\D/g, "");
    if (phoneNo1.length <= 8) {
      return `${t("PHONE_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };
  const validatePhoneNumber2 = (value) => {
    if (!value) {
      return `${t("CONTACT_NUMBER_REQUIRED")}`;
    }
    const phoneNo2 = value.replace(/\D/g, "");
    if (phoneNo2.length <= 8) {
      return `${t("PHONE_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  const validatePhoneNumber3 = (value) => {
    if (!value) {
      return `${t("CONTACT_NUMBER_REQUIRED")}`;
    }
    const phoneNo3 = value.replace(/\D/g, "");
    if (phoneNo3.length <= 8) {
      return `${t("PHONE_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  //===========function for apply validation in homeNumber field=========
  const validateHomeNumber1 = (value) => {
    if (!value) {
      return `${t("HOME_NUMBER_REQUIRED")}`;
    }
    const homeNo1 = value.replace(/\D/g, "");
    if (homeNo1.length <= 8) {
      return `${t("HOME_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };
  const validateHomeNumber2 = (value) => {
    if (!value) {
      return `${t("HOME_NUMBER_REQUIRED")}`;
    }
    const homeNo2 = value.replace(/\D/g, "");
    if (homeNo2.length <= 8) {
      return `${t("HOME_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };
  const validateHomeNumber3 = (value) => {
    if (!value) {
      return `${t("HOME_NUMBER_REQUIRED")}`;
    }
    const homeNo3 = value.replace(/\D/g, "");
    if (homeNo3.length <= 8) {
      return `${t("HOME_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  //========function for calling masterData api==========
  useEffect(() => {
    const refKey = "contact_relation";
    const getMasterDataList = async () => {
      dispatch(fetchMasterData({ refKey: refKey })).then((data) => {
        const all_data = data && data.payload.data.contact_relation;
        if (
          profileData &&
          Object.keys(profileData).length > 0 &&
          profileData.emergency_contacts.length > 0
        ) {
          const quesion_id1 =
            profileData && profileData?.emergency_contacts[0]?.relation_id;
          const quesion_id2 =
            profileData && profileData?.emergency_contacts[1]?.relation_id;
          const quesion_id3 =
            profileData && profileData?.emergency_contacts[2]?.relation_id;

          const is_question1 = all_data.some((item) => item.id === quesion_id1);
          if (is_question1) {
            setSelectedRelationship1({
              id: profileData?.emergency_contacts[0].relation_id,
              value_name: profileData?.emergency_contacts[0].relation,
            });
            setValue(
              "relationship1",
              profileData?.emergency_contacts[0].relation
            );
          }

          const is_question2 = all_data.some((item) => item.id === quesion_id2);
          if (is_question2) {
            setSelectedRelationship2({
              id: profileData?.emergency_contacts[1].relation_id,
              value_name: profileData?.emergency_contacts[1].relation,
            });
            setValue(
              "relationship2",
              profileData?.emergency_contacts[1].relation
            );
          }

          const is_question3 = all_data.some((item) => item.id === quesion_id3);
          if (is_question3) {
            setSelectedRelationship3({
              id: profileData?.emergency_contacts[2].relation_id,
              value_name: profileData?.emergency_contacts[2].relation,
            });
            setValue(
              "relationship3",
              profileData?.emergency_contacts[2].relation
            );
          }
        }
      });
    };
    getMasterDataList();
  }, []);

  //===== function for remove extra + in dial code======
  function removeExtraPlus(inputString) {
    if (typeof inputString !== "string") {
      return inputString;
    }
    let newStr = "+" + inputString;
    return newStr.replace(/\++/g, "+");
  }

  //========== function for calling edit emergency contact api===========
  const onSubmit = async (formdata) => {
    setLoader(true);
    const contactData = [
      {
        name: formdata.name1,
        relation: selectedRelationShip1 && selectedRelationShip1.id,
        dail_code_1: removeExtraPlus(phoneDialCode1),
        phone_num_1: phoneNo1,
        phone_num_2: homeNo1,
        phoneCountryCode1: phoneCountryCode1,
        dail_code_2: removeExtraPlus(homeDialCode1),
        homeCountryCode1: homeCountryCode1,
        is_default: isDefault == 1 ? 1 : 0,
        showThirdContact: showThirdContact,
      },
      {
        name: formdata.name2,
        relation: selectedRelationShip2 && selectedRelationShip2.id,
        dail_code_1: removeExtraPlus(phoneDialCode2),
        phone_num_1: phoneNo2,
        phone_num_2: homeNo2,
        phoneCountryCode2: phoneCountryCode2,
        dail_code_2: removeExtraPlus(homeDialCode2),
        homeCountryCode2: homeCountryCode2,
        is_default: isDefault == 2 ? 1 : 0,
        showThirdContact: showThirdContact,
      },
    ];
    if (formdata.name3) {
      contactData.push({
        name: formdata.name3,
        relation: selectedRelationShip3 && selectedRelationShip3.id,
        dail_code_1: removeExtraPlus(phoneDialCode3),
        phone_num_1: phoneNo3,
        phone_num_2: homeNo3,
        phoneCountryCode3: phoneCountryCode3,
        dail_code_2: removeExtraPlus(homeDialCode3),
        homeCountryCode3: homeCountryCode3,
        is_default: isDefault == 3 ? 1 : 0,
        showThirdContact: showThirdContact,
      });
    }
    let requestData = new FormData();
    requestData.append("e_contact_json", JSON.stringify(contactData));
    await SublyApi.editEmegencyContact(userToken, requestData).then(
      (responsejson) => {
        const ResponseCode = STATUS_MSG[responsejson.data.code];
        if (responsejson.code === STATUS_CODES.SUCCESS) {
          Toster(t("EMERGENCY_CONTACT_UPDATE"), "success");
          dispatch(appMyProfile(userToken));
          dispatch(setEmergencyContactExist(currentUser));
          navigate("/personal-information");
          dispatch(setActiveTab("Emergency contact"));
          setLoader(false);
        } else if (responsejson.code === STATUS_CODES.VALIDATION_ERROR) {
          Toster(t("VALIDATION_ERROR"), "success");
          setLoader(false);
        } else if (responsejson.code === STATUS_CODES.INVALID_TOKEN) {
          dispatch(userLogoutClear());
          setLoader(false);
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };

  //========= function for show this form data prefilled===
  useEffect(() => {
    if (profileData && profileData.emergency_contacts) {
      const contactData = profileData.emergency_contacts;
      {
        contactData &&
          contactData?.length > 0 &&
          contactData?.map((item, index) => {
            if (index === 0) {
              setValue("name1", item?.name);
              setValue("phoneNumber1", item.first_phone_number);
              setValue("home1", item.second_phone_number);
              setPhoneNo1(item.first_phone_number);
              setHomeNo1(item.second_phone_number);
              setPhoneDialCode1(item.first_dial_code);
              setHomeDialCode1(item.second_dial_code);
              if (item.is_default == 1) {
                setIsDefault(item.is_default == 1 ? 1 : 0);
              }

              setShowThirdContact(item.showThirdContact);
            } else if (index === 1) {
              setValue("name2", item.name);
              setValue("phoneNumber2", item.first_phone_number);
              setValue("home2", item.second_phone_number);
              setPhoneNo2(item.first_phone_number);
              setHomeNo2(item.second_phone_number);
              setPhoneDialCode2(item.first_dial_code);
              setHomeDialCode2(item.second_dial_code);
              if (item.is_default == 1) {
                setIsDefault(item.is_default == 1 ? 2 : 0);
              }
            } else if (index === 2) {
              setValue("name3", item.name);
              setValue("phoneNumber3", item.first_phone_number);
              setValue("home3", item.second_phone_number);
              setPhoneNo3(item.first_phone_number);
              setHomeNo3(item.second_phone_number);
              setPhoneDialCode3(item.first_dial_code);
              setHomeDialCode3(item.second_dial_code);
              if (item.is_default == 1) {
                setIsDefault(item.is_default == 1 ? 3 : 0);
              }
            }
          });
      }
    }
  }, [masterList.contact_relation]);

  return (
    <div className={styles.editProfileWrapper}>
      {loader ? <Loader /> : ""}
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.profileTitle}>
            <p>{t("EMERGENCY_CONTACT_TITLE")}</p>
          </div>
        </div>
      </div>

      <Container>
        <div className={styles.contactStep}>
          <div className={styles.editEmergencyContact}>
            <div className={styles.contactText}>
              <h5>{t("CONTACT1_TITLE")}</h5>
            </div>
            <div
              onClick={() => handleSetDefaultContact(1)}
              className={`defaultText ${
                isDefault === 1 ? "activeContactDefult" : ""
              }`}
            >
              <NavLink>{t("SET_DEFAULT_CONTACT")}</NavLink>
            </div>
          </div>

          <div className={styles.inputForm}>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.formSection}
            >
              <Form.Group className={styles.FormGroup}>
                <Form.Label className={styles.FormLabel}>
                  {t("NAME_LABEL")}
                </Form.Label>
                <Form.Control
                  className={styles.FormInput}
                  placeholder={`${t("NAME_PLACEHOLDER_TEXT")}`}
                  type="text"
                  {...register("name1", {
                    onChange: (e) => {
                      handleKeyPress(e);
                    },
                    required: {
                      value: true,
                      message: `${t("NAME_REQUIRED_MESSAGE")}`,
                    },
                    ...sanitizeHtmlTags(),
                  })}
                />

                {errors.name1 && (
                  <span className="errorMsg">{errors.name1.message}</span>
                )}
              </Form.Group>

              <Form.Group className={styles.FormGroup}>
                <Form.Label className={styles.FormLabel}>
                  {t("RELATIONSHIP_LABEL")}
                </Form.Label>
                <Form.Control
                  className={styles.FormInput}
                  placeholder={t("RELATIONSHIP_PLACEHOLDER_TEXT")}
                  type="text"
                  readOnly
                  onClick={() => setRelationshipModalOpen(true)}
                  {...register("relationship1", {
                    required: {
                      value: true,
                      message: `${t("RELATIONSHIP_REQUIRED_MESSAGE")}`,
                    },
                  })}
                />
                <IoMdArrowDropdown
                  className="dropDown"
                  onClick={() => {
                    setRelationshipModalOpen(true);
                  }}
                />

                {errors.relationship1 && (
                  <span className="errorMsg">
                    {errors.relationship1.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group className={styles.FormGroup}>
                <div className="phone-input-container">
                  <Controller
                    control={control}
                    name="phoneNumber1"
                    render={({ field }) => (
                      <PhoneInput
                        specialLabel={t("PHONE_INPUT_LABEL")}
                        placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                        disableDropdown={true}
                        countryCodeEditable={false}
                        onlyCountries={["do"]}
                        inputProps={{ maxLength: 15 }}
                        country={"do"}
                        value={
                          (phoneDialCode1 && phoneDialCode1.toString()) +
                          (phoneNo1 && phoneNo1.toString())
                        }
                        onChange={(value, country) => {
                          let dialCode = country.dialCode;
                          let phone = value.slice(
                            dialCode.length,
                            value.length
                          );
                          setPhoneCountryCode1(country.countryCode);
                          setPhoneDialCode1(dialCode);
                          setPhoneNo1(phone);
                          field.onChange(value);
                          clearErrors("");
                        }}
                        inputExtraProps={{
                          style: {
                            position: "relative",
                          },
                        }}
                        dropdownClass={styles.customDropdown}
                      />
                    )}
                    rules={{ validate: validatePhoneNumber1 }}
                  />

                  {errors.phoneNumber1 && (
                    <span className="errorMsg">
                      {errors.phoneNumber1.message}
                    </span>
                  )}
                </div>
              </Form.Group>
              <Form.Group className={styles.FormGroup}>
                <div className="phone-input-container">
                  <Controller
                    control={control}
                    name="home1"
                    render={({ field }) => (
                      <PhoneInput
                        specialLabel={t("HOME_INPUT_LABEL")}
                        placeholder={`${t("HOME_NUMBER_PLACEHOLDER")}`}
                        disableDropdown={true}
                        countryCodeEditable={false}
                        onlyCountries={["do"]}
                        inputProps={{ maxLength: 15 }}
                        country={"do"}
                        value={
                          (homeDialCode1 && homeDialCode1.toString()) +
                          (homeNo1 && homeNo1.toString())
                        }
                        onChange={(value, country) => {
                          let dialCode = country.dialCode;
                          let home = value.slice(dialCode.length, value.length);
                          setHomeCountryCode1(country.countryCode);
                          setHomeDialCode1(dialCode);
                          setHomeNo1(home);
                          field.onChange(value);
                          clearErrors("");
                        }}
                        inputExtraProps={{
                          style: {
                            position: "relative",
                          },
                        }}
                        dropdownClass={styles.customDropdown}
                      />
                    )}
                    rules={{ validate: validateHomeNumber1 }}
                  />
                  {errors.home1 && (
                    <span className="errorMsg">{errors.home1.message}</span>
                  )}
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className={styles.contactStep}>
          <div className={styles.editEmergencyContact}>
            <div className={styles.contactText}>
              <h5>{t("CONTACT2_TITLE")}</h5>
            </div>
            <div
              onClick={() => handleSetDefaultContact(2)}
              className={`defaultText ${
                isDefault === 2 ? "activeContactDefult" : ""
              }`}
            >
              <NavLink>{t("SET_DEFAULT_CONTACT")}</NavLink>
            </div>
          </div>
          <div className={styles.inputForm}>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.formSection}
            >
              <Form.Group className={styles.FormGroup}>
                <Form.Label className={styles.FormLabel}>
                  {t("NAME_LABEL")}
                </Form.Label>
                <Form.Control
                  className={styles.FormInput}
                  placeholder={`${t("NAME_PLACEHOLDER_TEXT")}`}
                  type="text"
                  {...register("name2", {
                    onChange: (e) => {
                      handleKeyPress(e);
                    },
                    required: {
                      value: true,
                      message: `${t("NAME_REQUIRED_MESSAGE")}`,
                    },
                    ...sanitizeHtmlTags(),
                  })}
                />

                {errors.name2 && (
                  <span className="errorMsg">{errors.name2.message}</span>
                )}
              </Form.Group>

              <Form.Group className={styles.FormGroup}>
                <Form.Label className={styles.FormLabel}>
                  {t("RELATIONSHIP_LABEL")}
                </Form.Label>
                <Form.Control
                  className={styles.FormInput}
                  placeholder={t("RELATIONSHIP_PLACEHOLDER_TEXT")}
                  type="text"
                  readOnly
                  onClick={() => setRelationshipModalOpen2(true)}
                  {...register("relationship2", {
                    required: {
                      value: true,
                      message: `${t("RELATIONSHIP_REQUIRED_MESSAGE")}`,
                    },
                  })}
                />
                <IoMdArrowDropdown
                  className="dropDown"
                  onClick={() => {
                    setRelationshipModalOpen2(true);
                  }}
                />

                {errors.relationship2 && (
                  <span className="errorMsg">
                    {errors.relationship2.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group className={styles.FormGroup}>
                <div className="phone-input-container">
                  <Controller
                    control={control}
                    name="phoneNumber2"
                    render={({ field }) => (
                      <PhoneInput
                        specialLabel={t("PHONE_INPUT_LABEL")}
                        placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                        disableDropdown={true}
                        countryCodeEditable={false}
                        onlyCountries={["do"]}
                        inputProps={{ maxLength: 15 }}
                        country={"do"}
                        value={
                          (phoneDialCode2 && phoneDialCode2.toString()) +
                          (phoneNo2 && phoneNo2.toString())
                        }
                        onChange={(value, country) => {
                          let dialCode = country.dialCode;
                          let phone = value.slice(
                            dialCode.length,
                            value.length
                          );
                          setPhoneCountryCode2(country.countryCode);
                          setPhoneDialCode2(dialCode);
                          setPhoneNo2(phone);
                          field.onChange(value); // Update the form value
                          clearErrors("");
                        }}
                        inputExtraProps={{
                          style: {
                            position: "relative",
                          },
                        }}
                        dropdownClass={styles.customDropdown}
                      />
                    )}
                    rules={{ validate: validatePhoneNumber2 }}
                  />
                  {errors.phoneNumber2 && (
                    <span className="errorMsg">
                      {errors.phoneNumber2.message}
                    </span>
                  )}
                </div>
              </Form.Group>
              <Form.Group className={styles.FormGroup}>
                <div className="phone-input-container">
                  <Controller
                    control={control}
                    name="home2"
                    render={({ field }) => (
                      <PhoneInput
                        specialLabel={t("HOME_INPUT_LABEL")}
                        placeholder={`${t("HOME_NUMBER_PLACEHOLDER")}`}
                        disableDropdown={true}
                        countryCodeEditable={false}
                        onlyCountries={["do"]}
                        inputProps={{ maxLength: 15 }}
                        country={"do"}
                        value={
                          (homeDialCode2 && homeDialCode2.toString()) +
                          (homeNo2 && homeNo2.toString())
                        }
                        onChange={(value, country) => {
                          let dialCode = country.dialCode;
                          let phone = value.slice(
                            dialCode.length,
                            value.length
                          );
                          setHomeCountryCode2(country.countryCode);
                          setHomeDialCode2(dialCode);
                          setHomeNo2(phone);
                          field.onChange(value);
                          clearErrors("");
                        }}
                        inputExtraProps={{
                          style: {
                            position: "relative",
                          },
                        }}
                        dropdownClass={styles.customDropdown}
                      />
                    )}
                    rules={{ validate: validateHomeNumber2 }}
                  />
                  {errors.home2 && (
                    <span className="errorMsg">{errors.home2.message}</span>
                  )}
                </div>
              </Form.Group>
              {showThirdContact && (
                <>
                  <div className={styles.editEmergencyContact}>
                    <div className={styles.contactText}>
                      <h5>{t("CONTACT3_TITLE")}</h5>
                    </div>
                    <div
                      onClick={() => handleSetDefaultContact(3)}
                      className={`defaultText ${
                        isDefault === 3 ? "activeContactDefult" : ""
                      }`}
                    >
                      <NavLink>{t("SET_DEFAULT_CONTACT")}</NavLink>
                    </div>
                  </div>
                  <Form.Group className={styles.FormGroup}>
                    <Form.Label className={styles.FormLabel}>
                      {t("NAME_LABEL")}
                    </Form.Label>
                    <Form.Control
                      className={styles.FormInput}
                      placeholder={`${t("NAME_PLACEHOLDER_TEXT")}`}
                      type="text"
                      {...register("name3", {
                        onChange: (e) => {
                          handleKeyPress(e);
                        },
                        required: {
                          value: true,
                          message: `${t("NAME_REQUIRED_MESSAGE")}`,
                        },
                        ...sanitizeHtmlTags(),
                      })}
                    />

                    {errors.name3 && (
                      <span className="errorMsg">{errors.name3.message}</span>
                    )}
                  </Form.Group>

                  <Form.Group className={styles.FormGroup}>
                    <Form.Label className={styles.FormLabel}>
                      {t("RELATIONSHIP_LABEL")}
                    </Form.Label>
                    <Form.Control
                      className={styles.FormInput}
                      placeholder={t("RELATIONSHIP_PLACEHOLDER_TEXT")}
                      type="text"
                      readOnly
                      onClick={() => setRelationshipModalOpen3(true)}
                      {...register("relationship3", {
                        required: {
                          value: true,
                          message: `${t("RELATIONSHIP_REQUIRED_MESSAGE")}`,
                        },
                      })}
                    />
                    <IoMdArrowDropdown
                      className="dropDown"
                      onClick={() => {
                        setRelationshipModalOpen3(true);
                      }}
                    />

                    {errors.relationship3 && (
                      <span className="errorMsg">
                        {errors.relationship3.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className={styles.FormGroup}>
                    <div className="phone-input-container">
                      <Controller
                        control={control}
                        name="phoneNumber3"
                        render={({ field }) => (
                          <PhoneInput
                            specialLabel={t("PHONE_INPUT_LABEL")}
                            placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                            disableDropdown={true}
                            countryCodeEditable={false}
                            onlyCountries={["do"]}
                            inputProps={{ maxLength: 15 }}
                            country={"do"}
                            value={
                              (phoneDialCode3 && phoneDialCode3.toString()) +
                              (phoneNo3 && phoneNo3.toString())
                            }
                            onChange={(value, country) => {
                              let dialCode = country.dialCode;
                              let phone = value.slice(
                                dialCode.length,
                                value.length
                              );
                              setPhoneCountryCode3(country.countryCode);
                              setPhoneDialCode3(dialCode);
                              setPhoneNo3(phone);
                              field.onChange(value);
                              clearErrors("");
                            }}
                            inputExtraProps={{
                              style: {
                                position: "relative",
                              },
                            }}
                            dropdownClass={styles.customDropdown}
                          />
                        )}
                        rules={{ validate: validatePhoneNumber3 }}
                      />
                      {errors.phoneNumber3 && (
                        <span className="errorMsg">
                          {errors.phoneNumber3.message}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className={styles.FormGroup}>
                    <div className="phone-input-container">
                      <Controller
                        control={control}
                        name="home3"
                        render={({ field }) => (
                          <PhoneInput
                            specialLabel={t("HOME_INPUT_LABEL")}
                            placeholder={`${t("HOME_NUMBER_PLACEHOLDER")}`}
                            disableDropdown={true}
                            countryCodeEditable={false}
                            onlyCountries={["do"]}
                            inputProps={{ maxLength: 15 }}
                            country={"do"}
                            value={
                              (homeDialCode3 && homeDialCode3.toString()) +
                              (homeNo3 && homeNo3.toString())
                            }
                            onChange={(value, country) => {
                              let dialCode = country.dialCode;
                              let phone = value.slice(
                                dialCode.length,
                                value.length
                              );
                              setHomeCountryCode3(country.countryCode);
                              setHomeDialCode3(dialCode);
                              setHomeNo3(phone);
                              field.onChange(value);
                              clearErrors("");
                            }}
                            inputExtraProps={{
                              style: {
                                position: "relative",
                              },
                            }}
                            dropdownClass={styles.customDropdown}
                          />
                        )}
                        rules={{ validate: validateHomeNumber3 }}
                      />
                      {errors.home3 && (
                        <span className="errorMsg">{errors.home3.message}</span>
                      )}
                    </div>
                  </Form.Group>
                </>
              )}
              <div className={styles.optionalButton}>
                <button
                  type="button"
                  onClick={() => setShowThirdContact(!showThirdContact)}
                >
                  {showThirdContact
                    ? t("OPTIONAL_CONTACT_HIDE")
                    : t("OPTIONAL_CONTACT_SHOW")}
                </button>
              </div>
              <div className={styles.nextBtn}>
                <button type="submit">{t("SUBMIT_BUTTON")}</button>
              </div>
            </Form>
            <RelationshipModal
              relationshipModalOpen={relationshipModalOpen}
              setRelationshipModalOpen={setRelationshipModalOpen}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedRelationShip1={selectedRelationShip1}
              setSelectedRelationship1={setSelectedRelationship1}
            />
            <RelationshipModal2
              relationshipModalOpen2={relationshipModalOpen2}
              setRelationshipModalOpen2={setRelationshipModalOpen2}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedRelationShip2={selectedRelationShip2}
              setSelectedRelationship2={setSelectedRelationship2}
            />

            <RelationshipModal3
              relationshipModalOpen3={relationshipModalOpen3}
              setRelationshipModalOpen3={setRelationshipModalOpen3}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedRelationShip3={selectedRelationShip3}
              setSelectedRelationship3={setSelectedRelationship3}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default EditEmergencyContact;
