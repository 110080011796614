export const TRANSLATIONS_ES = {
  // -----------------------------Login TEXT-------------------------------------
  LOGIN_TITLE: "Bienvenido a G-LOCK",
  EMAIL_LABEL: "Correo electrónico",
  EMAIL_PLACEHOLDER_TEXT: "Ingresa tu correo electrónico",
  PASSWORD_PLACEHOLDER_TEXT: "Introducir la contraseña",
  EMAIL_REQUIRED_MESSAGE: "Por favor introduce tu correo electrónico",
  PASSWORD_REQUIRED_MESSAGE: "Por favor, introduce tu contraseña",
  FORGOT_PASSWORD_LINK: "¿Has olvidado tu contraseña?",
  SIGN_IN_BUTTON: "Iniciar sesión",
  OR_SOCIAL_SIGNIN: "O inicia sesión con tu cuenta de Google",
  DONT_HAVE_ACCOUNT: "¿No tienes una cuenta todavía?",
  SIGN_UP_NOW: "Regístrate ahora",
  EMAIL_INVALID:
    "Por favor, introduce una dirección de correo electrónico válida",
  LOGIN_SUCCESSFULLY: "Iniciar sesión exitosamente",
  EMAIL_ALREADY_IN_USE:
    "Correo electrónico ya en uso, utilice otros correos electrónicos.",

  // -----------------------------ForgotPassword TEXT----------------------------
  FORGOT_PASSWORD: "Has olvidado tu contraseña",
  FORGOT_YOUR_PASSWORD: "¿Olvidaste tu contraseña?",
  FORGOT_PARA: `Por favor Ingresa tu correo electrónico  Recibirá un enlace para crear una nueva contraseña`,
  RESET_PASSWORD: "Restablecer la contraseña",
  NEW_PASSWORD: " Nueva contraseña ",
  SUBMIT_BUTTON: "Aceptar",
  OTP_REQUIRED: "Por favor Ingresa OTP",
  DIGIT_REQUIRED: "Por favor Ingresa 6 dígitos OTP",
  OTP_INPUT_LABEL:
    "Por favor ingrese el código que recibió en su identificación de correo",

  //-----------------------------SignUp TEXT----------------------------
  ACCOUNT_INFORMATION_TITTLE: "Información de la cuenta",
  NAME_LABEL: "Nombre",
  PASSWORD_LABEL: "Contraseña",
  CONFIRM_PASSWORD_LABEL: "Confirmar Contraseña",
  ENTER_HEALTH_ISSUE: "Ingrese otro problema de salud",
  CONFIRM_PASSWORD_PLACEHOLDER_TEXT: "Ingresa la contraseña",
  NAME_PLACEHOLDER_TEXT: "Ingrese su nombre",
  NAME_REQUIRED_MESSAGE: "Por favor, escriba tu nombre",
  CONFIRM_PASSWORD_REQUIRED_MESSAGE:
    "Por favor Ingresa tu contraseña de confirmación",
  SIGN_IN_NOW: "Iniciar sesión",
  ALREADY_HAVE_ACCOUNT: "¿Ya tienes una cuenta todavía?",
  NEXT_BUTTON: "Siguiente",
  PIN_NUMBER_LABEL: "Número de PIN",
  ADDRESS_LABEL: "Dirección",
  ADDRESS_PLACEHOLDER_TEXT: "Ingresa la direccion",
  ADDRESS_REQUIRED_MESSAGE: "Por favor Ingresa tu dirección",
  ADDRESS_VALIDATION_MINLENGTH: "Por favor ingrese mínimo 5 caracteres",
  ADDRESS_VALIDATION_MAXLENGTH: "Por favor ingrese mínimo 250 caracteres",
  NATIONAL_ID_LABEL: "Cédula",
  NATIONAL_ID_PLACEHOLDER_TEXT: "Ingresa tu cédula",
  NATIONAL_ID_REQUIRED_MESSAGE: "Por favor Ingresa tu cédula",
  BLOOD_TYPE_LABEL: "Tipo de sangre",
  FILL_OTHER_HEALTH_ISSUE: "Por favor complete el otro problema de salud.",
  BLOOD_TYPE_PLACEHOLDER_TEXT: "Seleccione tipo de sangre",
  BLOOD_TYPE_REQUIRED_MESSAGE: "Por favor selecciona tu tipo de sangre",
  HEALTH_ISSUE_LABEL: "Especificar problemas de salud",
  HEALTH_ISSUE_PLACEHOLDER_TEXT: "Selecciona problemas de salud",
  HEALTH_ISSUE_REQUIRED_MESSAGE: "Por favor selecciona tu problema de salud",
  SELECT_HEALTH_ISSUE_TITLE: "Selecciona problemas de salud",
  SELECT_BLOOD_TYPE: "Selecciona tipo de sangre",
  DONE_BUTTON: "Listo",
  CONFIRM_PASSWORD_NOT_MATCH:
    "La contraseña no coincide con la contraseña confirmada",
  PIN_REQUIRED: "El número de PIN es obligatorio",
  PERSONAL_INFORMATION_TITTLE: "Información personal",
  PHONE_NUMBER_LABEL: "Número de teléfono",
  PASS_MAXLENGTH:
    "Por favor introduce la contraseña, con un máximo de 12 caracteres",
  PASS_MINLENGTH:
    "Por favor Ingresa la contraseña, con un mínimo de 6 caracteres",
  DOB_LABEL: "Fecha de nacimiento",
  DOB_PLACEHOLDER: "Ingresa fecha de nacimiento",
  DATE_OF_BIRTH_REQUIRED: "Por favor selecciona tu fecha de nacimiento",
  PHONE_NUMBER_PLACEHOLDER: "Ingresa número telefónico",
  HOME_NUMBER_LABEL: "Ingresa el número de casa",
  HOME_NUMBER_PLACEHOLDER: "Ingresa el número de casa",
  COMPANY_LABEL: "Empresa",
  COMPANY_PLACEHOLDER_TEXT: "Ingresar Empresa",
  COMPANY_REQUIRED_MESSAGE: "Por favor Ingresa el nombre de tu empresa",
  SECURITY_QUESTION_LABEL1: "Preguntas de seguridad 1",
  SECURITY_PLACEHOLDER_TEXT: "Seleccionar preguntas de seguridad",
  SECURITY_QUESTION_REQUIRED_MESSAGE:
    "Por favor selecciona tu pregunta de seguridad",
  SECURITY_ANSWER_LABEL: "Respuesta",
  ANSWER_PLACEHOLDER_TEXT: "Ingresa la respuesta",
  ANSWER_REQUIRED_MESSAGE: "Por favor Ingresa tu respuesta de seguridad",
  SECURITY_QUESTION_LABEL2: "Preguntas de seguridad 2",
  SECURITY_QUESTION_LABEL3: "Preguntas de seguridad 3",
  SECURITY_QUESTION_TITLE: "Preguntas de seguridad",
  BIKE_INFORMATION_TITTLE: "Información sobre motocicleta",
  LICENSE_NUMBER_LABEL: "Número de placa",
  LICENSE_PLACEHOLDER_TEXT: "Ingresa el número de placa",
  LICENSE_REQUIRED_MESSAGE: "Por favor Ingresa tu número de placa",
  MODAL_NUMBER_LABEL: "Modelo de la motocicleta",
  MODAL_PLACEHOLDER_TEXT: "Introduce el modelo de la motocicleta",
  MODAL_REQUIRED_MESSAGE: "Por favor Ingresa tu modelo de motocicleta",
  BIKE_COLOR_LABEL: "Color de la motocicleta",
  BIKE_COLOR__PLACEHOLDER_TEXT: "Selecciona el color de la motocicleta",
  BIKE_COLOR__REQUIRED_MESSAGE:
    "Por favor selecciona el color de tu motocicleta",
  YEAR_OF_BIKE_LABEL: "Año de la motocicleta",
  BIKE_COLOR_TYPE: "Color de la motocicleta",
  YEAR_OF_BIKE_REQUIRED: "Por favor selecciona el año de la motocicleta",
  SKIP_BUTTON: "Saltar",
  SELECT_BIKE_YEAR: "Por favor selecciona el año de la motocicleta",
  PIN_REQUIRED_MESSAGE: "Por favor Ingresa tu número PIN",
  UPLOAD_BIKE_PHOTO_LABEL: "Subir foto de la motocicleta",
  EMERGENCY_CONTACT_TITLE: "Contactos de emergencia",
  CREATE_ACCOUNT_BUTTON: "Crear una cuenta",
  RELATIONSHIP_LABEL: "Relación",
  RELATIONSHIP_PLACEHOLDER_TEXT: "Ingresa la relación",
  RELATIONSHIP_REQUIRED_MESSAGE: "Por favor Ingresa tu relación",
  CONTACT_NUMBER_REQUIRED: "Por favor, introduce tu número de teléfono",
  PHONE_NUMBER_LENGTH_ERROR: "Por favor ingresa un número de teléfono válido",
  HOME_NUMBER_REQUIRED: "Por favor introduce tu número de casa",
  HOME_NUMBER_LENGTH_ERROR: "Por favor ingrese un número de casa válido",
  OTHER_LABEL: "Otro problema de salud",
  CONTACT1_TITLE: "Contacto 1",
  CONTACT2_TITLE: "Contacto 2",
  CONTACT3_TITLE: "Contacto 3",
  SET_DEFAULT_CONTACT: "Establecer predeterminado",
  UPLOAD_PHOTO: "Subir foto",
  OTHER_INPUT_REQUIRED: "Por favor Ingresa otro problema de salud",
  VALIDATION_MINLENGTH: "Por favor Ingresa mínimo 2 caracteres",
  VALIDATION_MAXLENGTH: "Por favor Ingresa un máximo de 15 caracteres",
  VALIDATION_MINLENGTH_NATIONAL_ID: "Por favor Ingresa mínimo 5 caracteres",
  VALIDATION_MAXLENGTH_NATIONAL_ID:
    "Por favor Ingresa un máximo de 25 caracteres",
  PASSWORD_MUST_CONTAIN:
    "La contraseña debe contener una mayúscula, una minúscula, un número y un carácter especial ",
  SCANER_QR_CODE: "Escáner de código QR",
  INCORRECT_PIN_ERROR:
    "Número de PIN incorrecto, Ingresa el PIN correcto para continuar ",
  CONTACT_TEXT: "Ingresa tu número de contacto",
  NATIONAL_ID_NUMBER_EXIST: "El número de identificación nacional ya existe",
  VEHICLE_SERIES_EXIST: "El número serial GLock ya existe",
  SERIAL_NUMBER_LABEL: "Número serial GLock",
  SERIAL_NUMBER_TEXT: "Número serial GLock",
  SERIAL_NUMBER_PLACEHOLDER_TEXT: "Introduce el número serial GLock",
  SERIAL_VALIDATION_MINLENGTH: "Por favor Ingresa mínimo 5 caracteres",
  SERIAL_VALIDATION_MAXLENGTH: "Por favor Ingresa un máximo de 25 caracteres",
  SERIAL_NUMBER_REQUIRED_MESSAGE: "Por favor Ingresa tu número serial GLock",
  ENTER_SIGNUP_PIN: "Por favor Ingresa el PIN de seguridad",
  MIN_PIN_LENGTH: "Por favor Ingresa el PIN, con un mínimo de 6 dígitos",
  BIRTHDAY_LABEL: "Cumpleaños",
  BIKE_YEAR_LABEL: "Año de la motocicleta",
  PHONE_INPUT_LABEL: "Número de teléfono",
  HOME_INPUT_LABEL: "Número de casa",
  OPTIONAL_CONTACT_HIDE: "Ocultar tercer contacto",
  OPTIONAL_CONTACT_SHOW: "Contacto 3 (Opcional)",
  BACK_ALERT_TEXT: "¿Quieres volver?",
  SELECT_IMAGE: "Por favor seleccione una imagen",
  NO_HISTORY_FOUND: "No se encontró ningún historial.",
  SECURITY_QUESTION_ONE_ERROR_MSG:
    "La primera pregunta de seguridad no puede ser la misma que la segunda o la tercera.",
  SECURITY_QUESTION_SECOND_ERROR_MSG:
    "La segunda pregunta de seguridad no puede ser igual a la primera.",
  SECURITY_QUESTION_THIRD_ERROR_MSG:
    "La tercera pregunta de seguridad no puede ser la misma que la segunda o la primera.",

  //---------------Api response error messages text----------------
  HEADER_IS_MISSING: "Falta el encabezado.",
  DEVICE_TYPE_NOT_ALLOWED: "Tipo de dispositivo no permitido",
  INVALID_API_KEY: "Clave de API no válida",
  TOKEN_REQUIRE_AUTHENTICATION: "Token requerido para autentificación",
  INVALID_TOKEN: "Simbolo no valido",
  VALIDATION_ERROR_CODE:
    "Error de validación de solicitud (devolver clave de mensaje con esto)",
  SUCCESS: "Éxito",
  STAFF_ERROR_MSG:
    "Acceso denegado NOTA: este código aparecerá cuando un personal intente ejecutar las API que no le están permitidas.",
  USER_NOT_FOUND: "Usuario no encontrado",
  BLOCKED_ACCOUNT: "Tu cuenta está actualmente bloqueada",
  SOCIAL_ALREADY_EXIT: "La cuenta está sincronizada con Google",
  SOCIAL_ACCOUNT_NOT_FOUND: "La cuenta no está sincronizada con Google",
  INTERNAL_SERVER_ERROR: "Error del servidor interno.",
  INVALID_LOGIN_CREDENTIAL: "Credenciales de acceso invalidos",
  ACCOUNT_NOT_FOUND:
    "Cuenta no encontrada, por favor Ingresa el correo electrónico correcto",
  ACCOUNT_CURRENTLY_INACTIVE: "Tu cuenta está actualmente inactiva",
  ACCOUNT_NOT_FOUND: "Tu cuenta ha sido eliminada",
  OLD_PASSWORD_DOES_NOT_MATCH: "La contraseña anterior no coincide.",
  PHONE_NUMBER_ALREADY_USE:
    "El número de teléfono ya está en uso; utilice otro número de teléfono",
  VEHICLE_IMAGE_REQUIRED: "Se requiere imagen de la motocicleta",
  ALL_SECURITY_QUESTION_REQUIRED: "Todas las preguntas de seguridad requeridas",
  ALL_EMERGENCY_CONTACT_REQUIRED: "Todas las preguntas de seguridad requeridas",
  FAILED_TO_SEND_EMAIL: "Error en el envío de correo electrónico.",
  INVALID_BLOOD_TYPE_ID: "Identificación de tipo de sangre no válida",
  INVALID_HEALTH_ISSUE_ID: "ID de problema de salud no válido",
  INVALID_SECURITY_QUESTION_ID: "ID de pregunta de seguridad no válido",
  INVALID_COLOR_ID: "ID de color no válido",
  VEHICLE_IMAGE_MAX_SIZE: "Imagen de la motocicleta tamaño máximo 10 mb",
  INVALID_VEHICLE_IMAGE_EXTENSION:
    "Extensión de imagen de la motocicleta no válida  Utilice jpeg gif png webp",
  INVALID_SECURITY_QUESTION_ANSWER:
    "Formato JSON de pregunta de seguridad no válido",
  INVALID_SCANNER_JSON_FORMATE: "Invalid scanner json format",
  INVALID_EMERGENCY_CONTACT: "Formato json del contacto de emergency no válido",
  CODE_ALREADY_USE: "Código ya usado",
  CODE_DOES_NOT_MATCH: "El código no coincide",
  INVALID_CONTACT_RELATION_ID: "ID de relación de contacto no válido",
  OTHER_HEALTH_ISSUE_REQUIRED: "Se requiere otro problema de salud",
  SIGNUP_SUCCESSFULLY: "Registro exitoso",
  UPLOAD_PHOTO_ERROR: "Por favor sube la imagen de la motocicleta",
  RELATION_TYPE: "Tipo de relación",
  UPDATE_PASSWORD_SUCCUSS: "Actualización de contraseña exitosamente",
  PASSWORD_SENT: "Contraseña enviada a tu correo electrónico",
  ACCOUNT_DELETED: "Tu cuenta ha sido eliminada.",
  EMAIL_LINKED_SOCIAL_USER:
    "El correo electrónico está vinculado con una cuenta de Google",
  EMAIL_LINKED_WITH_NORMAL_USER:
    "El correo electrónico está vinculado con la usuario normal",
  FAILED_UPLOAD_IMAGE: "No se pudo cargar la imagen",
  INVALID_VEHICLE_ID: "Identificación de la motocicleta no válida",
  INCORRECT_PIN_NUMBER: "Número de PIN incorrecto",
  VEHICLE_UNBLOCKED_BY_COMPANY:
    "La motocicleta ya está desbloqueada por la empresa",
  VEHICLE_LOCKED_SUCCUSFULLY: "Motocicleta bloqueada con éxito",
  VEHICLE_UNLOCKED_SUCCSFULLY: "Motocicleta desbloqueada con éxito",
  LICENCE_PLATE_NUMBER_EXIST: "Número de placa de la motocicleta ya registrado",
  YOUR_SESSION_EXPIRE: "Tu sesión expiró",

  //-------------------Home List-----------------
  HOME_TITLE: "Inicio",
  SEARCH: "Buscar motocicleta",
  ADD_NEW_BIKE_TITLE: "Agregar nueva motocicleta",
  ENTER_LOCK_PIN: "Por favor Ingresa tu número PIN de 6 dígitos",
  APPLY_FILTER_TITLE: "Filtrado por",
  APPLY_FILTER: "Aplicar filtro",
  CLEAR_FILTER: "Filtro claro",
  ADD_VEHICLE_SUCCESSFULLY: "Agregar nueva motocicleta exitosamente",
  CHANGE_STATUS_LOCK: "Motocicleta bloqueada con éxito",
  CHANGE_STATUS_UNLOCK: "Motocicleta desbloqueada con éxito",
  FORGOT_PIN_LINK: "¿Olvidaste el PIN?",
  VEHICLE_DETAIL_TITLE: "Detalles de la motocicleta",
  KILOMETERS: "Distancia (km):",
  LICENCE_PLATE: "Número de placa",
  MODAL_NUMBER_TEXT: "Nombre del modelo",
  VEHICLE_YEAR: "Año de la motocicleta",
  VEHICLE_COLOR: "Color de la motocicleta",
  GEO_FENCE_BTN: "Geo-Cerca",
  MAXLENGTH: "Por favor Ingresa una distancia máxima de 50 km",
  GEO_FENCE_TEXT: "Geo-Cerca :",
  PIN_REQUIRED_MSG: "Por favor Ingresa un PIN válido de 6 dígitos",
  ENTER_NEW_PIN_NUMBER: "Por favor Ingresa un nuevo PIN",
  ENTER_OLD_PIN: "Por favor Ingresa el número PIN antiguo",
  INCORRECT_PIN_NUMBER: "PIN incorrecto  Inténtalo de nuevo",
  NO_VEHICLES_FOUND: "No se encontraron motocicletas ",
  NO_NOTIFICATION_FOUND: "No se encontró ninguna notificación",
  ENTER_KM_PLACEHOLDER: "Introducir kilómetro",
  KILOMETER_LABEL: "Kilómetro",
  KM_REQUIRED_MSG: "se requiere kilómetro",
  KM_MIN_MAX_ERROR: "El kilómetro no debe ser superior a 0,5 ni inferior a 50.",
  MY_VEHICLE_LABEL: "Mi vehículo",
  COMPANY_VEHICLE_LABEL: "Vehículo de empresa",
  SELECT_VEHICLE_ERROR: "Por favor seleccione un vehículo.",
  ACCOUNT_INACTIVE_ERROR:
    "Su cuenta ha estado inactiva. Por favor contacte al administrador.",

  //-------------------setting -----------------
  LOGOUT: "Cerrar sesión",
  ALERT: "Alerta",
  WANT_TO_DELETE: "¿Estás seguro de que quieres eliminar esta cuenta?",
  CANCEL: "Cancelar",
  OK: "De acuerdo",
  SETTING_TITLE: "Ajustes",
  SETTING_USER_NAME: "Wanda Maximoff",
  USER_EMAIL: "wanda.wilaimson@gmail.com",
  USER_PHONE_NO: "+1 - 7894561230",
  VIEW_DETAILS: "Ver detalles",
  NOTIFICATION_TEXT: "Notificación",
  CHANGE_PIN: "Cambiar PIN",
  ABOUT_US: "Sobre nosotros",
  PRIVACY_POLICY: "Política de privacidad",
  TERMS_CONDITIONS: "Términos y condiciones",
  DELETE_ACCOUNT: "Borrar cuenta",
  LOGOUT_SUCCESSFULLY: "Cerrar sesión exitosamente",
  OLD_PIN: "PIN antiguo",
  NEW_PIN: "Nuevo PIN",
  FORGOT_PIN: "Olvidado Chapa",
  FORGOT_YOUR_PIN: "Olvidaste tu PIN",
  UPDATE_PIN: "Actualizar PIN",
  UPDATE_YOUR_PIN: "Actualiza tu PIN",
  CODE_REQUIRED_MESSAGE: "Por favor Ingresa el código de 6 dígitos",
  ENTER_NEW_PIN: "Introduce un nuevo PIN",
  OLD_PASSWORD_LABEL: "Contraseña anterior",
  NEW_PASSWORD_LABEL: "Nueva contraseña",
  OLD_PASSWORD_PLACEHOLDER_TEXT: "Contraseña antigua",
  NEW_PASSWORD_PLACEHOLDER_TEXT: "Ingresa nueva contraseña",
  OLD_PASSWORD_REQUIRED_MESSAGE: "Por favor Ingresa tu antigua contraseña",
  NEW_PASSWORD_REQUIRED_MESSAGE: "Por favor Ingresa tu nueva contraseña",
  CHANGE_PASSWORD_SUCCUSS: "Cambio de contraseña exitoso",
  NOTIFICATION_DISABLED: "Notificación desactivada exitosamente",
  NOTIFICATION_ENABLED: "Notificación exitosa",
  NOTIFICATION_TITLE: "Notificaciones",
  PIN_UPDATE_SUCCESSFULLY: "Actualización de PIN exitoso",
  PIN_CHANGE_SUCCESSFULLY: "Cambio de PIN exitoso",
  GEO_FENCE_STATUS_CHANGE_SUCCESFULLY: "Actualización de geo-cerca exitoso",
  OFF_TEXT: "Apagada",
  ON_TEXT: "EN",
  UNLOCK_TEXT: "Desbloquear",
  LOCK_TEXT: "Bloquear",
  EMERGENCY_TEXT: "Emergencia",
  CHANGE_LANGUAGE: "Cambiar idioma",
  SELECT_LANGUAGE: "Seleccione el idioma",
  CONTINUE: "Continuar",
  CHANGE_PASSWORD: "Cambiar la contraseña",
  ACCOUNT_DELETE_SUCCUSS: "Cuenta eliminada exitosamente",
  SERIAL_NO_NOT_EXSIT: "El número serial GLock no existe",
  IMAGE_DELETE_SUCCUSS: "Imagen eliminada exitosamente",
  IMAGE_ADDED_SUCESS: "Imágenes cargadas exitosamente",

  //-------------------Job History -----------------
  HISTORY_TITLE: "Historial",
  SOS_TITLE: "Emergencia",
  NEED_HELP: "¿Necesitas ayuda?",
  PRESS_BUTTON: "Presiona el botón",
  MAP_TITLE: "Mapa",
  THEFT: "Robo",
  ACCIDENT: "Accidente",
  ROAD_SIDE_ASSISTANCE: "Asistencia en carretera",

  //---------------Sos modal-------------
  SELECT_VEHICLE: "Seleccionar motocicleta",

  //-------------------Profile  -----------------
  PROFILE_TILTLE: "Perfil",
  EDIT_PROFILE_TEXT: "Editar perfil",
  PERSONAL_INFORMATION: "Informacion personal",
  SECURITY_QUESTION: "Pregunta de seguridad",
  EMERGENCY_CONTACT: "Contacto de emergencia",
  BIRTHDAY: "Fecha de nacimiento :",
  NATIONAL_ID_TEXT: "Cédula :",
  BLOOD_TYPE_TEXT: "Tipo de sangre :",
  SPECIFY_HEALTH_ISSUE: "Problemas de salud :",
  PROFILE_PHONE_NUMBER: "Número de teléfono :",
  PROFILE_HOME_NUMBER: "Número de casa :",
  ADDRESS_TEXT: "Dirección :",
  SECURITY_QUESTION_HEAD: "Pregunta de seguridad :",
  EDIT_QUESTIONS: "Editar preguntas",
  EDIT_EMERGENCY_CONTACT: "Editar contactos de emergencia",
  NAME_TEXT: "Nombre :",
  RELATIONSHIP_TEXT: "Relación :",
  QUESTION: "Pregunta",
  ANSWER: "Respuesta",
  PERSONAL_INFORMATION_TITLE: "Informacion personal",
  UPDATE: "Actualizar",
  PERSONAL_INFO_UPDATE: "Actualización de información personal exitosa",
  SECURITY_QUESTION_UPDATE: "Actualización de pregunta de seguridad exitosa",
  EMERGENCY_CONTACT_UPDATE: "Actualización de contacto de emergencia exitosa",
  PROFILE_CONTACT: "Contacto",
  COMPANY_OPTIONAL_TEXT:
    "Opcional para usuarios o autocompletado para administradores que crean usuarios",
  OTHER_HEALTH_ISSUE: "Otro problema de salud :",

  //-------------------Map-----------------
  PILOT_NAME: "Nombre del conductor",
  PLATE_NUMBER: "Número de placa",
  BIKE_MODEL: "Modelo de la motocicleta",
  BIKE_COLOR: "Color de la motocicleta",
  BIKE_YEAR: "Año de la motocicleta",

  //-------------------Page Not found  -----------------
  OOPS: "Ups!",
  PAGE_404: "404 Pagina no encontrada",
  SORRY_PAGE_NOT_EXIST: "Lo sentimos, la página que estás buscando no existe.",

  // RESET_BTN: "Reiniciar",
  RESET_BTN: "Desactivar estado de alerta",
  RESER_SOS_SUCCESS: "Restablecer el vehículo SOS con éxito",
  SEND_BTN: "Enviar",
  COMPANY_NAME: "Nombre de empresa",
  HISTORY_DETAIL: "Detalle de la historia",
  UNSAVED_CHANGES_CONFIRMATION: "¿Estás segura de que quieres irte?",
  VIEW_ON_MAP: "Ver en el mapa",

  START_DATE: "Fecha de inicio",
  END_DATE: "Fecha final",
  STEALTH: "Sigilo",
  SOS_TYPE: "Tipo de emergencia",
  INVALID_IMG_ID: "ID de imagen no válida",
  IMAGES: "Imágenes",
  NOTIFY: "notificar",
  NOTIFY_SEND: "Notificación enviada exitosamente",
  SOS_IMG_REQUIRED: "Se requiere imagen de sos",
  INVALID_EXTENSION: "La imagen Sos tiene extensiones no válidas.",
  INVALID_SOS_ID: "Identificación de emergencia no válida",
  IMAGE_LIMIT: "La imagen Sos no puede tener más de 10 MB.",
  MAX_IMAGE_LIMIT: "Se pueden cargar un máximo de 10 imágenes en sos.",
  INVALID_FILE_TYPE: "La extensión de la imagen no es válida.",
  LANGUAGE_CHANGE: "Cambio de idioma con éxito",
};
