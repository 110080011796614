import "../../Assests/Styles/Common.css";
import { useTranslation } from "react-i18next";
import styles from "../SignUp/SignUp.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { Container, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import BloodTypeModal from "./BloodTypeModal";
import HealthIssueModal from "./HealthIssueModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import SecurityQuestionModal from "./SecurityQuestionModal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import SecurityModal2 from "./SecurityModal2";
import SecurityModal3 from "./SecurityModal3";
import {
  setSignupData,
  fetchMasterData,
  checkEmailPhone,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import personalInfo from "../../Assests/Images/account_information_ico.svg";
import { CHECK_TYPE } from "../../utils/Constants";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toster";
import { useNavigate } from "react-router-dom";

function SignUpStepSecond({ setSwitchTo, setPreviousValue, previousValue }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [confirmNavigation, setConfirmNavigation] = useState(false);
  const { signupData, switchTo } = useSelector((state) => state.user);
  const [bloodTypeModalOpen, setBloodTypeModalOpen] = useState(false);
  const [healthIssueModalOpen, setHealthIssueModalOpen] = useState(false);
  const [selectedBloodType, setSelectedBloodType] = useState(
    signupData && signupData.blood_data ? signupData.blood_data : ""
  );
  const [selectedHealthIssue, setSelectedHealthIssue] = useState(
    signupData && signupData.selectedHealthIssue
      ? signupData.selectedHealthIssue
      : ""
  );
  const [selectedHealthIssueValue, setSelectedHealthIssueValue] = useState(
    signupData && signupData.selectedHealthIssueValue
      ? signupData.selectedHealthIssueValue
      : ""
  );

  const [otherInputValue, setOtherInputValue] = useState(
    signupData && signupData.otherInputValue ? signupData.otherInputValue : ""
  );

  const [securityQuestion1, setSecurityQuestion1] = useState(
    signupData && signupData.securityQuestion1
      ? signupData.securityQuestion1
      : ""
  );
  const [securityQuestion2, setSecurityQuestion2] = useState(
    signupData && signupData.securityQuestion2
      ? signupData.securityQuestion2
      : ""
  );
  const [securityQuestion3, setSecurityQuestion3] = useState(
    signupData && signupData.securityQuestion3
      ? signupData.securityQuestion3
      : ""
  );
  const [securityQuestionModalOpen, setSecurityQuestionModalOpen] =
    useState(false);
  const [securityQuestionModalOpen2, setSecurityQuestionModalOpen2] =
    useState(false);
  const [securityQuestionModalOpen3, setSecurityQuestionModalOpen3] =
    useState(false);
  const [phoneDialCode, setPhoneDialCode] = useState(
    signupData && signupData.phoneDialCode ? signupData.phoneDialCode : ""
  );
  const [homeDialCode, setHomeDialCode] = useState(
    signupData && signupData.homeDialCode ? signupData.homeDialCode : ""
  );
  const [driverPhoneNo, setDriverPhoneNo] = useState(
    signupData && signupData.driverPhoneNo ? signupData.driverPhoneNo : ""
  );
  const [driverHomeNo, setDriverHomeNo] = useState(
    signupData && signupData.driverHomeNo ? signupData.driverHomeNo : ""
  );
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    signupData && signupData.phoneCountryCode ? signupData.phoneCountryCode : ""
  );
  const [homeCountryCode, setHomeCountryCode] = useState(
    signupData && signupData.homeCountryCode ? signupData.homeCountryCode : ""
  );
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [formatedDate, setFormatedDate] = useState(
    signupData && signupData.dob ? signupData.dob : ""
  );

  //====== useform propety for manage validation==========
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  //======== function for calling masterData api===========
  useEffect(() => {
    const refKey =
      "colours,blood_types,security_questions,health_issues,contact_relation";
    const getMasterDataList = async () => {
      dispatch(fetchMasterData({ refKey: refKey }));
    };
    getMasterDataList();
  }, [switchTo]);

  //========== disable space on key press input password field=======
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //======= function for apply validation in phoneNumber field=========
  const validatePhoneNumber = (value) => {
    if (!value) {
      return `${t("CONTACT_NUMBER_REQUIRED")}`;
    }
    const driverPhoneNo = value.replace(/\D/g, "");
    if (driverPhoneNo.length <= 8) {
      return `${t("PHONE_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  //======= function for apply validation in homeNumber field==========
  const validateHomeNumber = (value) => {
    if (!value) {
      return `${t("HOME_NUMBER_REQUIRED")}`;
    }
    const driverHomeNo = value.replace(/\D/g, "");
    if (driverHomeNo.length <= 8) {
      return `${t("HOME_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  //======= function for manage date and format it as yyyy-mm-dd===========
  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : null;
    setSelectedDate(formattedDate);
    setFormatedDate(formattedDate);
  };

  //======= function for remove extra + in dial code==========
  function removeExtraPlus(inputString) {
    let newStr = "+" + inputString;
    return newStr.replace(/\++/g, "+");
  }

  //======= function for submit form=========
  // const onSubmit = async (formData) => {
  //   let requestData = new FormData();
  //   requestData.append("check_type", CHECK_TYPE.PHONE_NUMBER);
  //   requestData.append("phone_number", driverPhoneNo);
  //   requestData.append("dial_code", removeExtraPlus(phoneDialCode));
  //   const response = await dispatch(checkEmailPhone(requestData));
  //   const responseData = response.payload;
  //   if (
  //     responseData &&
  //     responseData.data.code === STATUS_CODES.PHONE_NUMBER_ALREADY_USE
  //   ) {
  //     Toster(t("PHONE_NUMBER_ALREADY_USE"), "error");
  //     return false;
  //   }
  //   const data = {
  //     ...formData,
  //     dob: formatedDate ? formatedDate : "",
  //     blood_data: selectedBloodType ? selectedBloodType : "",
  //     driverPhoneNo: driverPhoneNo,
  //     driverHomeNo: driverHomeNo,
  //     phoneDialCode: removeExtraPlus(phoneDialCode),
  //     homeDialCode: removeExtraPlus(homeDialCode),
  //     phoneCountryCode: phoneCountryCode,
  //     homeCountryCode: homeCountryCode,
  //     selectedHealthIssue: selectedHealthIssue,
  //     otherInputValue: otherInputValue,
  //     selectedHealthIssueValue: selectedHealthIssueValue,
  //     securityQuestion1: securityQuestion1,
  //     securityQuestion2: securityQuestion2,
  //     securityQuestion3: securityQuestion3,
  //   };
  //   data.dob = dayjs(data.dob).format("YYYY-MM-DD");
  //   let userData = { ...data };
  //   dispatch(setSignupData(userData));
  //   setSwitchTo(2);
  // };

  const onSubmit = async (formData) => {
    const createRequestData = (checkType, formData) => {
      let requestData = new FormData();
      requestData.append("check_type", checkType);
      requestData.append("national_id", formData.nationalId);
      requestData.append("phone_number", driverPhoneNo);
      requestData.append("dial_code", removeExtraPlus(phoneDialCode));
      return requestData;
    };

    try {
      // Check national_id
      let nationalIdRequestData = createRequestData(
        CHECK_TYPE.NATIONAL_ID,
        formData
      );
      let nationalIdResponse = await dispatch(
        checkEmailPhone(nationalIdRequestData)
      );
      let nationalIdResponseData = nationalIdResponse.payload;

      if (
        nationalIdResponseData &&
        nationalIdResponseData.data.code ===
          STATUS_CODES.NATIONAL_ID_NUMBER_EXIST
      ) {
        Toster(t("NATIONAL_ID_NUMBER_EXIST"), "error");
        return false;
      }

      // Check phone_number only if national ID check passed
      let phoneNumberRequestData = createRequestData(
        CHECK_TYPE.PHONE_NUMBER,
        formData
      );
      let phoneNumberResponse = await dispatch(
        checkEmailPhone(phoneNumberRequestData)
      );
      let phoneNumberResponseData = phoneNumberResponse.payload;

      if (
        phoneNumberResponseData &&
        phoneNumberResponseData.data.code ===
          STATUS_CODES.PHONE_NUMBER_ALREADY_USE
      ) {
        Toster(t("PHONE_NUMBER_ALREADY_USE"), "error");
        return false;
      }
      // If both checks passed, proceed with the rest of the form submission
      const data = {
        ...formData,
        dob: formatedDate ? formatedDate : "",
        blood_data: selectedBloodType ? selectedBloodType : "",
        driverPhoneNo: driverPhoneNo,
        driverHomeNo: driverHomeNo,
        phoneDialCode: removeExtraPlus(phoneDialCode),
        homeDialCode: removeExtraPlus(homeDialCode),
        phoneCountryCode: phoneCountryCode,
        homeCountryCode: homeCountryCode,
        selectedHealthIssue: selectedHealthIssue,
        otherInputValue: otherInputValue,
        selectedHealthIssueValue: selectedHealthIssueValue,
        securityQuestion1: securityQuestion1,
        securityQuestion2: securityQuestion2,
        securityQuestion3: securityQuestion3,
      };

      data.dob = dayjs(data.dob).format("YYYY-MM-DD");

      let userData = { ...data };
      dispatch(setSignupData(userData));
      setSwitchTo(2);
    } catch (error) {
      console.error("Error checking national ID or phone number:", error);
      Toster(t("SOMETHING_WENT_WRONG"), "error");
    }
  };

  //============= Function to handle user's choice from the alert modal=============
  const handleAlertModalChoice = (choice) => {
    if (choice === "ok") {
      const formattedDateOfBirth = dayjs(getValues("dateOfBirth")).format(
        "YYYY-MM-DD"
      );

      const formData = {
        dob: formattedDateOfBirth,
        dateOfBirth: formattedDateOfBirth,
        dateOfBirth: getValues("dateOfBirth"),
        address: getValues("address"),
        nationalId: getValues("nationalId"),
        bloodType: getValues("bloodType"),
        phoneDialCode: phoneDialCode,
        phoneCountryCode: phoneCountryCode,
        driverPhoneNo: getValues("phone"),
        homeDialCode: homeDialCode,
        homeCountryCode: homeCountryCode,
        driverHomeNo: getValues("home"),
        selectedHealthIssueValue: getValues("healthIssue"),
        company: getValues("company"),
        securityQuestionFirst: getValues("securityQuestionFirst"),
        securityAnswer1: getValues("securityAnswer1"),
        securityQuestion1: securityQuestion1,
        securityQuestionsecond: getValues("securityQuestionsecond"),
        securityQuestion2: securityQuestion2,
        securityAnswer2: getValues("securityAnswer2"),
        securityQuestionThird: getValues("securityQuestionThird"),
        securityQuestion3: securityQuestion3,
        securityAnswer3: getValues("securityAnswer3"),
        selectedHealthIssueValue: selectedHealthIssueValue,
        selectedHealthIssue: selectedHealthIssue,
        otherInputValue: otherInputValue,
        selectedDate: selectedDate,
        driverPhoneNo: driverPhoneNo,
        driverHomeNo: driverHomeNo,
        selectedDate: selectedDate,
        formatedDate: formatedDate,
        selectedBloodType: selectedBloodType,
        blood_data: selectedBloodType ? selectedBloodType : "",
      };
      dispatch(setSignupData(formData));
      setSwitchTo(0);
      setPreviousValue(true);
    }
    // Close the alert modal in both cases
    setShowAlertModal(false);
  };

  // Add confirmation for navigation away from the form
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    const handleBackButton = (event) => {
      setShowAlertModal(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [showAlertModal]);

  //========= function for show form data prefilled=========
  useEffect(() => {
    if (previousValue == false) {
    } else {
      if (signupData && Object.keys(signupData).length > 0) {
        if (signupData.dateOfBirth) {
          setValue(
            "dateOfBirth",
            signupData.dateOfBirth ? new Date(signupData.dateOfBirth) : ""
          );
        }
        setValue("address", signupData.address ? signupData.address : "");
        setValue(
          "nationalId",
          signupData.nationalId ? signupData.nationalId : ""
        );
        setValue("bloodType", signupData.bloodType ? signupData.bloodType : "");
        setValue(
          "healthIssue",
          signupData.selectedHealthIssueValue
            ? signupData.selectedHealthIssueValue
            : ""
        );

        // setOtherInputValue(signupData.otherIssue);
        setOtherInputValue(signupData.otherInputValue);
        setValue("otherIssue", signupData.otherInputValue);

        setValue(
          "phone",
          signupData.driverPhoneNo ? signupData.driverPhoneNo : ""
        );
        setValue(
          "home",
          signupData.driverHomeNo ? signupData.driverHomeNo : ""
        );
        setValue("company", signupData.company);
        setValue(
          "securityQuestionFirst",
          signupData.securityQuestionFirst
            ? signupData.securityQuestionFirst
            : ""
        );
        setValue(
          "securityQuestionsecond",
          signupData.securityQuestionsecond
            ? signupData.securityQuestionsecond
            : ""
        );
        setValue("securityQuestionThird", signupData.securityQuestionThird);
        setValue(
          "securityAnswer1",
          signupData.securityAnswer1 ? signupData.securityAnswer1 : ""
        );
        setValue(
          "securityAnswer2",
          signupData.securityAnswer2 ? signupData.securityAnswer2 : ""
        );
        setValue(
          "securityAnswer3",
          signupData.securityAnswer3 ? signupData.securityAnswer3 : ""
        );
        setDriverPhoneNo(
          signupData.driverPhoneNo ? signupData.driverPhoneNo : ""
        );
        setDriverHomeNo(signupData.driverHomeNo ? signupData.driverHomeNo : "");
        setSelectedHealthIssueValue(
          signupData.selectedHealthIssueValue
            ? signupData.selectedHealthIssueValue
            : ""
        );
        setSelectedDate(signupData.selectedDate ? signupData.selectedDate : "");
      }
    }

    clearErrors("dateOfBirth");
    clearErrors("address");
    clearErrors("nationalId");
    clearErrors("bloodType");
    clearErrors("healthIssue");
    clearErrors("phone");
    clearErrors("home");
    clearErrors("company");
  }, [switchTo]);

  return (
    <div className={styles.signpWrapper}>
      <div className={styles.tittle}>
        <div className={styles.backIcon}>
          <IoMdArrowRoundBack onClick={() => setShowAlertModal(true)} />
        </div>
        <Modal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className={styles.goBack}>{t("BACK_ALERT_TEXT")}</div>
          </Modal.Body>
          <div className={styles.Btns}>
            <button
              className={styles.cancelBtn}
              onClick={() => handleAlertModalChoice("cancel")}
            >
              {t("CANCEL")}
            </button>
            <button
              className={styles.deleteBtn}
              onClick={() => handleAlertModalChoice("ok")}
            >
              {t("OK")}
            </button>
          </div>
        </Modal>
        <div className={styles.signUpTitle}>
          <p>
            {t("PERSONAL_INFORMATION_TITTLE")}
            <img src={personalInfo}></img>
          </p>
        </div>
        <p className={styles.stepCount}>
          2 <span>/4</span>
        </p>
      </div>

      <Container>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <div className="dateOfBirth">
                <Controller
                  control={control}
                  name="dateOfBirth"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDatePicker"]}>
                        <DemoItem>
                          <MobileDatePicker
                            label={t("BIRTHDAY_LABEL")}
                            selected={selectedDate}
                            views={["year", "month", "day"]}
                            defaultValue={
                              signupData.dateOfBirth
                                ? dayjs(signupData.dateOfBirth)
                                : dayjs().set("year", 1999) // Set default year to 1999
                            }
                            onChange={(newValue) => {
                              onChange(newValue);
                              handleDateChange(newValue);
                              clearErrors("dateOfBirth");
                            }}
                            disableFuture={true}
                            maxDate={dayjs().subtract(18, "year")} // User must be at least 18 years old
                            minDate={dayjs(new Date("1901-01-01"))}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                />

                <div className="errorMsg">
                  {errors.dateOfBirth && <p>{t("DATE_OF_BIRTH_REQUIRED")}</p>}
                </div>
              </div>
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("ADDRESS_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("ADDRESS_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("address", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("ADDRESS_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 5,
                    message: `${t("ADDRESS_VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 250,
                    message: `${t("ADDRESS_VALIDATION_MAXLENGTH")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.address && (
                <span className="errorMsg">{errors.address.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("NATIONAL_ID_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("NATIONAL_ID_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("nationalId", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("NATIONAL_ID_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 5,
                    message: `${t("VALIDATION_MINLENGTH_NATIONAL_ID")}`,
                  },
                  maxLength: {
                    value: 25,
                    message: `${t("VALIDATION_MAXLENGTH_NATIONAL_ID")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.nationalId && (
                <span className="errorMsg">{errors.nationalId.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("BLOOD_TYPE_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("BLOOD_TYPE_PLACEHOLDER_TEXT")}`}
                type="text"
                readOnly
                onClick={() => setBloodTypeModalOpen(true)}
                {...register("bloodType", {
                  required: {
                    value: true,
                    message: `${t("BLOOD_TYPE_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setBloodTypeModalOpen(true);
                }}
              />

              {errors.bloodType && (
                <span className="errorMsg">{errors.bloodType.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("HEALTH_ISSUE_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("HEALTH_ISSUE_PLACEHOLDER_TEXT")}`}
                type="text"
                readOnly
                value={selectedHealthIssueValue}
                onClick={() => setHealthIssueModalOpen(true)}
                {...register("healthIssue", {
                  required: {
                    value: true,
                    message: `${t("HEALTH_ISSUE_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setHealthIssueModalOpen(true);
                }}
              />

              {errors.healthIssue && (
                <span className="errorMsg">{errors.healthIssue.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <div className="phone-input-container">
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <PhoneInput
                      specialLabel={t("PHONE_INPUT_LABEL")}
                      placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      onlyCountries={["do"]}
                      inputProps={{ maxLength: 15 }}
                      country={"do"}
                      value={
                        (phoneDialCode && phoneDialCode.toString()) +
                        (driverPhoneNo && driverPhoneNo.toString())
                      }
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setPhoneCountryCode(country.countryCode);
                        setPhoneDialCode(dialCode); // Add "+" sign here
                        setDriverPhoneNo(phone);
                        field.onChange(value); // Update the form value
                        clearErrors("");
                      }}
                      inputExtraProps={{
                        style: {
                          position: "relative", // Ensure relative positioning for the container
                        },
                      }}
                      dropdownClass={styles.customDropdown} // Apply custom dropdown styles
                    />
                  )}
                  rules={{ validate: validatePhoneNumber }}
                />
                {errors.phone && (
                  <span className="errorMsg">{errors.phone.message}</span>
                )}
              </div>
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <div className="phone-input-container">
                <Controller
                  control={control}
                  name="home"
                  render={({ field }) => (
                    <PhoneInput
                      specialLabel={t("HOME_INPUT_LABEL")}
                      placeholder={`${t("HOME_NUMBER_PLACEHOLDER")}`}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      onlyCountries={["do"]}
                      inputProps={{ maxLength: 15 }}
                      country={"do"}
                      value={
                        (homeDialCode && homeDialCode.toString()) +
                        (driverHomeNo && driverHomeNo.toString())
                      }
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setHomeCountryCode(country.countryCode);
                        setHomeDialCode(dialCode);
                        setDriverHomeNo(phone);
                        field.onChange(value);
                        clearErrors("");
                      }}
                      inputExtraProps={{
                        style: {
                          position: "relative", // Ensure relative positioning for the container
                        },
                      }}
                      dropdownClass={styles.customDropdown} // Apply custom dropdown styles
                    />
                  )}
                  rules={{ validate: validateHomeNumber }}
                />
                {errors.home && (
                  <span className="errorMsg">{errors.home.message}</span>
                )}
              </div>
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("COMPANY_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("COMPANY_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("company", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.company && (
                <span className="errorMsg">{errors.company.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_QUESTION_LABEL1")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("SECURITY_PLACEHOLDER_TEXT")}`}
                type="text"
                readOnly
                onClick={() => setSecurityQuestionModalOpen(true)}
                onChange={(e) => {
                  setSecurityQuestion1(""); // Clear the selected security question
                  clearErrors("securityQuestionFirst"); // Clear the error for the field
                }}
                {...register("securityQuestionFirst", {
                  required: {
                    value: true,
                    message: `${t("SECURITY_QUESTION_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setSecurityQuestionModalOpen(true);
                }}
              />

              {errors.securityQuestionFirst && (
                <span className="errorMsg">
                  {errors.securityQuestionFirst.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_ANSWER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("ANSWER_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("securityAnswer1", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("ANSWER_REQUIRED_MESSAGE")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.securityAnswer1 && (
                <span className="errorMsg">
                  {errors.securityAnswer1.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_QUESTION_LABEL2")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("SECURITY_PLACEHOLDER_TEXT")}`}
                type="text"
                onChange={(e) => {
                  setSecurityQuestion2(""); // Clear the selected security question
                  clearErrors("securityQuestionsecond"); // Clear the error for the field
                }}
                readOnly
                onClick={() => setSecurityQuestionModalOpen2(true)}
                {...register("securityQuestionsecond", {
                  required: {
                    value: true,
                    message: `${t("SECURITY_QUESTION_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setSecurityQuestionModalOpen2(true);
                }}
              />
              {errors.securityQuestionsecond && (
                <span className="errorMsg">
                  {errors.securityQuestionsecond.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_ANSWER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("ANSWER_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("securityAnswer2", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("ANSWER_REQUIRED_MESSAGE")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.securityAnswer2 && (
                <span className="errorMsg">
                  {errors.securityAnswer3.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_QUESTION_LABEL3")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("SECURITY_PLACEHOLDER_TEXT")}`}
                type="text"
                onChange={(e) => {
                  setSecurityQuestion3(""); // Clear the selected security question
                  clearErrors("securityQuestionThird"); // Clear the error for the field
                }}
                readOnly
                onClick={() => setSecurityQuestionModalOpen3(true)}
                {...register("securityQuestionThird", {
                  required: {
                    value: true,
                    message: `${t("SECURITY_QUESTION_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setSecurityQuestionModalOpen3(true);
                }}
              />

              {errors.securityQuestionThird && (
                <span className="errorMsg">
                  {errors.securityQuestionThird.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SECURITY_ANSWER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("ANSWER_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("securityAnswer3", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("ANSWER_REQUIRED_MESSAGE")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.securityAnswer3 && (
                <span className="errorMsg">
                  {errors.securityAnswer3.message}
                </span>
              )}
            </Form.Group>
            <div className={styles.nextBtn}>
              <button type="submit">{t("NEXT_BUTTON")}</button>
            </div>
          </Form>

          <BloodTypeModal
            bloodTypeModalOpen={bloodTypeModalOpen}
            setBloodTypeModalOpen={setBloodTypeModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            setSelectedBloodType={setSelectedBloodType}
            selectedBloodType={selectedBloodType}
          />
          <HealthIssueModal
            healthIssueModalOpen={healthIssueModalOpen}
            setHealthIssueModalOpen={setHealthIssueModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            selectedHealthIssue={selectedHealthIssue}
            setSelectedHealthIssue={setSelectedHealthIssue}
            selectedHealthIssueValue={selectedHealthIssueValue}
            setSelectedHealthIssueValue={setSelectedHealthIssueValue}
            setOtherInputValue={setOtherInputValue}
            otherInputValue={otherInputValue}
            profileData={otherInputValue}
          />

          <SecurityQuestionModal
            securityQuestionModalOpen={securityQuestionModalOpen}
            setSecurityQuestionModalOpen={setSecurityQuestionModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            securityQuestion1={securityQuestion1}
            setSecurityQuestion1={setSecurityQuestion1}
            securityQuestion2={securityQuestion2}
            securityQuestion3={securityQuestion3}
          />

          <SecurityModal2
            securityQuestionModalOpen2={securityQuestionModalOpen2}
            setSecurityQuestionModalOpen2={setSecurityQuestionModalOpen2}
            setValue={setValue}
            clearErrors={clearErrors}
            securityQuestion2={securityQuestion2}
            setSecurityQuestion2={setSecurityQuestion2}
            securityQuestion1={securityQuestion1}
            securityQuestion3={securityQuestion3}
          />
          <SecurityModal3
            securityQuestionModalOpen3={securityQuestionModalOpen3}
            setSecurityQuestionModalOpen3={setSecurityQuestionModalOpen3}
            setValue={setValue}
            clearErrors={clearErrors}
            securityQuestion3={securityQuestion3}
            setSecurityQuestion3={setSecurityQuestion3}
            securityQuestion1={securityQuestion1}
            securityQuestion2={securityQuestion2}
          />
        </div>
      </Container>
    </div>
  );
}

export default SignUpStepSecond;