import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  switchTo: 0,
  previousValue: false,
  signupData: {},
  masterList: {},
  vehicleListData: {},
  jobHistoryData: {},
  notificationData: [],
  totalCount: 0,
  unreadNotification: "",
  loginType: "",
  profileData: {},
  mapData: {},
  vehicleData: {},
  sosData: {},
  currentUser: {},
  user_language:"",
  socialUserData: {},
  currentPage: 1,
  serialNumber: {},
  activeTab: "Personal Information",
  securityQuestionExit: false,
  emergencyContactExist: false,
  loadMore: false,
  isLoading: false,
  userToken: null,
  success: false,
  message: false,
  error: null,
};

// Thunk for user login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for check userSignup
export const signUp = createAsyncThunk(
  "user/signUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.signUp(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for check email and phone avalibilty
export const checkEmailPhone = createAsyncThunk(
  "user/checkEmailAvalibity",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.checkExistence(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for masterData list
export const fetchMasterData = createAsyncThunk(
  "masterList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.masterDataList(data.refKey);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for Vehicle list
export const fetchVehicleList = createAsyncThunk(
  "vehicleList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.vehicleList(
        data.userToken,
        data.page_no,
        data.search,
        data.filterByVehicle
      );
      return {
        response: response,
        onLoadMore: data.onLoadMore,
        page_no: data.page_no,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for add new vehicle
export const addNewVehicle = createAsyncThunk(
  "user/addNewVehicle",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.addVehicle(
        data.userToken,
        data.requestData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for sos history
export const jobHistory = createAsyncThunk(
  "jobHistory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getJobList(data.userToken, data.page_no);
      // return response;
      return {
        response: response,
        onLoadMore: data.onLoadMore,
        page_no: data.page_no,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get sos service
export const getSos = createAsyncThunk(
  "user/getSos",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.sosService(
        data.userToken,
        data.requestData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for logOut
export const logOutApp = createAsyncThunk(
  "logOut",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.userLogOut(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for forgot Pin
export const appForGotPin = createAsyncThunk(
  "forGotPin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.forgotPin(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for my profile
export const appMyProfile = createAsyncThunk(
  "appMyProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.myProfile(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for update  profile
export const editPersonalInformation = createAsyncThunk(
  "editPersonalInfo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.editPersonalInfo(
        data.userToken,
        data.requestData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get content
export const fetchContent = createAsyncThunk(
  "fetchContent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getContent(data.userToken, data.pageName);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for map
export const getMapData = createAsyncThunk(
  "getMapData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getMap(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for vehicle details list
export const fetchVehicleDetails = createAsyncThunk(
  "fetchVehicleDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getVehicleDetail(
        data.userToken,
        data.vehicleId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for notification list
export const fetchNotificationList = createAsyncThunk(
  "fetchNotificationList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getNotificationList(
        data.userToken,
        data.page_no
      );
      return {
        response: response,
        onLoadMore: data.onLoadMore,
        page_no: data.page_no,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for sos details list
export const fetchSosDetails = createAsyncThunk(
  "fetchSosDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getSOSDetail(data.userToken, data.sosId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for get sos service
export const getSosImg = createAsyncThunk(
  "user/getSosImg",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.postSosImage(
        data.userToken,
        data.requestData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for send vehicle move nofity
export const sendVehicleNotification = createAsyncThunk(
  "user/sendVehicleNotification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.sendVehicleNotify(
        data.userToken,
        data.requestData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    //function for user logOut
    userLogoutClear: (state) => {
      state.currentUser = {};
      state.vehicleListData = {};
      state.jobHistoryData = {};
      state.profileData = {};
      state.mapData = {};
      state.masterList = {};
      state.vehicleData = {};
      state.sosData = {};
      state.isLoading = false;
      state.userToken = null;
    },

    //function for switch signup form nextStep
    setSwitchTo: (state, action) => {
      state.switchTo = action.payload;
    },

    //function for back privoius step in signup form
    setPreviousValue: (state, action) => {
      state.previousValue = action.payload;
    },

    //function for store all signup step data in redux
    setSignupData: (state, action) => {
      const { payload } = action;
      state.signupData = { ...state.signupData, ...payload };
    },

    //function for clear signup Redux data
    clearSignupData: (state, action) => {
      state.signupData = {};
    },

    //function for reset step form state
    resetSwitchTo: (state) => {
      state.switchTo = 0;
    },

    //function for update vehicle Lock unlock status
    updateLockUnlockStatus: (state, action) => {
      state.vehicleListData = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setLoadMore: (state, action) => {
      state.loadMore = action.payload;
    },

    setSocialUserData: (state, action) => {
      state.socialUserData = action.payload;
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    setSecurityQuestionExist: (state, action) => {
      state.currentUser.security_question_exist = action.payload;
    },

    setEmergencyContactExist: (state, action) => {
      state.currentUser.emergency_contact_exist = action.payload;
    },

    clearProfileData: (state) => {
      state.profileData = {};
    },

    resetActiveTab: (state) => {
      state.activeTab = "Personal Information";
    },
  },
  extraReducers: (builder) => {
    //======= user login======
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response.data.token;
        state.loginType = response.data.signup_type;
        // state.currentUser = response.data; //
        state.user_language=response.data.locale 
        state.success = true;
      } else {
        state.userToken = null;
        state.user_language = ""; //
        state.success = false;
      }
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //======= builder for masterData====
    builder.addCase(fetchMasterData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMasterData.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        state.masterList = response && response.data;
        state.success = true;
      } else {
        state.success = false;
        state.masterList = {};
      }
    });

    builder.addCase(fetchMasterData.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ========final signup============
    builder.addCase(signUp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response.code === STATUS_CODES.SUCCESS) {
        state.currentUser = response.data;
        state.userToken = response.data.token;
        state.loginType = response.data.signup_type;
      }
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //=========== Check email existence============
    builder.addCase(checkEmailPhone.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(checkEmailPhone.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(checkEmailPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
      state.error = action.error.message;
    });

    //===== builder for vehicle List============
    builder.addCase(fetchVehicleList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchVehicleList.fulfilled, (state, action) => {
      state.isLoading = false;
      const { response, onLoadMore, page_no } = action.payload;
      if (
        onLoadMore == true &&
        response &&
        response.code == STATUS_CODES.SUCCESS
      ) {
        if (page_no === state.currentPage) {
          state.vehicleListData = [...state.vehicleListData];
        } else {
          state.vehicleListData = [
            ...state.vehicleListData,
            ...response.data.vehicleList,
          ];
        }

        state.loadMore = response.data.loadMore;
        state.success = true;
      } else if (
        onLoadMore !== true &&
        response &&
        response.code == STATUS_CODES.SUCCESS
      ) {
        state.vehicleListData = response && response.data.vehicleList;
        state.loadMore = response.data.loadMore;
      } else {
        state.vehicleListData = {};
      }
    });

    builder.addCase(fetchVehicleList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // =======add new vehicle===========
    builder.addCase(addNewVehicle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addNewVehicle.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addNewVehicle.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //========= builder for job history===========
    builder.addCase(jobHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(jobHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      const { response, onLoadMore, page_no } = action.payload;
      if (
        onLoadMore == true &&
        response &&
        response.code == STATUS_CODES.SUCCESS
      ) {
        if (page_no === state.currentPage) {
          state.jobHistoryData = [...state.jobHistoryData];
        } else {
          state.jobHistoryData = [
            ...state.jobHistoryData,
            ...response.data.historyData,
          ];
        }

        state.loadMore = response.data.loadMore;
        state.success = true;
      } else if (
        onLoadMore !== true &&
        response &&
        response.code == STATUS_CODES.SUCCESS
      ) {
        state.jobHistoryData = response && response.data.historyData;
        state.loadMore = response.data.loadMore;
      } else {
        state.jobHistoryData = {};
      }
    });

    builder.addCase(jobHistory.rejected, (state, action) => {
      state.isLoading = false;
    });

    //========= builder for app logOut===========
    builder.addCase(logOutApp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logOutApp.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        state.currentUser = {};
        state.userToken = null;
        state.user_language=""
      }
    });

    builder.addCase(logOutApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    //=======builder for get sos============
    builder.addCase(getSos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSos.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSos.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //============= builder for app forgot pin==========
    builder.addCase(appForGotPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(appForGotPin.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(appForGotPin.rejected, (state, action) => {
      state.isLoading = false;
    });

    //=========== builder for my profile===========
    builder.addCase(appMyProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(appMyProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        state.profileData = response && response.data;
        state.success = true;
      }
    });

    builder.addCase(appMyProfile.rejected, (state, action) => {
      state.isLoading = false;
    });

    //========= builder for edit personal info==========
    builder.addCase(editPersonalInformation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editPersonalInformation.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        state.success = true;
      }
    });

    builder.addCase(editPersonalInformation.rejected, (state, action) => {
      state.isLoading = false;
    });

    //--- builder for get content-------------
    builder.addCase(fetchContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchContent.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        state.success = true;
      } else {
        state.success = false;
      }
    });

    builder.addCase(fetchContent.rejected, (state, action) => {
      state.isLoading = false;
    });

    //========== builder for get map data===========
    builder.addCase(getMapData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMapData.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        state.mapData = response && response.data;
        state.success = true;
      }
    });

    builder.addCase(getMapData.rejected, (state, action) => {
      state.isLoading = false;
    });

    //======= builder for vehicle details========
    builder.addCase(fetchVehicleDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchVehicleDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        state.vehicleData = response && response.data;
        state.success = true;
      } else {
        state.success = false;
        state.vehicleData = {};
      }
    });

    builder.addCase(fetchVehicleDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    //========== builder for notification list============
    builder.addCase(fetchNotificationList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNotificationList.fulfilled, (state, action) => {
      state.isLoading = false;
      const { response, onLoadMore, page_no } = action.payload;
      if (
        onLoadMore == true &&
        response &&
        response.code == STATUS_CODES.SUCCESS
      ) {
        if (page_no === state.currentPage) {
          state.notificationData = [...state.notificationData];
        } else {
          state.notificationData = [
            ...state.notificationData,
            ...response.data.notification_list,
          ];
        }

        state.loadMore = response.data.loadMore;
        state.success = true;
        state.totalCount = response.data.totalCount;
        //UNREAD COUNT
        state.unreadNotification = response.data.totalUnreadcount;
      } else if (
        onLoadMore !== true &&
        response &&
        response.code == STATUS_CODES.SUCCESS
      ) {
        state.notificationData = response && response.data.notification_list;
        state.loadMore = response.data.loadMore;
        state.success = true;
      } else {
        state.success = false;
        state.notificationData = [];
        state.totalCount = 0;
      }
    });

    builder.addCase(fetchNotificationList.rejected, (state, action) => {
      state.isLoading = false;
    });

    //======= builder for sos details========
    builder.addCase(fetchSosDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSosDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      const response = action.payload;
      if (response && response.code == STATUS_CODES.SUCCESS) {
        state.sosData = response && response.data;
        state.success = true;
      } else {
        state.success = false;
        state.sosData = {};
      }
    });

    builder.addCase(fetchSosDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    //=======builder for get sos Image============
    builder.addCase(getSosImg.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSosImg.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSosImg.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // ======= vehicle move notify===========
    builder.addCase(sendVehicleNotification.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendVehicleNotification.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(sendVehicleNotification.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default userSlice.reducer;
export const {
  setSwitchTo,
  setPreviousValue,
  signupData,
  clearForm,
  setSignupData,
  userLogoutClear,
  clearSignupData,
  resetSwitchTo,
  setCurrentPage,
  setSocialUserData,
  updateLockUnlockStatus,
  updateProfileData,
  setActiveTab,
  setSecurityQuestionExist,
  setEmergencyContactExist,
  clearProfileData,
  resetActiveTab,
} = userSlice.actions;
