import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import QrScanner from "qr-scanner";
import "qr-scanner/qr-scanner.min.js";
import { LuFlashlight } from "react-icons/lu";

const CameraModal = ({ show, handleClose, setValue }) => {
  const videoRef = useRef(null);
  const [scanner, setScanner] = useState(null);
  const [flashlightOn, setFlashlightOn] = useState(false);
  const [scannedData, setScannedData] = useState(null);

  useEffect(() => {
    if (show) {
      openCamera();
    }
    return () => {
      if (scanner) {
        scanner.stop();
      }
    };
  }, [show]);

  useEffect(() => {
    if (scannedData) {
      // If data is scanned, close modal after 3 seconds
      const timeout = setTimeout(() => {
        handleClose();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [scannedData, handleClose]);

  const openCamera = async () => {
    setValue("serialnumber", "");
    setScannedData(""); 
    try {
      const qrScanner = new QrScanner(videoRef.current, (result) => {
        setValue("serialnumber", result);
        setScannedData(result); 
      });
      setScanner(qrScanner);
      if (flashlightOn) {
        qrScanner.toggleFlash();
      }
      qrScanner.start();
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const toggleFlashlight = () => {
    if (scanner) {
      scanner.toggleFlash();
      setFlashlightOn(!flashlightOn);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} style={{ opacity: "0.5" }}>
      <div className="CameraModal">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "white", width: "100%" }}>
            Scan QR Code
          </Modal.Title>
          <div>
            <Button
              onClick={toggleFlashlight}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              {flashlightOn ? (
                <LuFlashlight
                  size={40}
                  style={{
                    background: "white",
                    color: "black",
                    borderRadius: "30px",
                    padding: "8px",
                  }}
                />
              ) : (
                <LuFlashlight
                  size={40}
                  style={{
                    background: "transparent",
                    color: "white",
                    borderRadius: "0px",
                    padding: "8px",
                  }}
                />
              )}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="ScannerCamera">
            <video
              ref={videoRef}
              autoPlay={true}
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "300px",
                borderRadius: "6px",
                background: `
              linear-gradient(to right, orange 5px, transparent 5px) 0 0,
              linear-gradient(to right, blue 5px, transparent 4px) 0 100%,
              linear-gradient(to left, yellow 4px, transparent 4px) 100% 0,
              linear-gradient(to left, green 4px, transparent 4px) 100% 100%,
              linear-gradient(to bottom, orange 4px, transparent 4px) 0 0,
              linear-gradient(to bottom, yellow 4px, transparent 4px) 100% 0,
              linear-gradient(to top, blue 4px, transparent 4px) 0 100%,
              linear-gradient(to top, green 4px, transparent 4px) 100% 100%`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "30px 30px",
                padding: "10px",
              }}
            ></video>
            {scannedData && (
              <p
                style={{ marginTop: "10px", color: "white" }}
              >{`Scanned Data: ${scannedData}`}</p>
            )}
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default CameraModal;
