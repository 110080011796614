import React from "react";
import i18next from "i18next";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../../Context/LanguageContext";
import LanguageDropdown from "./LanguageDropdown";
import moment from "moment";

const OptionButton = () => {
  const { selectedLanguage = "es", changeLanguage } =
    useContext(LanguageContext);

  useEffect(() => {
    if (selectedLanguage) {
      i18next.changeLanguage(selectedLanguage);
      moment.locale(selectedLanguage);
    }
  }, [selectedLanguage]);

  const handleClick = (e) => {
    changeLanguage(e);
  };

  return (
    <div>
      <LanguageDropdown
        onChange={(e) => handleClick(e)}
        value={selectedLanguage}
      />
    </div>
  );
};

export default OptionButton;
