import { IoMdArrowRoundBack } from "react-icons/io";
import styles from "../Profile/Profile.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { IoMdArrowDropdown } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import BloodTypeModal from "../../AuthComponents/SignUp/BloodTypeModal";
import HealthIssueModal from "../../AuthComponents/SignUp/HealthIssueModal";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import {
  appMyProfile,
  editPersonalInformation,
  fetchMasterData,
  setActiveTab,
  userLogoutClear,
} from "../../store/slices/userSlice";
import Loader from "../../utils/Loader/Loader";
import { BsPersonSquare } from "react-icons/bs";

function EditPersonalInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken, profileData } = useSelector((state) => state.user);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [formatedDate, setFormatedDate] = useState("");
  const [bloodTypeModalOpen, setBloodTypeModalOpen] = useState(false);
  const [healthIssueModalOpen, setHealthIssueModalOpen] = useState(false);
  const [selectedBloodType, setSelectedBloodType] = useState(
    profileData && {
      id: profileData.blood_type_id,
      value_name: profileData.blood_type,
    }
  );

  const [selectedHealthIssue, setSelectedHealthIssue] = useState([]);
  const [selectedHealthIssueValue, setSelectedHealthIssueValue] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState();
  const [homeDialCode, setHomeDialCode] = useState();
  const [phoneCountryCode, setPhoneCountryCode] = useState("");
  const [homeCountryCode, setHomeCountryCode] = useState("");
  const [otherInputValue, setOtherInputValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [homeNumber, setHomeNumber] = useState("");
  const [loading, setLoading] = useState(true);

  //======= function for apply validation using useForm========
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  //============ disable space on key press input password field==========
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // ==========function for calling masterdata api =========
  const getMasterDataList = async () => {
    setLoading(false);
    const refKey = "blood_types,health_issues";
    const healthIsuueSelected = profileData?.health_issue_arr;
    const id_arr = profileData?.health_issue_id;
    dispatch(fetchMasterData({ refKey: refKey })).then((res) => {
      var blood_group = res.payload.data.blood_types;
      var health_issue_group = res?.payload?.data.health_issues;
      const bloodExists = blood_group.some(
        (item) => item.id === profileData.blood_type_id
      );
      if (bloodExists) {
        setValue("bloodType", profileData.blood_type);
      }
      const filteredList_health_name = healthIsuueSelected?.filter((item) =>
        health_issue_group?.some((obj) => obj.value_name === item)
      );
      const filteredList_health_id = id_arr?.filter((item) =>
        health_issue_group?.some((obj) => obj.id === item)
      );
      setSelectedHealthIssueValue(filteredList_health_name);
      setValue("healthIssue", filteredList_health_name);
      setSelectedHealthIssue(filteredList_health_id);
    });
  };

  //========== function for calling masterData api=========
  useEffect(() => {
    getMasterDataList();
  }, []);

  // =======function for manage date and format it as yyyy-mm-dd=========
  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : null;
    setSelectedDate(formattedDate);
    setFormatedDate(formattedDate);
  };

  //======== function for apply validation in phoneNumber field=========
  const validatePhoneNumber = (value) => {
    if (!value) {
      return `${t("CONTACT_NUMBER_REQUIRED")}`;
    }
    const phoneNumber = value.replace(/\D/g, ''); 
    if (phoneNumber.length <= 8 ) {
      return `${t("PHONE_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };
  

  //======= function for apply validation in homeNumber field==========
  const validateHomeNumber = (value) => {
    if (!value) {
      return `${t("HOME_NUMBER_REQUIRED")}`;
    }
    const homeNumber = value.replace(/\D/g, ''); 
    if (homeNumber.length <= 8 ) {
      return `${t("HOME_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  //======== function for remove extra + in dial code===========
  function removeExtraPlus(inputString) {
    let newStr = "+" + inputString;
    return newStr.replace(/\++/g, "+");
  }

  //========function for edit personal info api=========
  const onSubmit = async (formdata) => {
    setLoading(true);
    let requestData = new FormData();
    requestData.append("d_name", formdata.name);
    requestData.append("d_dial_code", removeExtraPlus(phoneDialCode));
    requestData.append("d_phone_country_code", phoneCountryCode);
    requestData.append("d_phone_num", phoneNumber);
    requestData.append("d_home_dial_code", removeExtraPlus(homeDialCode));
    requestData.append("d_home_country_code", homeCountryCode);
    requestData.append("d_home_num", homeNumber);
    requestData.append("d_address", formdata.address);
    requestData.append("d_nat_id", formdata.nationalId);
    requestData.append("d_birthday", formatedDate);
    requestData.append("d_blood_id", selectedBloodType.id);
    requestData.append("d_health_issue_id", selectedHealthIssue);
    requestData.append("d_other_health_issue", otherInputValue ? otherInputValue : "");

    requestData.append("d_company_nm", formdata.company);
    dispatch(
      editPersonalInformation({
        userToken: userToken,
        requestData: requestData,
      })
    ).then((response) => {
      setLoading(false);
      const responsejson = response.payload;
      const ResponseCode = STATUS_MSG[responsejson && responsejson.data.code];

      if (
        responsejson &&
        responsejson.data &&
        responsejson.code === STATUS_CODES.SUCCESS
      ) {
        Toster(t("PERSONAL_INFO_UPDATE"), "success");
        dispatch(appMyProfile(userToken));
        navigate("/personal-information");
        dispatch(setActiveTab("Personal Information"));
      } else if (
        responsejson &&
        responsejson.data &&
        responsejson.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else if (responsejson?.data?.code == STATUS_CODES.VALIDATION_ERROR) {
        Toster(responsejson.data.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  //====== function for show prefill data in form=============
  useEffect(() => {
    setValue("name", profileData.name);
    if (profileData.birthday) {
      const formattedDate = profileData.birthday
        ? dayjs(profileData.birthday).format("YYYY-MM-DD")
        : null;
      setValue("dateOfBirth", formattedDate);
      setSelectedDate(profileData.birthday);
      setFormatedDate(formattedDate);
    }
    setValue("address", profileData.address);
    setValue("nationalId", profileData.national_id);

    setSelectedBloodType({ id: profileData.blood_type_id });

    if (profileData.other_health_issue) {
      setOtherInputValue(profileData.other_health_issue);
      setValue("otherIssue",profileData.other_health_issue);
    }
    setPhoneDialCode(profileData.dial_code);
    setPhoneCountryCode(profileData.country_code);
    setHomeCountryCode(profileData.home_country_code);
    setHomeDialCode(profileData.home_dial_code);
    setPhoneNumber(profileData.phone_number);
    setHomeNumber(profileData.home_phone_number);
    setValue("phone", profileData.phone_number);
    setValue("home", profileData.home_phone_number);
    setValue("company", profileData.company_name);
  }, [profileData]);

  useEffect(() => {
    dispatch(appMyProfile(userToken));
  }, []);

  const formattedValue = selectedHealthIssueValue
    ? selectedHealthIssueValue.join(", ")
    : "";

  return (
    <div className={styles.editProfileWrapper}>
      {loading ? <Loader /> : ""}
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.profileTitle}>
            <p>{t("EDIT_PROFILE_TEXT")}</p>
          </div>
        </div>
      </div>

      <div className={styles.bottomText}>
        <h2>
          {t("PERSONAL_INFORMATION_TITLE")}
          <BsPersonSquare />
        </h2>
      </div>
      <Container>
        {" "}
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("NAME_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("NAME_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("name", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("NAME_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 2,
                    message: `${t("NAME_VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 25,
                    message: `${t("NAME_VALIDATION_MAXLENGTH")}`,
                  },
                })}
              />

              {errors.name && (
                <span className="errorMsg">{errors.name.message}</span>
              )}
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <div className="dateOfBirth">
                <Controller
                  control={control}
                  name="dateOfBirth"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDatePicker"]}>
                        <DemoItem>
                          <MobileDatePicker
                            label={t("BIRTHDAY_LABEL")}
                            selected={selectedDate}
                            views={["year", "month", "day"]}
                            defaultValue={
                              profileData && profileData.birthday
                                ? dayjs(profileData.birthday)
                                : null
                            }
                            onChange={(newValue) => {
                              onChange(newValue);
                              handleDateChange(newValue);
                              clearErrors("dateOfBirth"); // Handle date change to remove error message
                            }}
                            disableFuture={true}
                            minDate={dayjs(new Date("1901-01-01"))}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                />

                <div className="errorMsg">
                  {errors.dateOfBirth && <p>{t("DATE_OF_BIRTH_REQUIRED")}</p>}
                </div>
              </div>
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("ADDRESS_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("ADDRESS_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("address", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("ADDRESS_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 5,
                    message: `${t("ADDRESS_VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 250,
                    message: `${t("ADDRESS_VALIDATION_MAXLENGTH")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.address && (
                <span className="errorMsg">{errors.address.message}</span>
              )}
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("NATIONAL_ID_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("NATIONAL_ID_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("nationalId", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("NATIONAL_ID_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 5,
                    message: `${t("VALIDATION_MINLENGTH_NATIONAL_ID")}`,
                  },
                  maxLength: {
                    value: 25,
                    message: `${t("VALIDATION_MAXLENGTH_NATIONAL_ID")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.nationalId && (
                <span className="errorMsg">{errors.nationalId.message}</span>
              )}
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("BLOOD_TYPE_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("BLOOD_TYPE_PLACEHOLDER_TEXT")}`}
                type="text"
                readOnly
                onClick={() => setBloodTypeModalOpen(true)}
                {...register("bloodType", {
                  required: {
                    value: true,
                    message: `${t("BLOOD_TYPE_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setBloodTypeModalOpen(true);
                }}
              />

              {errors.bloodType && (
                <span className="errorMsg">{errors.bloodType.message}</span>
              )}
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("HEALTH_ISSUE_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("HEALTH_ISSUE_PLACEHOLDER_TEXT")}`}
                type="text"
                readOnly
                value={formattedValue}
                onClick={() => setHealthIssueModalOpen(true)}
                {...register("healthIssue", {
                  required: {
                    value: true,
                    message: `${t("HEALTH_ISSUE_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setHealthIssueModalOpen(true);
                }}
              />

              {errors.healthIssue && (
                <span className="errorMsg">{errors.healthIssue.message}</span>
              )}
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <div className="phone-input-container">
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <PhoneInput
                      specialLabel={t("PHONE_INPUT_LABEL")}
                      placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      onlyCountries={["do"]}
                      inputProps={{ maxLength: 15 }}
                      country={"do"}
                      value={
                        (phoneDialCode && phoneDialCode.toString()) +
                        (phoneNumber && phoneNumber.toString())
                      }
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setPhoneCountryCode(country.countryCode);
                        setPhoneDialCode(dialCode);
                        setPhoneNumber(phone);
                        field.onChange(value);
                        clearErrors("");
                      }}
                      inputExtraProps={{
                        style: {
                          position: "relative",
                        },
                      }}
                      dropdownClass={styles.customDropdown}
                    />
                  )}
                  rules={{ validate: validatePhoneNumber }}
                />
                {errors.phone && (
                  <span className="errorMsg">{errors.phone.message}</span>
                )}
              </div>
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <div className="phone-input-container">
                <Controller
                  control={control}
                  name="home"
                  render={({ field }) => (
                    <PhoneInput
                      specialLabel={t("HOME_INPUT_LABEL")}
                      placeholder={`${t("HOME_NUMBER_PLACEHOLDER")}`}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      onlyCountries={["do"]}
                      inputProps={{ maxLength: 15 }}
                      country={"do"}
                      value={
                        (homeDialCode && homeDialCode.toString()) +
                        (homeNumber && homeNumber.toString())
                      }
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setHomeCountryCode(country.countryCode);
                        setHomeDialCode(dialCode);
                        setHomeNumber(phone);
                        field.onChange(value);
                        clearErrors("");
                      }}
                    />
                  )}
                  rules={{ validate: validateHomeNumber }}
                />
                {errors.home && (
                  <span className="errorMsg">{errors.home.message}</span>
                )}
              </div>
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("COMPANY_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("COMPANY_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("company", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.company && (
                <span className="errorMsg">{errors.company.message}</span>
              )}
              <p className={styles.companyOptional}>
                ( {t("COMPANY_OPTIONAL_TEXT")})
              </p>
            </Form.Group>

            <div className={styles.nextBtn}>
              <button type="submit">{t("UPDATE")}</button>
            </div>
          </Form>
          <BloodTypeModal
            bloodTypeModalOpen={bloodTypeModalOpen}
            setBloodTypeModalOpen={setBloodTypeModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            setSelectedBloodType={setSelectedBloodType}
            selectedBloodType={selectedBloodType}
          />
          <HealthIssueModal
            healthIssueModalOpen={healthIssueModalOpen}
            setHealthIssueModalOpen={setHealthIssueModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            selectedHealthIssue={selectedHealthIssue}
            setSelectedHealthIssue={setSelectedHealthIssue}
            selectedHealthIssueValue={selectedHealthIssueValue}
            setSelectedHealthIssueValue={setSelectedHealthIssueValue}
            otherInputValue={otherInputValue}
            setOtherInputValue={setOtherInputValue}
            profileData={profileData?.other_health_issue}
          
          />
        </div>
      </Container>
    </div>
  );
}

export default EditPersonalInfo;
