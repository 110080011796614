import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../DeleteAccount/DeleteAccount.module.css";
import { LanguageContext } from "../../Context/LanguageContext";
import i18next from "i18next";
import { Icon } from "@iconify/react";
import moment from "moment";
import "moment/locale/es";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";
import { userLogoutClear } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

function ChangeLanModal({ changeLan, setChangeLan }) {
  const languages = [
    { id: "en", code: "en", label: "English", icon: "circle-flags:us" },
    { id: "es", code: "es", label: "Spanish", icon: "circle-flags:es-variant" },
    // Add more languages as needed
  ];

  const { userToken } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedLanguage, changeLanguage } = useContext(LanguageContext);

  const [whichLan, setWhichLan] = useState(selectedLanguage);

  // API call to change language
  const fetchLangugae = async () => {
    let requestData = new FormData();
    requestData.append("locale", whichLan);
    try {
      const responsejson = await SublyApi.changeLanguage(userToken, requestData);
      const ResponseCode = STATUS_MSG[responsejson.code];

      if (responsejson.code === STATUS_CODES.SUCCESS) {
        // Toster(t("LANGUAGE_CHANGE"), "success");
      } else if (
        responsejson &&
        responsejson.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else {
        Toster(t(ResponseCode), "error");
      }
    } catch (error) {
      Toster(t("ERROR_OCCURRED"), "error");
    }
  };

  const handleSelect = () => {
    changeLanguage(whichLan);
    i18next.changeLanguage(whichLan);
    moment.locale(whichLan);

    fetchLangugae();
    setChangeLan(false);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (
      storedLanguage &&
      languages.some((lang) => lang.code === storedLanguage)
    ) {
      changeLanguage(storedLanguage);
      moment.locale(storedLanguage);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (changeLan && !event.target.closest(".modalCustom")) {
        setChangeLan(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [changeLan, setChangeLan]);

  return (
    <div>
      <Modal
        show={changeLan}
        onHide={() => {
          setChangeLan(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Body className="modalCustom">
          <div className={styles.languageSelection}>
            <h5>{t("SELECT_LANGUAGE")}</h5>
            {languages?.map((item, key) => (
              <div className="vehicleInput" key={key}>
                <input
                  type="radio"
                  id={item.code}
                  value="english"
                  name="select"
                  onChange={() => setWhichLan(item.code)}
                  checked={whichLan === item.code}
                />
                <label htmlFor={item.id} className={styles.vehicleInputDisplay}>
                  <Icon icon={item.icon} color="" width="22" height="22" />
                  {item.label}
                </label>
                <span className={styles.langIcons}></span>
              </div>
            ))}
          </div>

          <div className={styles.Btns}>
            <button
              className={styles.deleteBtn}
              onClick={() => {
                handleSelect();
              }}
            >
              {t("CONTINUE")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangeLanModal;
