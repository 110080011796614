import styles from "./VehicleHistory.module.css";
import { FaBell } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import HomeFooterOption from "../Home/HomeFooterOption";
import theftImage from "../../Assests/Images/theift1.png";
import accidentImage from "../../Assests/Images/accident1.png";
import roadsideImage from "../../Assests/Images/roadside1.png";
import {
  jobHistory,
  setCurrentPage,
  userLogoutClear,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { STATUS_CODES } from "../../utils/StatusCode";
import Loader from "../../utils/Loader/Loader";
import moment from "moment";
import { FaLock } from "react-icons/fa6";
import { FaUnlock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function VehicleHistory() {
  const listInnerRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    userToken,
    jobHistoryData,
    isLoading,
    currentPage,
    loadMore,
    unreadNotification,
  } = useSelector((state) => state.user);

  //======= function for calling vehicle api============
  async function getJobList(pageNum = 1, onLoadMore = false) {
    await dispatch(
      jobHistory({
        userToken: userToken,
        page_no: pageNum,
        onLoadMore: onLoadMore,
      })
    ).then((responsejson) => {
      const { response } = responsejson.payload;
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else if (onLoadMore == true && loadMore) {
        dispatch(setCurrentPage(currentPage + 1));
      }
    });
  }

  useEffect(() => {
    dispatch(setCurrentPage(1));
    getJobList();
  }, []);

  //======= Function to load more vehicles===========
  const loadMoreVehicles = () => {
    if (loadMore) {
      getJobList(currentPage + 1, true);
    }
  };

  //======privious code without loader========
  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        loadMoreVehicles();
      }
    }
  };

  // =========get dynamic image according to sub_type=========
  const getImageForSubType = (subType) => {
    switch (subType) {
      case "theft":
        return <img src={theftImage} alt="Theft" />;
      case "accident":
        return <img src={accidentImage} alt="Accident" />;
      case "roadside-assistance":
        return <img src={roadsideImage} alt="Roadside Assistance" />;
      case "lock":
        return (
          <div className={styles.lockImg}>
            <FaLock />
          </div>
        );
      case "unlock":
        return (
          <div className={styles.unlockImg}>
            <FaUnlock />
          </div>
        );
      default:
        return null;
    }
  };

  // =========get status according to sub_type=========
  const getStatusForSubType = (subType) => {
    switch (subType) {
      case "theft":
        return t("THEFT");
      case "accident":
        return t("ACCIDENT");
      case "roadside-assistance":
        return t("ROAD_SIDE_ASSISTANCE");
      case "lock":
        return t("LOCK_TEXT");
      case "unlock":
        return t("UNLOCK_TEXT");
      default:
        return null;
    }
  };

  return (
    <div className={styles.settingWrapper}>
      <div className={styles.backGround}>
        <div className={styles.topItem}>
          <h5>{t("HISTORY_TITLE")}</h5>
        </div>
        <div className={styles.bellIcon}>
          {unreadNotification > 0 ? (
            <div className={styles.notificationTick}></div>
          ) : (
            ""
          )}
          <FaBell onClick={() => navigate("/notification")} />
        </div>
      </div>
      <div className={styles.jobHistoryList}>
        <div className="fixContent" onScroll={handleScroll} ref={listInnerRef}>
          {jobHistoryData && jobHistoryData.length > 0 ? (
            jobHistoryData.map((item, index) => {
              return (
                <div
                  className={styles.listBox}
                  key={index}
                  onClick={
                    item.type === "sos"
                      ? () => navigate(`/history-detail/${item.id}`)
                      : null
                  }
                  style={{
                    cursor: item.type === "sos" ? "pointer" : "default",
                  }}
                >
                  <div className={styles.historyList}>
                    <div className={styles.listItem}>
                      <div className={styles.history}>
                        <h3>{item.license_plate_number}</h3>
                        <div className={styles.typeText}>
                          {item.sub_type == "unlock" ? (
                            <p> {t("UNLOCK_TEXT")} : </p>
                          ) : item.type == "sos" ? (
                            <p> {t("START_DATE")} : </p>
                          ) : (
                            <p> {t("LOCK_TEXT")} : </p>
                          )}

                          <span>
                            <strong>
                              {moment(item.date).format("DD/MM/YYYY hh:mm A")}
                            </strong>
                          </span>
                        </div>
                        {item.type === "sos" && (
                          <div className={styles.typeText}>
                            <p>{t("END_DATE")} :</p>
                            <span>
                              <strong>
                                {item.end_date
                                  ? moment(item.end_date).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )
                                  : "NA"}
                              </strong>
                            </span>
                          </div>
                        )}

                        {/* <p>{item.sub_type}</p> */}
                        <p> {getStatusForSubType(item.sub_type)}</p>
                      </div>

                      <div className={styles.sosImg}>
                        {getImageForSubType(item.sub_type)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : isLoading ? (
            <Loader />
          ) : (
            <div className={styles.noResultsMessage}>
              {t("NO_HISTORY_FOUND")}
            </div>
          )}
          {isLoading && <Loader />}
        </div>
      </div>

      <HomeFooterOption />
    </div>
  );
}

export default VehicleHistory;
