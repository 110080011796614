import { useTranslation } from "react-i18next";
import styles from "../Profile/Profile.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import notificationLock from "../../Assests/Images/lock1.png";
import notificationUnlock from "../../Assests/Images/unlock1.png";
import {
  fetchNotificationList,
  setCurrentPage,
  userLogoutClear,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import moment from "moment";
import SublyApi from "../../helpers/Api";

function Notification() {
  const { userToken, currentPage } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageNumberList, setPageNumberList] = useState(1);
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadmore, setLoadmore] = useState();

  //======== function for calling vehicle api=========
  function notificationList(pageNum = pageNumberList, onLoadMore = false) {
    dispatch(
      fetchNotificationList({
        userToken: userToken,
        page_no: pageNum,
        onLoadMore: onLoadMore,
      })
    ).then((responsejson) => {
      setIsLoading(false);
      const { response } = responsejson.payload;
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else {
        setNotificationData([
          ...notificationData,
          ...response.data.notification_list,
        ]);
        setLoadmore(response.data.loadMore);
        dispatch(setCurrentPage(currentPage + 1));
      }
    });
  }

  useEffect(() => {
    dispatch(setCurrentPage(1));
  }, []);

  useEffect(() => {
    notificationList();
  }, [pageNumberList]);

  //========= function for handle notification status===========
  const handleChangeStatus = (status_id) => {
    let res = SublyApi.notificationIsRead(userToken, status_id);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else {
        const updatedNotifications = notificationData.map((notification) => {
          if (notification.id === status_id) {
            return { ...notification, is_read: 1 }; // Assuming you want to update is_read to 0
          }
          return notification;
        });
        setNotificationData(updatedNotifications);
        dispatch(
          fetchNotificationList({
            userToken: userToken,
            page_no: 1,
            onLoadMore: true,
          })
        );
      }
    });
  };

  return (
    <div className={styles.profileWrapper}>
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.profileTitle}>
            <p>{t("NOTIFICATION_TITLE")}</p>
          </div>
        </div>
      </div>

      {/* ON SCROLL FN */}
      <div
        className={styles.content}
        style={{
          height: "285px",
          minHeight: "calc(100vh - 125px)",
        }}
        onScroll={(e) => {
          const element = e.target;

          if (
            Math.abs(
              element.scrollHeight - element.scrollTop - element.clientHeight
            ) <= 1 &&
            notificationData.length > 0 &&
            loadmore == true
          ) {
            setPageNumberList((prevPageNumber) => prevPageNumber + 1);
          }
        }}
      >
        {notificationData && notificationData.length > 0 ? (
          notificationData.map((item, index) => {
            const hours = moment(item?.created_at).fromNow();
            return (
              <div
                className={styles.notificationItem}
                key={index}
                onClick={() => {
                  if (item.is_read !== 1) {
                    handleChangeStatus(item.id);
                  }
                }}
              >
                <div
                  className={
                    item.is_read === 1
                      ? styles.listItemread
                      : styles.listItemUnread
                  }
                >
                  <div className={styles.history}>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <div className={styles.typeText}>
                      <h3 className={styles.TimeAgo}>
                        {hours.toLocaleLowerCase()}
                      </h3>
                    </div>
                  </div>
                  <div className={styles.notifyIMg}>
                    {item.type === "vehicle" && item.action === "lock" ? (
                      <img src={notificationLock} alt={item.altText} />
                    ) : item.type === "vehicle" && item.action === "unlock" ? (
                      <img src={notificationUnlock} alt={item.altText} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : !isLoading ? (
          <div className={styles.noResultsMessage}>
            {t("NO_NOTIFICATION_FOUND")}
          </div>
        ) : (
          ""
        )}
        {isLoading && <Loader />}
      </div>
    </div>
  );
}

export default Notification;
