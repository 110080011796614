import styles from "../Home/HomeList.module.css";
import { NavLink } from "react-router-dom";
import "../../Assests/Styles/Common.css";
import { footerLink } from "./FooterData";

function HomeFooterOption() {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.optionList}>
        {footerLink.map((item) => (
          <div key={item.id} className={item.footerIconCss}>
            <NavLink to={item.link} className="itemLink">
              {item.icon}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomeFooterOption;
