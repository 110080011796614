import "../../Assests/Styles/Common.css";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./UpdatePinModal.module.css";
import PinInput from "react-pin-input";
import { useSelector } from "react-redux";
import "../../Assests/Styles/Common.css";
import Loader from "../../utils/Loader/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { useNavigate } from "react-router-dom";
import Toster from "../../utils/Toster";
import lockImg from "../../Assests/Images/Lock1.png";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";

function UpdatePinModal({
  updatePinModalOpen,
  setUpdatePinModalOpen,
  onClose,
}) {
  const { userToken } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [newPinValue, setNewPinValue] = useState();
  const [newPinError, setNewPinError] = useState();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  //==========function for clear input on page refresh=======
  useEffect(() => {
    if (!updatePinModalOpen) {
      // Reset pinValue when the modal closes
      setValue("");
      setNewPinValue("");
    }
  }, [updatePinModalOpen]);

  //======== function for change pin  calling==========
  const onSubmit = async (formdata) => {
    if (!newPinValue || newPinValue.length !== 6) {
      setNewPinError(t("ENTER_NEW_PIN_NUMBER"));
      return;
    }
    setLoading(true);
    let requestData = new FormData();
    requestData.append("code", formdata.code);
    requestData.append("new_pin", newPinValue);
    await SublyApi.updatePin(userToken, requestData).then((responsejson) => {
      const ResponseCode = STATUS_MSG[responsejson.data.code];
      if (responsejson.code === STATUS_CODES.SUCCESS) {
        Toster(t("PIN_CHANGE_SUCCESSFULLY"), "success");
        setValue("code", "");
        setNewPinValue("");
        setUpdatePinModalOpen(false);
        setLoader(false);
        onClose();
        setLoading(false);
      } else if (responsejson.code === STATUS_CODES.INCORRECT_PIN_NUMBER) {
        Toster(t("INCORRECT_PIN_NUMBER"), "success");
        setLoader(false);
        setLoading(false);
      } else if (responsejson.data.code === STATUS_CODES.VALIDATION_ERROR) {
        setLoader(false);
      } else {
        Toster(t(ResponseCode), "error");
        setLoading(false);
      }
    });
  };

  return (
    <div className={styles.topModal}>
      {loader ? <Loader /> : ""}
      <Modal
        show={updatePinModalOpen}
        onHide={() => {
          setUpdatePinModalOpen(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Body className={styles.textAreaClass}>
          <div className={styles.wrapper}>
            <div className={styles.topSection}>
              <div className={styles.formStart}>
                <div className={styles.backIcon}>
                  <IoMdArrowRoundBack onClick={() => navigate(-1)} />
                </div>
                <div className={styles.forgotText}>
                  <p>{t("UPDATE_PIN")}</p>
                </div>
              </div>
            </div>

            <div className={styles.lockImg}>
              <img src={lockImg}></img>
            </div>

            <div className={styles.tittleText}>
              <h3>{t("UPDATE_YOUR_PIN")}</h3>
              <p>{t("OTP_INPUT_LABEL")}</p>
            </div>

            <Container>
              <Form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.formSection}
              >
                <div className={styles.inputForm}>
                  <div className="otpInput">
                    <Controller
                      name="code"
                      type="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: `${t("CODE_REQUIRED_MESSAGE")}`,
                        minLength: {
                          value: 6,
                          message: `${t("CODE_REQUIRED_MESSAGE")}`,
                        },
                      }}
                      render={({ field }) => (
                        <OtpInput
                          inputStyle="inputStyle"
                          {...field}
                          numInputs={6}
                          isInputNum
                          shouldAutoFocus={field.value.length < 6}
                          renderInput={(props, index) => (
                            <input {...props} inputMode="numeric" />
                          )}
                          onChange={(value) => {
                            let numbers = /^[0-9]+$/;
                            if (value.match(numbers) || value === "") {
                              field.onChange(value);
                            } else {
                              return false;
                            }
                          }}
                        />
                      )}
                    />
                  </div>

                  {errors.code && (
                    <span className="changeModalErrorMsg">
                      {errors.code.message}
                    </span>
                  )}

                  <div className={styles.codeLabel}>
                    <p>{t("ENTER_NEW_PIN")}</p>
                  </div>
                  <div className={styles.pinInput}>
                    <PinInput
                      length={6}
                      type="numeric"
                      style={{ padding: "10px, 10px", width: "100%" }}
                      inputStyle={{ borderColor: "#DEDEDE" }}
                      inputFocusStyle={{ borderColor: "#DEDEDE" }}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      value={newPinValue}
                      initialValue={newPinValue}
                      onChange={(value) => {
                        setNewPinValue(value);
                        setNewPinError("");
                      }}
                    />
                  </div>
                  {newPinError && (
                    <div className="changeModalErrorMsg">{newPinError}</div>
                  )}

                  <div className={styles.submitBtn}>
                    {/* <button>{t("SUBMIT_BUTTON")}</button> */}
                    <button
                      className={`${styles.nextButton} ${
                        loading ? styles.disabledButton : ""
                      }`}
                      disabled={loading}
                    >
                      {t("SUBMIT_BUTTON")}
                    </button>
                  </div>
                </div>
              </Form>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpdatePinModal;
