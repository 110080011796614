import SignUpStepFirst from "./SignUpStepFirst";
import SignUpStepSecond from "./SignUpStepSecond";
import SignUpStepThird from "./SignUpStepThird";
import SignUpStepFourth from "./SignUpStepFourth";
import { setSwitchTo, setPreviousValue } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import $ from "jquery";

function SignupFlow() {
  const dispatch = useDispatch();
  const { switchTo, previousValue } = useSelector((state) => state.user);

    // --------function for open component top of the page ----------
    useEffect(() => {
      $("document").ready(function () {
        $(window).scrollTop(0);
      });
    }, [switchTo]);


  return (
    <>
      {switchTo == 0 ? (
        <SignUpStepFirst
          setSwitchTo={(value) => dispatch(setSwitchTo(value))}
          previousValue={previousValue}
          setPreviousValue={(value) => dispatch(setPreviousValue(value))}
        />
      ) : switchTo == 1 ? (
        <SignUpStepSecond
          setSwitchTo={(value) => dispatch(setSwitchTo(value))}
          previousValue={previousValue}
          setPreviousValue={(value) => dispatch(setPreviousValue(value))}
        />
      ) : switchTo == 2 ? (
        <SignUpStepThird
          setSwitchTo={(value) => dispatch(setSwitchTo(value))}
          previousValue={previousValue}
          setPreviousValue={(value) => dispatch(setPreviousValue(value))}
        />
      ) : switchTo == 3 ? (
        <SignUpStepFourth
          setSwitchTo={(value) => dispatch(setSwitchTo(value))}
          previousValue={previousValue}
          setPreviousValue={(value) => dispatch(setPreviousValue(value))}
        />
      ) : (
        switchTo == 4
      )}
    </>
  );
}

export default SignupFlow;