import { AiFillHome } from "react-icons/ai";
import { FaListAlt } from "react-icons/fa";
import { TbSos } from "react-icons/tb";
import { FaMap } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";

export const footerLink = [
  {
    id: "1",
    icon: <AiFillHome />,
    link: "/home-list",
    footerIconCss: "",
  },
  {
    id: "2",
    icon: <FaListAlt />,
    link: "/vehicle-history",
    footerIconCss: "",
  },
  {
    id: "3",
    icon: <TbSos />,
    link: "/sos-history",
    footerIconCss: "circleClass",
  },
  {
    id: "4",
    icon: <FaMap />,
    link: "/map",
    footerIconCss: "",
  },
  {
    id: "5",
    icon: <IoMdSettings />,
    link: "/setting",
    footerIconCss: "",
  },
];
