import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./ChangePinModal.module.css";
import changePinImg from "../../Assests/Images/reset.png";
import PinInput from "react-pin-input";
import { useEffect, useState } from "react";
import SublyApi from "../../helpers/Api";
import { useSelector } from "react-redux";
import Toster from "../../utils/Toster";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";

function ChangePinModal({ changePinModalOpen, setChangePinModalOpen }) {
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [oldPinValue, setOldPinValue] = useState();
  const [newPinValue, setNewPinValue] = useState();
  const [oldPinError, setOldPinError] = useState("");
  const [newPinError, setNewPinError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!changePinModalOpen) {
      // Reset pinValue when the modal closes
      setOldPinValue("");
      setNewPinValue("");
    }
  }, [changePinModalOpen]);

  // ==== function for change pin  calling ==========
  const changePin = async (formdata) => {
    if (!oldPinValue || oldPinValue.length !== 6) {
      setOldPinError(t("ENTER_OLD_PIN"));
      return;
    } else if (!newPinValue || newPinValue.length !== 6) {
      setNewPinError(t("ENTER_NEW_PIN_NUMBER"));
      return;
    }
    let requestData = new FormData();
    requestData.append("old_pin", oldPinValue);
    requestData.append("new_pin", newPinValue);
    setLoading(true);
    await SublyApi.changePin(userToken, requestData).then((responsejson) => {
      const ResponseCode = STATUS_MSG[responsejson.data.code];
      if (responsejson.code === STATUS_CODES.SUCCESS) {
        Toster(t("PIN_CHANGE_SUCCESSFULLY"), "success");
        setChangePinModalOpen(false);
        setLoading(false);
      } else if (responsejson.code === STATUS_CODES.INCORRECT_PIN_NUMBER) {
        Toster(t("INCORRECT_PIN_NUMBER"), "success");
        setLoading(false);
      } else if (responsejson.data.code === STATUS_CODES.VALIDATION_ERROR) {
        setOldPinValue("");
        setOldPinError(t("INCORRECT_PIN_NUMBER"));
        setLoading(false);
      } else {
        Toster(t(ResponseCode), "error");
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <Modal
        show={changePinModalOpen}
        onHide={() => {
          setChangePinModalOpen(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.modalTitle}>
            {t("CHANGE_PIN")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalCustom">
          <div className={styles.iconText}>
            <img src={changePinImg}></img>
          </div>

          <div className={styles.alertBox}>
            <h6>{t("OLD_PIN")}</h6>
          </div>
          <div className={styles.lockPin}>
            <div className="lockPinINput">
              <PinInput
                length={6}
                type="numeric"
                style={{ padding: "10px, 10px", width: "100%" }}
                inputStyle={{ borderColor: "#DEDEDE" }}
                inputFocusStyle={{ borderColor: "#DEDEDE" }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                value={oldPinValue}
                initialValue={oldPinValue}
                onChange={(value) => {
                  setOldPinValue(value);
                  setOldPinError(""); // Reset pinError when user starts typing again
                }}
              />
            </div>
          </div>
          {oldPinError && (
            <div className="changeModalErrorMsg">{oldPinError}</div>
          )}

          <div className={styles.alertBox}>
            <h6>{t("NEW_PIN")}</h6>
          </div>
          <div className={styles.lockPin}>
            <div className="lockPinINput">
              <PinInput
                length={6}
                type="numeric"
                style={{ padding: "10px, 10px", width: "100%" }}
                inputStyle={{ borderColor: "#DEDEDE" }}
                inputFocusStyle={{ borderColor: "#DEDEDE" }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                value={newPinValue}
                initialValue={newPinValue}
                onChange={(value) => {
                  setNewPinValue(value);
                  setNewPinError(""); // Reset pinError when user starts typing again
                }}
              />
            </div>
          </div>
          {newPinError && (
            <div className="changeModalErrorMsg">{newPinError}</div>
          )}

          <div className={styles.submitBtns}>
            {/* <button className={styles.cancelBtn} onClick={() => changePin()}>
              {t("SUBMIT_BUTTON")}
            </button> */}

            <button
              type="submit"
              onClick={() => changePin()}
              className={`${styles.cancelBtn} ${
                loading ? styles.disabledButton : ""
              }`}
              disabled={loading}
            >
              {t("SUBMIT_BUTTON")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangePinModal;
