import styles from "../Profile/Profile.module.css";
import { FaAnglesRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { IoMdArrowRoundBack } from "react-icons/io";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { BsPersonSquare } from "react-icons/bs";
import { MdOutlineWbTwilight } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";

function PersonalInformation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profileData, activeTab } = useSelector((state) => state.user);

  //======= function for remove extra + in dial code==========
  function removeExtraPlus(inputString) {
    let newStr = "+" + inputString;
    return newStr.replace(/\++/g, "+");
  }

  return (
    <div className={styles.profileWrapper}>
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate("/setting")} />
          </div>
          <div className={styles.profileTitle}>
            <p>{t("PROFILE_TILTLE")}</p>
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.userDetail}>
          <h5>{profileData && profileData.name}</h5>
          <p>{profileData && profileData.email}</p>
          <p>
            {" "}
            {removeExtraPlus(profileData.dial_code)}{" "}
            {profileData.phone_number &&
              profileData.phone_number.substring(0, 3)}{" "}
            {profileData.phone_number &&
              profileData.phone_number.substring(3, 6)}{" "}
            {profileData.phone_number && profileData.phone_number.substring(6)}
          </p>

          {/* <p>
            {removeExtraPlus(profileData.dial_code)}{" "}
            {profileData.phone_number &&
              profileData.phone_number.substring(profileData.dial_code.length)}
          </p> */}

          <div className={styles.viewDetail}>
            <p onClick={() => navigate("/edit-personal-info")}>
              {t("EDIT_PROFILE_TEXT")} <FaAnglesRight />
            </p>
          </div>
        </div>

        <div className={styles.tabOPtion}>
          <div className="tabSection">
            <Tabs
              id="uncontrolled-tab-example"
              className="tabSection"
              defaultActiveKey={activeTab}
            >
              <Tab
                eventKey="Personal Information"
                title={
                  <div className="tabSectionTitle">
                    {t("PERSONAL_INFORMATION")}
                    <BsPersonSquare />
                  </div>
                }
              >
                <div className={styles.tabData}>
                  <div className={styles.tabContent}>
                    <p>{t("BIRTHDAY")}</p>
                    <h2>{moment(profileData.birthday).format("DD/MM/YYYY")}</h2>
                  </div>
                  <div className={styles.tabContent}>
                    <p>{t("NATIONAL_ID_TEXT")}</p>
                    <h2>{profileData.national_id}</h2>
                  </div>
                  <div className={styles.tabContent}>
                    <p>{t("BLOOD_TYPE_TEXT")}</p>
                    <h2>{profileData.blood_type}</h2>
                  </div>

                  <div className={styles.tabContent}>
                    <p>{t("SPECIFY_HEALTH_ISSUE")}</p>
                    <h2>
                      {profileData &&
                        profileData.health_issue_arr &&
                        profileData.health_issue_arr.join(", ")}
                    </h2>
                  </div>
                  {profileData.other_health_issue && (
                    <div className={styles.tabContent}>
                      <p>{t("OTHER_HEALTH_ISSUE")}</p>
                      <h2>{profileData.other_health_issue}</h2>
                    </div>
                  )}

                  <div className={styles.tabContent}>
                    <p>{t("PROFILE_PHONE_NUMBER")}</p>
                    <h2>
                      {removeExtraPlus(profileData.dial_code)}{" "}
                      {profileData.phone_number &&
                        profileData.phone_number.substring(0, 3)}{" "}
                      {profileData.phone_number &&
                        profileData.phone_number.substring(3, 6)}{" "}
                      {profileData.phone_number &&
                        profileData.phone_number.substring(6)}
                    </h2>
                  </div>
                  <div className={styles.tabContent}>
                    <p>{t("PROFILE_HOME_NUMBER")}</p>
                    <h2>
                      {removeExtraPlus(profileData.home_dial_code)}{" "}
                      {profileData.home_phone_number &&
                        profileData.home_phone_number.substring(0, 3)}{" "}
                      {profileData.home_phone_number &&
                        profileData.home_phone_number.substring(3, 6)}{" "}
                      {profileData.home_phone_number &&
                        profileData.home_phone_number.substring(6)}
                    </h2>
                  </div>
                  <div className={styles.tabContent}>
                    <p>{t("ADDRESS_TEXT")}</p>
                    <h2>{profileData.address}</h2>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="Security Question"
                title={
                  <div className="tabSectionTitle">
                    {t("SECURITY_QUESTION")}
                    <FaQuestionCircle />
                  </div>
                }
              >
                <div className={styles.tabData}>
                  <div className={styles.secirityText}>
                    <h3>{t("SECURITY_QUESTION_HEAD")}</h3>
                    <p onClick={() => navigate("/edit-security-question")}>
                      {t("EDIT_QUESTIONS")}
                    </p>
                  </div>

                  <div className={styles.securityQuestion}>
                    {profileData &&
                      profileData.security_que_ans &&
                      profileData.security_que_ans.map((item, index) => (
                        <div key={index}>
                          <h2>{`${t("QUESTION")} ${index + 1} :  ${
                            item.quesion
                          }`}</h2>
                          <p>{`${t("ANSWER")}  : ${item.answer}`}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="Emergency contact"
                title={
                  <div className="tabSectionTitle">
                    {t("EMERGENCY_CONTACT")}
                    <MdOutlineWbTwilight />
                  </div>
                }
              >
                <div className={styles.tabData}>
                  <div className={styles.editEmergencyContactTitle}>
                    <p onClick={() => navigate("/edit-emergency-contact")}>
                      {t("EDIT_EMERGENCY_CONTACT")}
                    </p>
                  </div>

                  <div className={styles.tabData}>
                    <div className={styles.emergencyContact}>
                      {profileData &&
                        profileData.emergency_contacts &&
                        profileData.emergency_contacts.map((contact, index) => (
                          <div
                            key={index}
                            className={
                              index !==
                              profileData.emergency_contacts.length - 1
                                ? styles.bottomBorder
                                : ""
                            }
                          >
                            <h3>{t("PROFILE_CONTACT")}</h3>
                            <div className={styles.tabContent}>
                              <p> {t("NAME_TEXT")} </p>
                              <h2>{contact.name}</h2>
                            </div>

                            <div className={styles.tabContent}>
                              <p> {t("RELATIONSHIP_TEXT")} </p>
                              <h2>{contact.relation}</h2>
                            </div>

                            <div className={styles.tabContent}>
                              <p> {t("PROFILE_PHONE_NUMBER")} </p>

                              <h2>
                                {removeExtraPlus(contact.first_dial_code)}{" "}
                                {contact.first_phone_number &&
                                  contact.first_phone_number.substring(
                                    0,
                                    3
                                  )}{" "}
                                {contact.first_phone_number &&
                                  contact.first_phone_number.substring(
                                    3,
                                    6
                                  )}{" "}
                                {contact.first_phone_number &&
                                  contact.first_phone_number.substring(6)}
                              </h2>
                            </div>

                            <div className={styles.tabContent}>
                              <p> {t("PROFILE_HOME_NUMBER")} </p>

                              <h2>
                                {removeExtraPlus(contact.second_dial_code)}{" "}
                                {contact.second_phone_number &&
                                  contact.second_phone_number.substring(
                                    0,
                                    3
                                  )}{" "}
                                {contact.second_phone_number &&
                                  contact.second_phone_number.substring(
                                    3,
                                    6
                                  )}{" "}
                                {contact.second_phone_number &&
                                  contact.second_phone_number.substring(6)}
                              </h2>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
