import "../../Assests/Styles/Common.css";
import { Form, Modal } from "react-bootstrap";
import styles from "../SignUp/BloodTypeModal.module.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function BloodTypeModal({
  bloodTypeModalOpen,
  setBloodTypeModalOpen,
  setValue,
  clearErrors,
  selectedBloodType,
  setSelectedBloodType,
}) {
  const { t } = useTranslation();
  const { masterList } = useSelector((state) => state.user);

  // ----------- function for manage checkbox----------------
  const handleCheckboxChange = (bloodType) => {
    setSelectedBloodType({
      id: bloodType.id,
      value_name: bloodType.value_name,
    });
    setValue("bloodType", bloodType.value_name);
    clearErrors("bloodType");
    setBloodTypeModalOpen(false);
  };

  return (
    <div className={styles.topModal}>
      <div className="modalPopup">
        <Modal
          show={bloodTypeModalOpen}
          onHide={() => {
            setBloodTypeModalOpen(false);
          }}
          className="modalCloseBtn"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className={styles.modalTitle}>
              {t("SELECT_BLOOD_TYPE")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="bloodTypeChek">
              <div className={styles.ScrollbarVertical}>
                <div className="checkBoxRow">
                  {masterList &&
                    masterList.blood_types &&
                    masterList.blood_types.length > 0 &&
                    masterList.blood_types.map((item, index) => (
                      <div
                        key={index}
                        className="checkbox-wrapper checkboxSelect"
                        onClick={() => handleCheckboxChange(item)}
                      >
                        <label for={`blood_type_${item.value_name}`}>
                          {item.value_name}
                        </label>
                        <input
                          id={`blood_type_${item.id}`}
                          type="radio"
                          checked={
                            selectedBloodType && selectedBloodType.id == item.id
                          }
                          onChange={() => handleCheckboxChange(item)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default BloodTypeModal;