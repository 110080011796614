import "../../Assests/Styles/Common.css";
import { Form, Modal } from "react-bootstrap";
import styles from "../SignUp/BloodTypeModal.module.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function RelationshipModal({
  relationshipModalOpen,
  setRelationshipModalOpen,
  setValue,
  clearErrors,
  selectedRelationShip1,
  setSelectedRelationship1,
}) {
  const { t } = useTranslation();
  const { masterList } = useSelector((state) => state.user);

  // ----------- function for manage checkbox----------------
  const handleCheckboxChange = (relationOption) => {
    setSelectedRelationship1({
      id: relationOption.id,
      value_name: relationOption.value_name,
    });
    setValue("relationship1", relationOption.value_name);
    clearErrors("relationship1");
    setRelationshipModalOpen(false);
  };

  return (
    <div className={styles.topModal}>
      <div className="modalPopup">
        <Modal
          show={relationshipModalOpen}
          onHide={() => {
            setRelationshipModalOpen(false);
          }}
          className="modalCloseBtn"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className={styles.modalTitle}>
              {t("RELATION_TYPE")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="bloodTypeChek">
              <div className={styles.ScrollbarVertical}>
                <div className="checkBoxRow">
                  {masterList &&
                    masterList.contact_relation &&
                    masterList.contact_relation.length > 0 &&
                    masterList.contact_relation.map((option, index) => (
                      <div
                        key={index}
                        className="checkboxSelect"
                        onClick={() => handleCheckboxChange(option)}
                      >
                        <label
                          for={`relation_option1_${
                            option.value_name.charAt(0).toUpperCase() +
                            option.value_name.slice(1)
                          }`}
                        >
                          {option.value_name}
                        </label>
                        <input
                          id={`relation_option1_${option.id}`}
                          type="radio"
                          checked={
                            selectedRelationShip1 &&
                            selectedRelationShip1.id == option.id
                          }
                          onChange={() => handleCheckboxChange(option)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default RelationshipModal;
